import React, { Fragment, useEffect, useState } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getAllUsers, getAllUsersCount, postApproveUser } from "../../../actions/auth";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";
import UserItem from "./Useritem";
import CountUp from "react-countup";

const UserList = () => {
  const auth = useSelector((state) => state.auth);
  const { loading, users, user } = auth;
  const dispatch = useDispatch();
  const [countVerifiedUser, setCountVerifiedUser] = useState(0);
  const [countNotVerifiedUser, setCountNotVerifiedUser] = useState(0);
  useEffect(() => {
    dispatch(getAllUsers());
    getCountUser();
  }, [dispatch]);

  const getCountUser = async () => {
    const dataCount = await getAllUsersCount();

    setCountVerifiedUser(dataCount.totalVerifiedUsers)
    setCountNotVerifiedUser(dataCount.totalNotVerifiedUsers)
  }
  const handleApproveUser = async (userId) => {
    try {
      // Call the action to approve user with userId
      await postApproveUser(userId);
      // After successful approval, you may want to refresh the user list
      dispatch(getAllUsers());
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };
  return (
    <Fragment>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Users List" />
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card-counter primary">
                    <i className="fa fa-users pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={countVerifiedUser}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">Verified Users</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter danger">
                    <i className="fa fa-users pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={countNotVerifiedUser}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">Users has not been verified</span>
                  </div>
                </div>
              </div>
            </div>
          <h4 className="my-4">All Users</h4>
          <div>
            {loading && !users ? (
              <Spinner />
            ) : (
              <Fragment>
                <table className="table table-hover table-sm booking-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Verif Status</th>
                      <th scope="col">SignUp Method</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((u, i = 0) => (
                      <UserItem
                        key={u._id}
                        user={u}
                        currentUser={user}
                        count={i++}
                        postApproveUser={handleApproveUser}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default UserList;
