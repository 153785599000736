import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";
import {
  GET_CATEGORIES,
  CATEGORY_ERROR,
  GET_CATEGORY,
  DELETE_CATEGORY,
} from "../types";

export const deleteCategory = (id) => async (dispatch) => {
  try {
    const res = await customAxios.delete(`/api/admin/categories/${id}`);
    dispatch({
      type: DELETE_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const getCategory = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/categories/${id}`);
    dispatch({
      type: GET_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const getCategories = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/categories");
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// add driver
export const addCategoryToDB = (formdata) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/categories",
      formdata,
      config
    );
    dispatch(setAlert("Category added", "success"));
    // console.log(res);
    return res;
  } catch (err) {
    const errors = err.response.data.errors;
    return errors;
  }
};
