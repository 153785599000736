import React from "react";
// import Moment from "react-moment";

const VehicleTypeList = ({
  vehicle_type,
  total_hours,
  toll_fare,
  zone_hours,
  activatedPackages,
  customerAddedAditionalMinutes,
}) => {
  const getTotal = (
    vehicle_type,
    totHoursComplete,
    tollFare,
    zoneIncludedHoursRounded
  ) => {
    const tot =
      vehicle_type.baseprice +
      (totHoursComplete > 2 ? totHoursComplete - 2 : 0) *
        2 *
        vehicle_type.hourlyprice +
      tollFare +
      zoneIncludedHoursRounded * vehicle_type.hourlyprice * 2 * 0.25;
    return tot;
  };

  const getPackageRate = () => {
    console.log("customerAddedAditionalMinutes");
    console.log(customerAddedAditionalMinutes);
    const pkg = activatedPackages.find(
      (e) => e.vehicletype === vehicle_type.typename
    );
    const inHrsRate =
      pkg.rate +
      (customerAddedAditionalMinutes / 60) * vehicle_type.hourlyprice;
    if (pkg) return inHrsRate;
  };

  // const getPackageRate = () => {
  //   console.log(activatedPackages);
  //   const pkg = activatedPackages.find(
  //     (e) => e.vehicletype === vehicle_type.typename
  //   );
  //   if (pkg) return pkg.rate.toString();
  // };

  const getPackageName = () => {
    const pkg = activatedPackages.find(
      (e) => e.vehicletype === vehicle_type.typename
    );
    if (pkg) return pkg.packagename;
  };

  const hasPkg = () => {
    const hpkg = activatedPackages.some(
      (e) => e.vehicletype === vehicle_type.typename
    );
    return hpkg;
  };

  const getVehicleRates = () => {
    return (
      <div>
        (BP: {vehicle_type.baseprice}) + [(TotTime: {total_hours} Hrs) - 2 Hrs]
        x 2 x (Rate: {vehicle_type.hourlyprice}) + (Toll: {toll_fare}) +
        [(Zonehrs: {zone_hours}) x (Rate: {vehicle_type.hourlyprice}) x 2] x
        0.25 = {getTotal(vehicle_type, total_hours, toll_fare, zone_hours)} JPY
      </div>
    );
  };

  return (
    <div>
      {getVehicleRates()}
      {hasPkg() ? (
        <span className="badge bg-dark">
          {getPackageName() + " " + getPackageRate()}
          {" JPY "}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default VehicleTypeList;
