import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Alert from "../../layout/Alert";
import Layout from "../../layout/Layout";
import TitleBar from "../../layout/TitleBar";
import ScaleLoader from "react-spinners/ScaleLoader";

import { addCategoryToDB, getCategory } from "../../../actions/admin/category";
import { useDispatch, useSelector } from "react-redux";

const UpdateCategory = ({ match }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category);
  const { categoryState, loading } = categories;
  const [formData, setFormData] = useState({
    _id: null,
    categoryname: "",
  });

  useEffect(() => {
    dispatch(getCategory(match.params.id));
  }, []);

  useEffect(() => {
    if (categories === null) return;
    setFormData({
      _id: categoryState?._id,
      categoryname: categoryState?.categoryname,
    });
  }, [categories, loading]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    dispatch(addCategoryToDB(formData));
  };

  return (
    <LoadingOverlay active={false} spinner={<ScaleLoader color="#36D7B7" />}>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Update Category" />
          <div className="container-fluid">
            <form onSubmit={(e) => onFormSubmit(e)}>
              <div className="row">
                <div className="col-md-6">

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <Link className="btn btn-secondary" to="/admin/categorylist">
                        <i
                          className="fa fa-arrow-left fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Back To List
                      </Link>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">Category details</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Category Name:</label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter category name"
                            name="categoryname"
                            value={formData.categoryname}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                categoryname: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update Category
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default UpdateCategory;
