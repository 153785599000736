import React, { useState, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import { addVehicle } from "../../../actions/admin/vehicle";
import { getVehicleTypes } from "../../../actions/admin/vehicletype";
import Alert from "../../layout/Alert";
import { useDispatch, useSelector } from "react-redux";

const AddVehicle = () => {
  const dispatch = useDispatch();
  const vehicleType = useSelector((state) => state.vehicletype);
  const { vehicletypes } = vehicleType;

  const vehicleTypeOptions = vehicletypes.map((d) => ({
    value: d._id,
    label: d.typename,
  }));

  useEffect(() => {
    dispatch(getVehicleTypes());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    vehiclename: "",
    vehicletype: null,
    registration: "",
    isactive: false,
  });

  const { vehiclename, registration, isactive } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(addVehicle(formData, null));
    setFormData({
      ...formData,
      vehiclename: "",
      registration: "",
      isactive: false,
    });
    console.log(formData);
  };

  const setOption = (e) => {
    setFormData({ ...formData, vehicletype: e.label });
  };
  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Add Vehicle" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <form onSubmit={(e) => onFormSubmit(e)}>

                <div className="card mb-3">
                  <div className="card-header">Vehicle status</div>
                  <div className="card-body">
                    <div className="mb-3">
                    <label className="form-check-label form-check-label-admin">Vehicle is active</label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={isactive}
                          name="isactive"
                          onChange={(e) => {
                            setFormData({ ...formData, isactive: !isactive });
                          }}
                        />
                
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-header">Vehicle details</div>
                  <div className="card-body">

                    <div className="mb-3">
                      <label className="label mb-2">Vehicle Name:</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control form-control-admin"
                          id="name"
                          placeholder="Enter name"
                          name="vehiclename"
                          value={vehiclename}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="label mb-2">Vehicle Type:</label>
                      <div className="col-sm-12">
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select pick-up location"
                          autoComplete="off"
                          // defaultValue={vehicleTypeOptions[0]}
                          onChange={(e) => {
                            setOption(e);
                          }}
                          options={vehicleTypeOptions}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="label mb-2">Vehicle Registration:</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control form-control-admin"
                          id="reg"
                          placeholder="Enter Vehicle Registration"
                          name="registration"
                          value={registration}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="mb-3">
                  <div className="col-sm-offset-2 col-sm-10">
                    <button type="submit" className="btn btn-primary">
                      <i
                        className="fa fa-plus-circle fa-fw"
                        aria-hidden="true"
                      ></i>{" "}
                      Add Vehicle
                    </button>
                  </div>
                </div>
                
              </form>
            </div>
          </div>
        </div>
        <Alert />
      </main>
    </Layout>
  );
};

export default AddVehicle;
