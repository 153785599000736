import {
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_ERROR,
  GET_SETTING,
  SET_REF,
  SET_LANG,
} from "../../actions/types";

const initialState = {
  setting:
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("jsonstring"))
      : null,
  loading: true,
  error: {},
  ref: null,
  lang: typeof window !== "undefined" ? localStorage.getItem("lang") : "jp",
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LANG:
      return {
        ...state,
        lang: payload,
        loading: false,
      };
    case UPDATE_SETTINGS:
    case GET_SETTING:
      return {
        ...state,
        setting: payload,
        loading: false,
      };
    case UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case SET_REF:
      return {
        ...state,
        ref: payload,
        loading: false,
      };
    default:
      return state;
  }
}
