import {
  ADD_PACKAGE,
  GET_PACKAGE,
  GET_PACKAGES,
  DELETE_PACKAGE,
  PACKAGE_ERROR,
  GET_NEW_PACKAGES,
  DELETE_NEW_PACKAGE,
} from "../../actions/types";

const initialState = {
  tpackages: [],
  tpackage: null,
  tnewpackages: [],
  tnewpackage: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NEW_PACKAGES:
    case DELETE_NEW_PACKAGE:
      return {
        ...state,
        tnewpackages: payload,
        loading: false,
      };
    case GET_PACKAGES:
    case DELETE_PACKAGE:
      return {
        ...state,
        tpackages: payload,
        loading: false,
      };
    case GET_PACKAGE:
    case ADD_PACKAGE:
      return {
        ...state,
        tpackage: payload,
        loading: false,
      };
    case PACKAGE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
