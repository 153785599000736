import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../../actions/auth";
import "./loginstyle.css";
import LogoImg from "../../../assets/images/bg_1.png";
import jwtDecode from "jwt-decode";

// import "./loginstyle.css";

const AdminLogin = ({ login, isAuthenticatedAdmin }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password, null, true);
  };

  if (isAuthenticatedAdmin) {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedJwt = jwtDecode(token);

      if (decodedJwt.user.role === "admin" || decodedJwt.user.role === undefined || decodedJwt.user.role === null) {
        return <Redirect to="/admin/dashboard" />;
      }
    } 
  }

  return (
    <section
      className="ftco-section"
      style={{ backgroundColor: "#f8f9fd", height: "100vh" }}
    >
      <div
        className="container shadow"
        style={{ borderRadius: "30px", backgroundColor: "white" }}
      >
        <div className="row justify-content-center"></div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12">
            <div
              className="wrap d-md-flex"
              style={{ borderRadius: "30px", padding: "30px" }}
            >
              <img
                src={LogoImg}
                className="img login-img"
                // style={{
                //   backgroundImage: LogoImg,
                //   backgroundSize: "contain",
                // }}
              ></img>
              <div className="login-wrap p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">
                      Welcome to the Booking Management System
                    </h3>
                  </div>
                </div>
                <form
                  action="#"
                  className="signin-form"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <div className="form-group mb-3">
                    <label className="label" htmlFor="name">
                      Username
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Username"
                      value={email}
                      onChange={(e) => onChange(e)}
                      className="form-control form-control-admin"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    {/* <input
                      type="text"
                      classname="form-control"
                      placeholder="Username"
                      required
                    /> */}
                  </div>
                  <div className="form-group mb-3">
                    <label className="label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      className="form-control form-control-admin"
                      id="exampleInputPassword1"
                    />

                    {/* <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required
                    /> */}
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="form-group d-md-flex">
                    {/* <div className="w-50 text-left">
                        <label className="checkbox-wrap checkbox-primary mb-0">
                          Remember Me
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AdminLogin.propType = {
  login: PropTypes.func.isRequired,
  isAuthenticatedAdmin: PropTypes.bool,
};

const mapsStateToProps = (state) => ({
  isAuthenticatedAdmin: state.auth.isAuthenticatedAdmin,
});

export default connect(mapsStateToProps, { login })(AdminLogin);
