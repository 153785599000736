import {
  PAGE_CONTENT_ERROR,
  ADD_PAGE_CONTENT,
  GET_PAGE_CONTENT,
  ON_GET_PAGE_CONTENT
} from "../types";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const getPageContents = (pagename) => async (dispatch) => {
  try {
    dispatch({
      type: ON_GET_PAGE_CONTENT,
    });
    const res = await customAxios.get(`/api/admin/cmspages/${pagename}`);
    dispatch({
      type: GET_PAGE_CONTENT,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: PAGE_CONTENT_ERROR,
      payload: { msg: err, status: err },
    });
  }
};

export const updateCMSPageContent = (formdata) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post("/api/admin/cmspages", formdata, config);
    dispatch({
      type: ADD_PAGE_CONTENT,
      payload: res.data,
    });
    dispatch(setAlert("Page updated", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      dispatch(setAlert(errors, "danger"));
    }
    dispatch({
      type: PAGE_CONTENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
