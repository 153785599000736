import React, { useState, useEffect, useTransition } from "react";
import LoadingOverlay from "react-loading-overlay";
import Layout from "../../layout/Layout";
import Alert from "../../layout/Alert";
import TitleBar from "../../layout/TitleBar";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCMSPageContent,
  getPageContents,
} from "../../../actions/admin/cmspage";

import { uploadFile } from "../../../actions/admin/awss3";
import { getFileDetails } from "../../../utils/Common";
import CMSImageUploaderCard from "../cardcomponents/CMSImageUploaderCard";

const CMSIndex = () => {
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage } = cmspage_state;
  const page_name = "cms-index";
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [formImageList, setFormImageList] = useState([]);
  // Initialize form data state
  const [formData, setFormData] = useState({
    pagename: page_name,
    page_content_en: {
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
      content11: "",
      content12: "",
      content13: "",
      content14: "",
      content15: "",
      content16: "",
      content17: "",
      content18: "",
      content19: "",
      content20: "",
      content21: "",
      content22: "",
      content23: "",
      content24: "",
      content25: "",
      content26: "",
      content27: "",
      content28: "",
      content29: "",
      content30: "",
      content31: "",
      content32: "",
      content33: "",
      content34: "",
      content35: "",
      content36: "",
      content37: "",
      content38: "",
      content39: "",
      content40: "",
      content41: "",
      content42: "",
      content43: "",
      content44: "",
      content45: "",
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      image6: "",
      image7: "",
      image8: "",
    },
    page_content_jp: {
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
      content11: "",
      content12: "",
      content13: "",
      content14: "",
      content15: "",
      content16: "",
      content17: "",
      content18: "",
      content19: "",
      content20: "",
      content21: "",
      content22: "",
      content23: "",
      content24: "",
      content25: "",
      content26: "",
      content27: "",
      content28: "",
      content29: "",
      content30: "",
      content31: "",
      content32: "",
      content33: "",
      content34: "",
      content35: "",
      content36: "",
      content37: "",
      content38: "",
      content39: "",
      content40: "",
      content41: "",
      content42: "",
      content43: "",
      content44: "",
      content45: "",
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      image6: "",
      image7: "",
      image8: "",
    },
  });
// Fetch CMS page data on initial render or when the page name changes
  useEffect(() => {
    if (cmspage?.pagename === page_name) setFormData(cmspage);
  }, [cmspage]);

  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  // Handle form input changes for English content
  const onChangeEn = (e) =>
    setFormData({
      ...formData,
      page_content_en: {
        ...formData.page_content_en,
        [e.target.name]: e.target.value,
      },
    });
 // Handle form input changes for Japanese content
  const onChangeJp = (e) =>
    setFormData({
      ...formData,
      page_content_jp: {
        ...formData.page_content_jp,
        [e.target.name]: e.target.value,
      },
    });
// Handle form submission to update CMS page content
  const onUpdateData = (e) => {
    e.preventDefault();
    setIsLoading(true);
    startTransition(async () => {
      const uploadedStatus = formImageList.map(async (f, i = 0) => {
        return await uploadFile(f.signedUrls, f.file.target.files[0]).then(
          (result) => {
            console.log(result);
            return result;
          }
        );
      });
      await Promise.all(uploadedStatus)
        .then(async (result) => {
          setIsLoading(false);
          dispatch(updateCMSPageContent(formData));
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    });
  };
// Set upload URL for English form images
  const setUploadUrlFromAWS_en = async (event, arrayImageName, formImage) => {
    if (event.target.files.length === 0) return;
    const { signedUrls, filePath } = await getFileDetails(event);
    setFormData({
      ...formData,
      page_content_en: {
        ...formData.page_content_en,
        [formImage]: filePath,
      },
    });
     // Set upload URL for Japanese form image
    addImagesToArray(arrayImageName, event, signedUrls, filePath);
  };

  const setUploadUrlFromAWS_jp = async (event, arrayImageName, formImage) => {
    if (event.target.files.length === 0) return;
    const { signedUrls, filePath } = await getFileDetails(event);
    setFormData({
      ...formData,
      page_content_jp: {
        ...formData.page_content_jp,
        [formImage]: filePath,
      },
    });
    addImagesToArray(arrayImageName, event, signedUrls, filePath);
  };
// Add uploaded images to the form image list
  const addImagesToArray = (imageName, event, signedUrls, filePath) => {
    const exists = formImageList.some((v) => v.image === imageName);
    if (!exists) {
      setFormImageList([
        ...formImageList,
        { image: imageName, file: event, signedUrls, filePath },
      ]);
    } else {
      const upd_obj = formImageList.map((obj) => {
        if (obj.image === imageName) {
          obj.file = event;
          obj.signedUrls = signedUrls;
          obj.filePath = filePath;
        }
        return obj;
      });
      setFormImageList(upd_obj);
    }
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="CMS | index" />
          <div className="container-fluid">
            <form onSubmit={(e) => {}}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label mb-2">
                      1. Hero - Main title (en):
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content1"
                        value={formData?.page_content_en?.content1}
                        onChange={(e) => onChangeEn(e)}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">2. Section 1 - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content2"
                        value={formData?.page_content_en?.content2}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      2. Section 1 - Card 1 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content3"
                        value={formData?.page_content_en?.content3}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      4. Section 1 - Card 1 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content4"
                        value={formData?.page_content_en?.content4}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 1 - [Image 1]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image1"}
                    formImage={"image1"}
                    awsImageUrl={formData?.page_content_en.image1}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      5. Section 1 - Card 2 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content5"
                        value={formData?.page_content_en?.content5}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      6. Section 1 - Card 2 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content6"
                        value={formData?.page_content_en?.content6}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 2 - [Image 2]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image2"}
                    formImage={"image2"}
                    awsImageUrl={formData?.page_content_en.image2}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      7. Section 1 - Card 3 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content7"
                        value={formData?.page_content_en?.content7}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      8. Section 1 - Card 3 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content8"
                        value={formData?.page_content_en?.content8}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 3 - [Image 3]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image3"}
                    formImage={"image3"}
                    awsImageUrl={formData?.page_content_en.image3}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      9. Section 1 - Card 4 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content9"
                        value={formData?.page_content_en?.content9}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      10. Section 1 - Card 4 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content10"
                        value={formData?.page_content_en?.content10}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 4 - [Image 4]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image4"}
                    formImage={"image4"}
                    awsImageUrl={formData?.page_content_en.image4}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">11. Section 2 - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content11"
                        value={formData?.page_content_en?.content11}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      12. Section 2 - Card 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content12"
                        value={formData?.page_content_en?.content12}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      13. Section 2 - Card 1 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content13"
                        value={formData?.page_content_en?.content13}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      14. Section 2 - Card 1 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content14"
                        value={formData?.page_content_en?.content14}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 1 - [Image 5]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image5"}
                    formImage={"image5"}
                    awsImageUrl={formData?.page_content_en.image5}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      15. Section 2 - Card 2 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content15"
                        value={formData?.page_content_en?.content15}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      16. Section 2 - Card 2 - Heading (Large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content16"
                        value={formData?.page_content_en?.content16}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      17. Section 2 - Card 2 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content17"
                        value={formData?.page_content_en?.content17}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <CMSImageUploaderCard
                    title={"Section 2 - Card 2 - [Image 6]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image6"}
                    formImage={"image6"}
                    awsImageUrl={formData?.page_content_en.image6}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      18. Section 2 - Card 3 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content18"
                        value={formData?.page_content_en?.content18}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      19. Section 2 - Card 3 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content19"
                        value={formData?.page_content_en?.content19}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      20. Section 2 - Card 3 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content20"
                        value={formData?.page_content_en?.content20}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 3 - [Image 7]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image7"}
                    formImage={"image7"}
                    awsImageUrl={formData?.page_content_en.image7}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      21. Section 2 - Card 4 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content21"
                        value={formData?.page_content_en?.content21}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      22. Section 2 - Card 4 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content22"
                        value={formData?.page_content_en?.content22}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      23. Section 2 - Card 4 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content23"
                        value={formData?.page_content_en?.content23}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 4 - [Image 8]"}
                    onChangeEvent={setUploadUrlFromAWS_en}
                    arrayImageName={"en_image8"}
                    formImage={"image8"}
                    awsImageUrl={formData?.page_content_en.image8}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      24. Section 2 - Horizontal Card 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content24"
                        value={formData?.page_content_en?.content24}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      25. Section 2 - Horizontal Card 1 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content25"
                        value={formData?.page_content_en?.content25}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      26. Section 2 - Horizontal Card 1 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content26"
                        value={formData?.page_content_en?.content26}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      27. Section 2 - Horizontal Card 2 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content27"
                        value={formData?.page_content_en?.content27}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      28. Section 2 - Horizontal Card 2 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content28"
                        value={formData?.page_content_en?.content28}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      29. Section 2 - Horizontal Card 2 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content29"
                        value={formData?.page_content_en?.content29}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">30. Section 3 - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content30"
                        value={formData?.page_content_en?.content30}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      31. Section 3 - Card 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content31"
                        value={formData?.page_content_en?.content31}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      32. Section 3 - Card 1 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content32"
                        value={formData?.page_content_en?.content32}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      33. Section 3 - Card 1 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content33"
                        value={formData?.page_content_en?.content33}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      34. Section 3 - Card 1 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content34"
                        value={formData?.page_content_en?.content34}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      35. Section 3 - Card 2 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content35"
                        value={formData?.page_content_en?.content35}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      36. Section 3 - Card 2 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content36"
                        value={formData?.page_content_en?.content36}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      37. Section 3 - Card 2 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content37"
                        value={formData?.page_content_en?.content37}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      38. Section 3 - Card 3 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content38"
                        value={formData?.page_content_en?.content38}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      39. Section 3 - Card 3 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content39"
                        value={formData?.page_content_en?.content39}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      40. Section 3 - Card 3 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content40"
                        value={formData?.page_content_en?.content40}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      41. Section 3 - Card 3 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content41"
                        value={formData?.page_content_en?.content41}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      42. Section 3 - Card 4 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content42"
                        value={formData?.page_content_en?.content42}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      43. Section 3 - Card 4 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content43"
                        value={formData?.page_content_en?.content43}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      44. Section 3 - Card 4 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content44"
                        value={formData?.page_content_en?.content44}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      45. Section 3 - Card 4 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content45"
                        value={formData?.page_content_en?.content45}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* second cards */}

                {/*  */}
                <div className="col-md-6 bg-light">
                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Main title (jp):
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content1"
                        value={formData?.page_content_jp?.content1}
                        onChange={(e) => onChangeJp(e)}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Section 1 - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content2"
                        value={formData?.page_content_jp?.content2}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 1 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content3"
                        value={formData?.page_content_jp?.content3}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 1 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content4"
                        value={formData?.page_content_jp?.content4}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 1 - [Image 1]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image1"}
                    formImage={"image1"}
                    awsImageUrl={formData?.page_content_jp.image1}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 2 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content5"
                        value={formData?.page_content_jp?.content5}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 2 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content6"
                        value={formData?.page_content_jp?.content6}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 2 - [Image 2]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image2"}
                    formImage={"image2"}
                    awsImageUrl={formData?.page_content_jp.image2}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 3 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content7"
                        value={formData?.page_content_jp?.content7}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 3 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content8"
                        value={formData?.page_content_jp?.content8}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 3 - [Image 3]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image3"}
                    formImage={"image3"}
                    awsImageUrl={formData?.page_content_jp.image3}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 4 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content9"
                        value={formData?.page_content_jp?.content9}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Card 4 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content10"
                        value={formData?.page_content_jp?.content10}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 1 - Card 4 - [Image 4]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image4"}
                    formImage={"image4"}
                    awsImageUrl={formData?.page_content_jp.image4}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">Section 2 - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content11"
                        value={formData?.page_content_jp?.content11}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content12"
                        value={formData?.page_content_jp?.content12}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 1 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content13"
                        value={formData?.page_content_jp?.content13}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 1 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content14"
                        value={formData?.page_content_jp?.content14}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 1 - [Image 5]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image5"}
                    formImage={"image5"}
                    awsImageUrl={formData?.page_content_jp.image5}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 2 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content15"
                        value={formData?.page_content_jp?.content15}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 2 - Heading (Large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content16"
                        value={formData?.page_content_jp?.content16}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 2 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content17"
                        value={formData?.page_content_jp?.content17}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 1 - [Image 6]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image6"}
                    formImage={"image6"}
                    awsImageUrl={formData?.page_content_jp.image6}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 3 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content18"
                        value={formData?.page_content_jp?.content18}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 3 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content19"
                        value={formData?.page_content_jp?.content19}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 3 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content20"
                        value={formData?.page_content_jp?.content20}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 1 - [Image 7]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image7"}
                    formImage={"image7"}
                    awsImageUrl={formData?.page_content_jp.image7}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 4 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content21"
                        value={formData?.page_content_jp?.content21}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 4 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content22"
                        value={formData?.page_content_jp?.content22}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Card 4 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content23"
                        value={formData?.page_content_jp?.content23}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <CMSImageUploaderCard
                    title={"Section 2 - Card 1 - [Image 8]"}
                    onChangeEvent={setUploadUrlFromAWS_jp}
                    arrayImageName={"jp_image8"}
                    formImage={"image8"}
                    awsImageUrl={formData?.page_content_jp.image8}
                  />

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Horizontal Card 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content24"
                        value={formData?.page_content_jp?.content24}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Horizontal Card 1 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content25"
                        value={formData?.page_content_jp?.content25}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Horizontal Card 1 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content26"
                        value={formData?.page_content_jp?.content26}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Horizontal Card 2 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content27"
                        value={formData?.page_content_jp?.content27}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Horizontal Card 2 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content28"
                        value={formData?.page_content_jp?.content28}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Horizontal Card 2 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content29"
                        value={formData?.page_content_jp?.content29}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Section 3 - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content30"
                        value={formData?.page_content_jp?.content30}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content31"
                        value={formData?.page_content_jp?.content31}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 1 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content32"
                        value={formData?.page_content_jp?.content32}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 1 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content33"
                        value={formData?.page_content_jp?.content33}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 1 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content34"
                        value={formData?.page_content_jp?.content34}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 2 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content35"
                        value={formData?.page_content_jp?.content35}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 2 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content36"
                        value={formData?.page_content_jp?.content36}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 2 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content37"
                        value={formData?.page_content_jp?.content37}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 3 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content38"
                        value={formData?.page_content_jp?.content38}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 3 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content39"
                        value={formData?.page_content_jp?.content39}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 3 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content40"
                        value={formData?.page_content_jp?.content40}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 3 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content41"
                        value={formData?.page_content_jp?.content41}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 4 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content42"
                        value={formData?.page_content_jp?.content42}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 4 - Heading (large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content43"
                        value={formData?.page_content_jp?.content43}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 4 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content44"
                        value={formData?.page_content_jp?.content44}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 3 - Card 4 - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content45"
                        value={formData?.page_content_jp?.content45}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={(e) => onUpdateData(e)}
                      >
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update content
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </form>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default CMSIndex;
