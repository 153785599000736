import {
    ON_GET_INDIVIDUAL_BOOKINGS,
    GET_INDIVIDUAL_BOOKINGS,
    INDIVIDUAL_BOOKINGS_ERROR
  } from "../actions/types";
  
  const initialState = {
    customerBookings: [],
    customerBooking: null,
    error: {},
    loading : true
  };
  export default function foo(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case ON_GET_INDIVIDUAL_BOOKINGS:
        return {
          ...state,
          // customerBookings: payload,
          loading: true,
        };
      case GET_INDIVIDUAL_BOOKINGS:
        return {
          ...state,
          customerBookings: payload,
          loading: false,
        };
      case INDIVIDUAL_BOOKINGS_ERROR:
        return {
          ...state,
          loading: false,
          error: payload,
        };
      default:
        return state;
    }
  }