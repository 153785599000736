import React, { useState, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import { addNewPackage } from "../../../actions/admin/package";
import { getVehicleTypes } from "../../../actions/admin/vehicletype";
import { getCategories } from "../../../actions/admin/category";
import Creatable from "react-select/creatable";
import Alert from "../../layout/Alert";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import _ from "lodash";
import { setAlert } from "../../../actions/alert";

const AddPackageNew = () => {
  const dispatch = useDispatch();
  const vehicleType = useSelector((state) => state.vehicletype);
  const airports_state = useSelector((state) => state.airport);
  const category_state = useSelector((state) => state.category);
  const { vehicletypes } = vehicleType;
  const { categories } = category_state;

  const [isloading, setIsloading] = useState(false);

  const [vtype, setVtype] = useState(null);
  const [vtypeval, setVtypeVal] = useState("#ced4da");
  const [vehicleCost, setVehicleCost] = useState(0);
  const [vehicleCostList, setVehicleCostList] = useState([]);

  const [formData, setFormData] = useState({
    isAirportTransfer: true,
    type: "",
    packagename: "",
    description: "",
    category: null,
    image: null,
    pickupTags: [],
    dropoffTags: [],
    isactive: true,
  });

  const addVehicle = (e) => {
    if (!vtype) return;
    const exists = vehicleCostList.some((v) => v.vtype === vtype.label);
    if (!exists) {
      setVehicleCostList([
        ...vehicleCostList,
        { vtype: vtype.label, vehicleCost, vehicle: vtype.value },
      ]);
      setVtype(null);
      setVehicleCost(0);
    } else {
      dispatch(
        setAlert(
          "Vehicle alrady added. Please remove existing vehicle and add vehicle with new cost.",
          "warning"
        )
      );
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getVehicleTypes());
    dispatch(getCategories());
  }, [dispatch]);
  const onFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    console.log(vehicleCostList);
    if (!validateForm()) return;
    const pkgObj = {
      isAirportTransfer: formData.isAirportTransfer,
      type: formData.type,
      packagename: formData.packagename,
      description: formData.description,
      categoryValues: formData.category,
      image: formData.image,
      pickupTags: formData.pickupTags,
      dropoffTags: formData.dropoffTags,
      vehicles: vehicleCostList,
      isactive: formData.isactive,
    };
    dispatch(addNewPackage(pkgObj));
    clearForm();
  };

  const validateForm = () => {
    if (
      formData.packagename === "" ||
      formData.type === "" ||
      formData.image === null ||
      formData.pickupTags.length == 0 ||
      formData.dropoffTags.length == 0 ||
      vehicleCostList.length == 0
    ) {
      return false;
    } else if (
      formData.isAirportTransfer == true && (formData.type == "basic" || formData.category == null)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const clearForm = () => {
    setFormData({
      isAirportTransfer: true,
      type: "",
      packagename: "",
      description: "",
      category: null,
      image: null,
      pickupTags: [],
      dropoffTags: [],
      isactive: true,
    });
    setVehicleCostList([]);
    setVtype(null);
    setVehicleCost(0);
  };

  const imageTypeOptions = [
    {
      value: "1",
      label: "Image1",
    },
    {
      value: "2",
      label: "Image2",
    },
    {
      value: "3",
      label: "Image3",
    },
    {
      value: "4",
      label: "Image4",
    },
  ];

  const categoryTypeOptions = categories.map((d) => ({
    value: d._id,
    label: d.categoryname,
  }));

  const vehicleTypeOptions = vehicletypes.map((d) => ({
    value: d._id,
    label: d.typename,
  }));

  const onChangePickupTagsValue = async (e) => {
    const lowercaseArr = e.map((element) => {
      return {
        label: element.label.toLowerCase(),
        value: element.value.toLowerCase(),
      };
    });
    setFormData({ ...formData, pickupTags: lowercaseArr });
  };

  const onChangeDropoffTagsValue = async (e) => {
    const lowercaseArr = e.map((element) => {
      return {
        label: element.label.toLowerCase(),
        value: element.value.toLowerCase(),
      };
    });
    setFormData({ ...formData, dropoffTags: lowercaseArr });
  };

  console.log(formData);

  const _handleRadio = (event) => {
    console.log(event.target.value);
    setFormData({ ...formData, type: event.target.value });
  };

  const _handleRadioAirportTransfer = (event) => {
    console.log(event.target.value);
    console.log(event.currentTarget.value);

    if (event.currentTarget.value == "true") {
      var isAirportTransfer = true;
      setFormData({ ...formData, isAirportTransfer: isAirportTransfer });
    } else if (event.currentTarget.value == "false") {
      var isAirportTransfer = false;

      setFormData({ ...formData, isAirportTransfer: isAirportTransfer, type: "basic" });
    }
  };

  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Add Package" />
          <div className="container-fluid">
            <form onSubmit={(e) => onFormSubmit(e)}>
              <div className="row">
                <div className="col-md-6">

                  <div className="card mb-3">
                    <div className="card-header">Package status</div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="form-check-label form-check-label-admin">Package is active:</label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={formData.isactive}
                            name="isactive"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                isactive: !formData.isactive,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="label mb-2">Package type:</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isAirportTransfer"
                            value={true}
                            id="isAirportTransfer"
                            checked={formData.isAirportTransfer}
                            onChange={(e) => _handleRadioAirportTransfer(e)}
                          />
                          <label className="form-check-label form-check-label-admin" htmlFor="isAirportTransfer">Airport Transfer</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isAirportTransfer"
                            value={false}
                            id="notAirportTransfer"
                            checked={!formData.isAirportTransfer}
                            onChange={(e) => _handleRadioAirportTransfer(e)}
                          />
                          <label className="form-check-label form-check-label-admin" htmlFor="notAirportTransfer">Not Airport Transfer (Basic)</label>
                        </div>
                      </div>
                      {
                        formData.isAirportTransfer ? (
                          <div className="mb-3">
                            <label className="label mb-2">Package is:</label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                value={"fromAirport"}
                                id="fromAirport"
                                checked={formData.type == "fromAirport"}
                                onChange={(e) => _handleRadio(e)}
                              />
                              <label className="form-check-label form-check-label-admin" htmlFor="fromAirport">From Airport</label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                value={"toAirport"}
                                id="toAirport"
                                checked={formData.type == "toAirport"}
                                onChange={(e) => _handleRadio(e)}
                              />
                              <label className="form-check-label form-check-label-admin" htmlFor="toAirport">To Airport</label>
                            </div>
                          </div>
                        ) : ""
                      }
                    </div>
                  </div>


                  <div className="card mb-3">
                    <div className="card-header">Package details</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Package Name:</label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter package name"
                            name="packagename"
                            value={formData.packagename}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Description:</label>
                        <div className="col">
                          <textarea
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="optional"
                            name="description"
                            value={formData.description}
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>

                      {/* Radio buttons for from and to air port */}

                      {
                        formData.isAirportTransfer ? (
                          <div className="mb-3">
                            <label className="label mb-2">Category:</label>
                            <div className="col">
                              <Select
                                className="basic-multi-select"
                                classNamePrefix="select pick-up location"
                                autoComplete="off"
                                value={formData.category}
                                onChange={(e) => {
                                  setFormData({ ...formData, category: e });
                                }}
                                options={categoryTypeOptions}
                                defaultValue={categoryTypeOptions[0]}
                              />
                            </div>
                          </div>
                        ) : ""
                      }

                      <div className="mb-3">
                        <label className="label mb-2">Image:</label>
                        <div className="col">
                          <Select
                            className="basic-multi-select"
                            classNamePrefix="select pick-up location"
                            autoComplete="off"
                            value={formData.image}
                            onChange={(e) => {
                              setFormData({ ...formData, image: e });
                            }}
                            options={imageTypeOptions}
                            defaultValue={imageTypeOptions[0]}
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Add Package
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card mb-3">
                    <div className="card-header">Package Detail Location</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Search Pickup Tags:</label>
                        <div className="col">
                          <Creatable
                            // className="w-full border border-gray-200 focus:border-gray-500 focus:outline-none focus:bg-white"
                            isClearable
                            isMulti={true}
                            onChange={(v) => onChangePickupTagsValue(v)}
                            options={[]}
                            value={formData.pickupTags}
                            placeholder={"Type tag name and press enter..."}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Search Dropoff Tags:</label>
                        <div className="col">
                          <Creatable
                            // className="w-full border border-gray-200 focus:border-gray-500 focus:outline-none focus:bg-white"
                            isClearable
                            isMulti={true}
                            onChange={(v) => onChangeDropoffTagsValue(v)}
                            options={[]}
                            value={formData.dropoffTags}
                            placeholder={"Type tag name and press enter..."}
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">Available vehicles</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Add Vehicles:</label>

                        <div className="row">
                          <div className="col-5">
                            <Select
                              name="vehicleType"
                              value={vtype}
                              onChange={(e) => {
                                setVtype(e);
                                setVtypeVal("#ced4da");
                              }}
                              options={vehicleTypeOptions}
                              classNamePrefix="select"
                              autoComplete="off"
                            // styles={styleVtype}
                            />
                          </div>
                          <div className="col-3">
                            <input
                              type="number"
                              className={`form-control form-control-admin`}
                              placeholder="Cost"
                              name="vCost"
                              value={vehicleCost}
                              onChange={(e) => {
                                setVehicleCost(e.target.value);
                                // setPaxCount(Number(e.target.value));
                                // setPaxcountVal("");
                              }}
                            />
                          </div>

                          <div className="col-3">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={(e) => {
                                addVehicle(e);
                              }}
                            >
                              <i
                                className="fa fa-plus fa-fw"
                                aria-hidden="true"
                              ></i>
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        {vehicleCostList.map((x, i = 100) => (
                          <span
                            key={i++}
                            className="badge bg-warning text-dark pax-card"
                          >
                            {x.vehicleCost} - {x.vtype}{" "}
                            <a href="#!">
                              <i
                                className="fa fa-times fa-fw"
                                aria-hidden="true"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setVehicleCostList(
                                    vehicleCostList.filter(
                                      (item) => item.vtype !== x.vtype
                                    )
                                  );
                                }}
                              ></i>
                            </a>
                          </span>
                        ))}
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default AddPackageNew;
