import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.NODE_ENV !== "production" ? "http://localhost:5001" : "",
  timeout: 10000,
  headers: {
    "Access-Control-Allow-Origin":
      process.env.NODE_ENV !== "production" ? "http://localhost:3000" : "",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
  },
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  // request.headers.Authorization =
  //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTIzNDU2Nzg5IiwibmFtZSI6IlNhbXBsZSIsImlhdCI6MTUxNjIzODIzfQ.ZEBwz4pWYGqgFJc6DIi7HdTN0z5Pfs4Lcv4ZNwMr1rs";

  return request;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    // console.log("responseHandler--------->");
    console.log(error);
    // console.log(error.response.data.msg);
    if (
      error.response.status === 401 &&
      error.response.data.msg === "InValidToken"
    ) {
      localStorage.removeItem("token");
      window.location = "/admin";
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// customAxios.interceptors.response.use(
//   (response) => responseHandler(response),
//   (error) => errorHandler(error)
// );

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
