import NotificationItem from "./NotificationItem";
import {
  getCustomerNotification,
  getCustomerNotificationDetail,
} from "../../../../actions/notification";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import Spinner from "../../../layout/Spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const customerNotifications = useSelector((state) => state.notification);
  const { loading, notifications } = customerNotifications;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomerNotification());
  }, [dispatch]);

  const handleDetailButton = (id) => {
    dispatch(getCustomerNotificationDetail(id));
  };

  loading ? console.log("loading") : console.log(notifications);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="card mt-3 card-myPage">
          <div className="card-body">
            <div className="py-3 px-2">
              {notifications?.uncheckedNotif?.length > 0 &&
              notifications?.checkedNotif?.length > 0 ? (
                <>
                  {notifications?.uncheckedNotif?.length > 0 && (
                    <>
                      <h5 className="mb-4">Unchecked</h5>
                      {notifications?.uncheckedNotif?.map((val) => {
                        return (
                          <NotificationItem
                            title={lang == "EN" ? val.titleEN : val.titleJP}
                            description={
                              lang == "EN"
                                ? val.descriptionEN
                                : val.descriptionJP
                            }
                            id={val._id}
                            checkStatus={false}
                            handleDetailButton={handleDetailButton}
                          />
                        );
                      })}
                    </>
                  )}
                  {notifications?.checkedNotif?.length > 0 && (
                    <>
                      <h5 className="mt-4 mb-3">Checked</h5>
                      {notifications?.checkedNotif?.map((val) => {
                        return (
                          <NotificationItem
                            title={lang == "EN" ? val.titleEN : val.titleJP}
                            description={
                              lang == "EN"
                                ? val.descriptionEN
                                : val.descriptionJP
                            }
                            id={val._id}
                            checkStatus={true}
                            handleDetailButton={handleDetailButton}
                          />
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <div className="border d-flex justify-content-center align-items-center py-5 my-2 rounded">
                  <p>{t("my_page.bookings_page.no_notification")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
