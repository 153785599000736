import {
  ADD_VEHICLE_TYPE,
  VEHICLE_TYPE_ERROR,
  GET_VEHICLE_TYPE,
  GET_VEHICLE_TYPES,
} from "../../actions/types";

const initialState = {
  vehicletypes: [],
  vehicletype: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_VEHICLE_TYPE:
      return {
        ...state,
        vehicletype: payload,
        loading: false,
      };
    case GET_VEHICLE_TYPE:
      return {
        ...state,
        vehicletype: payload,
        loading: false,
      };
    case GET_VEHICLE_TYPES:
      return {
        ...state,
        vehicletypes: payload,
        loading: false,
      };
    case VEHICLE_TYPE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
