import { v4 as uuidv4 } from "uuid";
import { SET_ALERT, REMOVE_ALERT, SET_ALERT_EDIT_BOOKING, REMOVE_ALERT_EDIT_BOOKING } from "./types";

export const setAlert =
  (msg, alertType, timeout = 5000) =>
    (dispatch) => {
      const id = uuidv4();
      dispatch({
        type: SET_ALERT,
        payload: { msg, alertType, id },
      });
      setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
    };

export const setAlertEditBooking =
  (alertType, isSuccess, timeout = 5000) =>
    (dispatch) => {
      const id = uuidv4();
      dispatch({
        type: SET_ALERT_EDIT_BOOKING,
        payload: { alertType, isSuccess, id },
      });
      setTimeout(() => dispatch({ type: REMOVE_ALERT_EDIT_BOOKING, payload: id }), timeout);
    };