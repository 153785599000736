import {
  ADD_CANCEL_RESCHEDULE_REQUEST,
  CANCEL_RESCHEDULE_REQUEST_ERROR,
  GET_CANCEL_RESCHEDULE_REQUESTS,
  GET_ACTIVE_CANCEL_RESCHEDULE_REQUESTS,
  GET_CANCEL_RESCHEDULE_REQUEST,
  ADD_RESPONSE_CANCEL_RESCHEDULE_REQUEST
} from "../../actions/types";

const initialState = {
  activecancelreschedulerequests: [],
  cancelreschedulerequests: [],
  cancelreschedulerequest: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_CANCEL_RESCHEDULE_REQUEST:
      return {
        ...state,
        cancelreschedulerequest: payload,
        loading: false,
      };
    case GET_CANCEL_RESCHEDULE_REQUEST:
      return {
        ...state,
        cancelreschedulerequest: payload,
        loading: false,
      };
    case GET_CANCEL_RESCHEDULE_REQUESTS:
      return {
        ...state,
        cancelreschedulerequests: payload,
        loading: false,
      };
    case GET_ACTIVE_CANCEL_RESCHEDULE_REQUESTS:
      return {
        ...state,
        activecancelreschedulerequests: payload,
        loading: false,
      };
    case CANCEL_RESCHEDULE_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADD_RESPONSE_CANCEL_RESCHEDULE_REQUEST:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
