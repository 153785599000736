import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { getDrivers } from "../../../actions/admin/driver";
import DriverItem from "./DriverItem";
import { Fragment } from "react";
// import tableSort from "table-sort-js/table-sort";

const DriversList = () => {
  const driver = useSelector((state) => state.driver);
  const { loading, drivers } = driver;
  const dispatch = useDispatch();
  useEffect(() => {
    // tableSort();
    dispatch(getDrivers());
  }, [dispatch]);
  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Driver's List" />

        <h4 className="my-4">All Available Drivers</h4>
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <table className="table-sort table table-hover table-sm booking-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Full time/ Part time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {drivers.map((driver, i = 0) => (
                    <DriverItem key={driver._id} drivers={driver} count={i++} />
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default DriversList;
