import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const VehicleItem = ({
  vehicle: { _id, vehiclename, vehicletype, registration, isactive, date },
  count,
}) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>{vehiclename}</td>
      <td>{vehicletype}</td>
      <td>{registration}</td>
      <td>
        {isactive ? (
          <span className="badge bg-success py-2">Active</span>
        ) : (
          <span className="badge bg-grey-2 py-2">Inactive</span>
        )}
      </td>
      <td>
        <Moment format="YYYY/MM/DD">{date}</Moment>
      </td>
      <td>
        <Link
          type="button"
          className="btn btn-secondary btn-sm py-2 px-4"
          to={`/admin/vehiclelist/edit/${_id}`}
        >
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default VehicleItem;
