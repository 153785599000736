import React, { useState, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { addDriver, getDriver } from "../../../actions/admin/driver";
import Alert from "../../layout/Alert";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../layout/Spinner";

const UpdateDriver = ({ match, history }) => {
  const dispatch = useDispatch();
  const driverState = useSelector((state) => state.driver);
  const { loading, driver } = driverState;

  const [formData, setFormData] = useState({
    _id: null,
    contract: "",
    name: "",
    email: "",
    phone: "",
    isfulltime: true,
    isactive: false,
  });
  const { contract, name, email, phone, isfulltime, isactive } = formData;
  useEffect(() => {
    dispatch(getDriver(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    //console.log(driver);
    setFormData({
      _id: loading || !driver?._id ? "" : driver?._id,
      name: loading || !driver?.name ? "" : driver?.name,
      contract: loading || !driver?.contract ? "" : driver?.contract,
      email: loading || !driver?.email ? "" : driver?.email,
      phone: loading || !driver?.phone ? "" : driver?.phone,
      isfulltime: loading || !driver?.isfulltime ? false : driver?.isfulltime,
      isactive: loading || !driver?.isactive ? "" : driver?.isactive,
    });
  }, [loading, driver]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(addDriver(formData, history, true));

    //console.log(formData);
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Update Driver" />
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5">
                  <form onSubmit={(e) => onFormSubmit(e)}>

                    <div className="mb-3">
                      <div className="col-sm-offset-2 col-sm-10">
                        <Link
                          type="submit"
                          className="btn btn-secondary"
                          to="/admin/driverlist"
                        >
                          <i
                            className="fa fa-arrow-left fa-fw"
                            aria-hidden="true"
                          ></i>{" "}
                          Back To List
                        </Link>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header">Driver status</div>
                      <div className="card-body">
                        <div className="mb-3">
                          <label className="form-check-label form-check-label-admin">
                            Driver is active
                          </label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="isactive"
                              checked={isactive}
                              name="isactive"
                              onChange={(e) => {
                                setFormData({ ...formData, isactive: !isactive });
                              }}
                            />

                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="label mb-2">Driver is:</label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="isfulltime"
                              value="fulltime"
                              checked={isfulltime}
                              onChange={(e) => {
                                setFormData({ ...formData, isfulltime: true });
                              }}
                            />
                            <label className="form-check-label form-check-label-admin">Full time</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="parttime"
                              value="parttime"
                              checked={!isfulltime}
                              onChange={(e) => {
                                setFormData({ ...formData, isfulltime: false });
                              }}
                            />
                            <label className="form-check-label form-check-label-admin">Part time</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="card-header">Driver details</div>
                      <div className="card-body">

                        <div className="mb-3">
                          <label className="label mb-2">Name:</label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control form-control-admin"
                              id="name"
                              placeholder="Enter name"
                              name="name"
                              value={name}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="label mb-2">Contract:</label>
                          <div className="col-sm-12">
                            <textarea
                              type="text"
                              className="form-control form-control-admin"
                              placeholder="Enter contract"
                              name="contract"
                              value={contract}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="label mb-2">Email:</label>
                          <div className="col-sm-12">
                            <input
                              type="email"
                              className="form-control form-control-admin"
                              id="email"
                              placeholder="Enter email"
                              name="email"
                              value={email}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="label mb-2">Phone Number:</label>
                          <div className="col-sm-12">
                            <input
                              type="phone"
                              className="form-control form-control-admin"
                              id="phone"
                              placeholder="Enter phone number"
                              name="phone"
                              value={phone}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          <i
                            className="fa fa-plus-circle fa-fw"
                            aria-hidden="true"
                          ></i>{" "}
                          Update
                        </button>{" "}

                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </Fragment>
        )
        }

        <Alert />
      </main >
    </Layout >
  );
};

export default withRouter(UpdateDriver);

// import React, { useState, useEffect, Fragment } from "react";
// import { Link, withRouter } from "react-router-dom";
// import TitleBar from "../../layout/TitleBar";
// import Layout from "../../layout/Layout";
// import { addDriver, getDriver } from "../../../actions/admin/driver";
// import Alert from "../../layout/Alert";

// import { useDispatch, useSelector } from "react-redux";
// import Spinner from "../../layout/Spinner";

// const UpdateDriver = ({ match, history }) => {
//   const dispatch = useDispatch();
//   const driverState = useSelector((state) => state.driver);
//   const { loading, driver } = driverState;

//   const [formData, setFormData] = useState({
//     _id: null,
//     name: "",
//     username: "",
//     email: "",
//     phone: "",
//     password: "",
//     confpassword: "",
//     isfulltime: true,
//     isactive: false,
//   });
//   const {
//     _id,
//     name,
//     username,
//     email,
//     phone,
//     password,
//     confpassword,
//     isfulltime,
//     isactive,
//   } = formData;
//   useEffect(() => {
//     dispatch(getDriver(match.params.id));
//   }, [getDriver, match.params.id]);

//   useEffect(() => {
//     //console.log(driver);
//     setFormData({
//       _id: loading || !driver?._id ? "" : driver?._id,
//       name: loading || !driver?.name ? "" : driver?.name,
//       username: loading || !driver?.username ? "" : driver?.username,
//       email: loading || !driver?.email ? "" : driver?.email,
//       phone: loading || !driver?.phone ? "" : driver?.phone,
//       password: loading || !driver?.password ? "" : driver?.password,
//       confpassword: loading || !driver?.password ? "" : driver?.password,
//       isfulltime: loading || !driver?.isfulltime ? false : driver?.isfulltime,
//       isactive: loading || !driver?.isactive ? "" : driver?.isactive,
//     });
//   }, [loading, driver]);

//   const onChange = (e) =>
//     setFormData({ ...formData, [e.target.name]: e.target.value });

//   const onFormSubmit = (e) => {
//     e.preventDefault();
//     dispatch(addDriver(formData, history, true));

//     console.log(formData);
//   };

//   return (
//     <Layout>
//       <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
//         <TitleBar title="Update Driver" />
//         {loading ? (
//           <Spinner />
//         ) : (
//           <Fragment>
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col-md-5">
//                   <form onSubmit={(e) => onFormSubmit(e)}>
//                     <div className="mb-3">
//                       <label className="label mb-2">Name:</label>
//                       <div className="col-sm-10">
//                         <input
//                           type="text"
//                           className="form-control form-control-admin"
//                           id="name"
//                           placeholder="Enter name"
//                           name="name"
//                           value={name}
//                           onChange={(e) => onChange(e)}
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="mb-3">
//                       <label className="label mb-2">Username:</label>
//                       <div className="col-sm-10">
//                         <input
//                           type="text"
//                           className="form-control form-control-admin"
//                           id="username"
//                           placeholder="Enter Username"
//                           name="username"
//                           value={username}
//                           onChange={(e) => onChange(e)}
//                           required
//                         />
//                       </div>
//                     </div>
//                     <div className="mb-3">
//                       <label className="label mb-2">Email:</label>
//                       <div className="col-sm-10">
//                         <input
//                           type="email"
//                           className="form-control form-control-admin"
//                           id="email"
//                           placeholder="Enter email"
//                           name="email"
//                           value={email}
//                           onChange={(e) => onChange(e)}
//                           required
//                         />
//                       </div>
//                     </div>

//                     <div className="mb-3">
//                       <label className="label mb-2">Phone Number:</label>
//                       <div className="col-sm-10">
//                         <input
//                           type="phone"
//                           className="form-control form-control-admin"
//                           id="phone"
//                           placeholder="Enter phone number"
//                           name="phone"
//                           value={phone}
//                           onChange={(e) => onChange(e)}
//                         />
//                       </div>
//                     </div>

//                     <div className="mb-3">
//                       <label className="label mb-2">Password:</label>
//                       <div className="col-sm-10">
//                         <input
//                           type="password"
//                           className="form-control form-control-admin"
//                           id="password"
//                           placeholder="Enter password"
//                           name="password"
//                           value={password}
//                           onChange={(e) => onChange(e)}
//                           required
//                         />
//                       </div>
//                     </div>

//                     <div className="mb-3">
//                       <label className="label mb-2">Confirm Password:</label>
//                       <div className="col-sm-10">
//                         <input
//                           type="password"
//                           className="form-control form-control-admin"
//                           id="confpassword"
//                           placeholder="Confirm password"
//                           name="confpassword"
//                           value={confpassword}
//                           onChange={(e) => onChange(e)}
//                         />
//                       </div>
//                     </div>

//                     <div className="mb-3">
//                       <div className="form-check form-switch">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           id="isactive"
//                           checked={isactive}
//                           name="isactive"
//                           onChange={(e) => {
//                             setFormData({ ...formData, isactive: !isactive });
//                           }}
//                         />
//                         <label className="form-check-label">
//                           Active status
//                         </label>
//                       </div>
//                     </div>

//                     <div className="mb-3">
//                       <label className="label mb-2">Driver is:</label>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="radio"
//                           name="flexRadioDefault"
//                           id="isfulltime"
//                           value="fulltime"
//                           checked={isfulltime}
//                           onChange={(e) => {
//                             setFormData({ ...formData, isfulltime: true });
//                           }}
//                         />
//                         <label className="form-check-label">Full time</label>
//                       </div>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="radio"
//                           name="flexRadioDefault"
//                           id="parttime"
//                           value="parttime"
//                           checked={!isfulltime}
//                           onChange={(e) => {
//                             setFormData({ ...formData, isfulltime: false });
//                           }}
//                         />
//                         <label className="form-check-label">Part time</label>
//                       </div>
//                     </div>

//                     <hr />
//                     <div className="mb-3">
//                       <div className="col-sm-offset-2 col-sm-10">
//                         <button type="submit" className="btn btn-secondary">
//                           <i
//                             className="fa fa-plus-circle fa-fw"
//                             aria-hidden="true"
//                           ></i>{" "}
//                           Update Driver
//                         </button>{" "}
//                         <Link
//                           type="submit"
//                           className="btn btn-warning"
//                           to="/admin/driverlist"
//                         >
//                           <i
//                             className="fa fa-arrow-left fa-fw"
//                             aria-hidden="true"
//                           ></i>{" "}
//                           Back To List
//                         </Link>
//                       </div>
//                     </div>
//                     <hr />
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </Fragment>
//         )}

//         <Alert />
//       </main>
//     </Layout>
//   );
// };

// export default withRouter(UpdateDriver);
