import customAxios from "../utils/customAxios";

/**
 * * Call the Navtime API, which is used to determine the appropriate route, as well as the time taken and associated tolls for the core booking logic.
 * * For more details, please refer to the Navitime documentation (https://rapidapi.com/navitimejapan-navitimejapan/api/navitime-route-car/ OR https://api-sdk.navitime.co.jp/api/)
 * This app uses the "Route (car) endpoint".
 * TLF has purchased the API through the RapidAPI Marketplace (not directly through Navitime) — this was due to the cost at the time, as well as the ease of
 * purchasing it online. Feel free to change this if needed (the cost is now similar; and you will only have to make minor changes to use the version 
 * directly provided by Navitime — one note though, there are minor differences in available functionality depending on whether
 * the API was purchased from RapidAPI or Navitime directly, but they didn't apply to our use-case last we checked).
 * @param garage - Vehicle dispatch location (typically the TLF Garage). This location MUST be inserted into the route as the start point.
 * @param via - pick-up and dropoff location(s) provided as an array. In other words, the API expects to know via which locations
 * you want the route to go. The Navitime documentation has more information.
 * @param start_time - The travel date/ trip start time — the traffic conditions and the route suggested etc. will vary based on this.
 */
/** The Rapid API link provided in the previous comment includes an "interactive playground" if you sign-in to it (create a free account or use the TLF one — Afra has the credentials).
 * The playground allows you to make test requests and understand how the endpoint behaves.
 */
/** Note: You will see a "Google Autocomplete" component in the client/src/components/layout-new-theme/form-card/AirportTransferForm.js,
 * client/src/components/layout-new-theme/form-card/MultipleDestinationForm.js and client/src/components/layout-new-theme/form-card/SingleDestinationForm.js
 * The form controllers for the pick-up and drop-off addresses use the Google Maps API to 
 * autocomplete the user's query > The data is then passed to the Navitime API for the rest.
 * 
 */
export const getRouteCar = async (garage, via, start_time) => {
  try {
    const res = await customAxios.get(
      `/api/admin/navitime/routecar/${garage}/${via}/${start_time}`
    );
    return res.data.items;
  } catch (err) {
    return [err];
  }
};

export const getRouteCarPackage = async (start, via, end, start_time, useVia) => {
  try {
    const res = await customAxios.get(
      `/api/admin/navitime/routecarpackage/${start}/${via}/${end}/${start_time}/${useVia}`
    );
    return res.data.items;
  } catch (err) {
    return [err];
  }
};

export const getRouteCarForTotalTime = async (start, end) => {
  try {
    const res = await customAxios.get(
      `/api/admin/navitime/routecar-estimated-bth/${start}/${end}`
    );
    return res.data.items;
  } catch (err) {
    return [err];
  }
};

export const checkDistance = async (garage, goal) => {
  try {
    const res = await customAxios.get(
      `/api/admin/navitime/check-distance/${garage}/${goal}`
    );
    return res.data;
  } catch (err) {
    return [err];
  }
};


