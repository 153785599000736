import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import {
  addVehicleType,
  getVehicleType,
} from "../../../actions/admin/vehicletype";
import Alert from "../../layout/Alert";
import { vehicleTypeImageOptions, iconTypes } from "../../../utils/dataLists";
import { useDispatch, useSelector } from "react-redux";

const UpdateVehicleType = ({ match }) => {
  const dispatch = useDispatch();
  const vehicletypeState = useSelector((state) => state.vehicletype);
  const { loading, vehicletype } = vehicletypeState;
  const [selectedImageOption, setSelectedimageOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [selectedOption7, setSelectedOption7] = useState(null);
  const [selectedOption8, setSelectedOption8] = useState(null);
  const [formData, setFormData] = useState({
    typename: "",
    vehicletypeimage: vehicleTypeImageOptions[0].label,
    hourlyprice: 0,
    hourlypriceOutsideTokyo: 0,
    description: "",
    maxpassenger: 0,
    maxluggages: 0,
    baseprice: 0,
    basepricehours: 0,
    basepriceOutsideTokyo: 0,
    basepricehoursOutsideTokyo: 0,
    subtitle: "",
    option1Icon: "",
    option2Icon: "",
    option3Icon: "",
    option4Icon: "",
    option5Icon: "",
    option6Icon: "",
    option7Icon: "",
    option8Icon: "",
    optionText1: "",
    optionText2: "",
    optionText3: "",
    optionText4: "",
    optionText5: "",
    optionText6: "",
    optionText7: "",
    optionText8: "",
  });

  const {
    typename,
    hourlyprice,
    hourlypriceOutsideTokyo,
    description,
    maxluggages,
    maxpassenger,
    baseprice,
    basepricehours,
    basepriceOutsideTokyo,
    basepricehoursOutsideTokyo,
    subtitle,
    optionText1,
    optionText2,
    optionText3,
    optionText4,
    optionText5,
    optionText6,
    optionText7,
    optionText8,
  } = formData;

  useEffect(() => {
    dispatch(getVehicleType(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    const opt = vehicleTypeImageOptions.find(
      (op) => op.label === vehicletype?.vehicletypeimage
    );
    setFormData({
      _id: loading || !vehicletype?._id ? "" : vehicletype?._id,
      typename: loading || !vehicletype?.typename ? "" : vehicletype?.typename,
      vehicletypeimage:
        loading || !vehicletype?.vehicletypeimage
          ? vehicleTypeImageOptions[1].label
          : opt?.label,
      hourlyprice:
        loading || !vehicletype?.hourlyprice ? 0 : vehicletype?.hourlyprice,
      hourlypriceOutsideTokyo:
        loading || !vehicletype?.hourlypriceOutsideTokyo ? 0 : vehicletype?.hourlypriceOutsideTokyo,
      baseprice:
        loading || !vehicletype?.baseprice ? 0 : vehicletype?.baseprice,
      basepricehours:
        loading || !vehicletype?.basepricehours ? 0 : vehicletype?.basepricehours,
      basepriceOutsideTokyo:
        loading || !vehicletype?.basepriceOutsideTokyo ? 0 : vehicletype?.basepriceOutsideTokyo,
      basepricehoursOutsideTokyo:
        loading || !vehicletype?.basepricehoursOutsideTokyo ? 0 : vehicletype?.basepricehoursOutsideTokyo,
      description:
        loading || !vehicletype?.description ? "" : vehicletype?.description,
      maxpassenger:
        loading || !vehicletype?.maxpassenger ? 0 : vehicletype?.maxpassenger,
      maxluggages:
        loading || !vehicletype?.maxluggages ? 0 : vehicletype?.maxluggages,

      subtitle: loading || !vehicletype?.subtitle ? "" : vehicletype?.subtitle,
      optionText1:
        loading || !vehicletype?.optionText1 ? "" : vehicletype?.optionText1,
      optionText2:
        loading || !vehicletype?.optionText2 ? "" : vehicletype?.optionText2,
      optionText3:
        loading || !vehicletype?.optionText3 ? "" : vehicletype?.optionText3,
      optionText4:
        loading || !vehicletype?.optionText4 ? "" : vehicletype?.optionText4,
      optionText5:
        loading || !vehicletype?.optionText5 ? "" : vehicletype?.optionText5,
      optionText6:
        loading || !vehicletype?.optionText6 ? "" : vehicletype?.optionText6,
      optionText7:
        loading || !vehicletype?.optionText7 ? "" : vehicletype?.optionText7,
      optionText8:
        loading || !vehicletype?.optionText8 ? "" : vehicletype?.optionText8,

      option1Icon:
        loading || !vehicletype?.option1Icon
          ? iconTypes[0].label
          : vehicletype?.option1Icon,

      option2Icon:
        loading || !vehicletype?.option2Icon
          ? iconTypes[0].label
          : vehicletype?.option2Icon,
      option3Icon:
        loading || !vehicletype?.option3Icon
          ? iconTypes[0].label
          : vehicletype?.option3Icon,
      option4Icon:
        loading || !vehicletype?.option4Icon
          ? iconTypes[0].label
          : vehicletype?.option4Icon,
      option5Icon:
        loading || !vehicletype?.option5Icon
          ? iconTypes[0].label
          : vehicletype?.option5Icon,
      option6Icon:
        loading || !vehicletype?.option6Icon
          ? iconTypes[0].label
          : vehicletype?.option6Icon,
      option7Icon:
        loading || !vehicletype?.option7Icon
          ? iconTypes[0].label
          : vehicletype?.option7Icon,
      option8Icon:
        loading || !vehicletype?.option8Icon
          ? iconTypes[0].label
          : vehicletype?.option8Icon,
    });
    setSelectedimageOption(opt);

    const iconOpt1 = iconTypes.find(
      (op) => op.label === vehicletype?.option1Icon
    );
    setSelectedOption1(iconOpt1);

    const iconOpt2 = iconTypes.find(
      (op) => op.label === vehicletype?.option2Icon
    );
    setSelectedOption2(iconOpt2);

    const iconOpt3 = iconTypes.find(
      (op) => op.label === vehicletype?.option3Icon
    );
    setSelectedOption3(iconOpt3);

    const iconOpt4 = iconTypes.find(
      (op) => op.label === vehicletype?.option4Icon
    );
    setSelectedOption4(iconOpt4);

    const iconOpt5 = iconTypes.find(
      (op) => op.label === vehicletype?.option5Icon
    );
    setSelectedOption5(iconOpt5);

    const iconOpt6 = iconTypes.find(
      (op) => op.label === vehicletype?.option6Icon
    );
    setSelectedOption6(iconOpt6);

    const iconOpt7 = iconTypes.find(
      (op) => op.label === vehicletype?.option7Icon
    );
    setSelectedOption7(iconOpt7);

    const iconOpt8 = iconTypes.find(
      (op) => op.label === vehicletype?.option8Icon
    );
    setSelectedOption8(iconOpt8);
  }, [loading, vehicletype]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFormSubmit = (e) => {
    dispatch(addVehicleType(formData, null, true));
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Update Vehicle Type" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <div className="col-sm-offset-2 col-sm-10">
                  <Link
                    type="submit"
                    className="btn btn-secondary"
                    to="/admin/vehicletypelist"
                  >
                    <i
                      className="fa fa-arrow-left fa-fw"
                      aria-hidden="true"
                    ></i>{" "}
                    Back To List
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/* <form onSubmit={(e) => onFormSubmit(e)}> */}
              <div className="card mb-3">
                <div className="card-header">Vehicle details</div>
                <div className="card-body">

                  <div className="mb-3">
                    <label className="label mb-2">Type Name:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        readOnly={false}
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Vehicle Type"
                        name="typename"
                        value={typename}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">30 Minutes Rate:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hourly Price"
                        name="hourlyprice"
                        value={hourlyprice}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Base Price:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hourly Price"
                        name="baseprice"
                        value={baseprice}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Base Price Hours:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hour for Base Price"
                        name="basepricehours"
                        value={basepricehours}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">30 Minutes Rate Outside Tokyo:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hourly Price Outside Tokyo"
                        name="hourlypriceOutsideTokyo"
                        value={hourlypriceOutsideTokyo}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Base Price Outside Tokyo:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hourly Price Outside Tokyo"
                        name="basepriceOutsideTokyo"
                        value={basepriceOutsideTokyo}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Base Price Hours Outside Tokyo:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hour for Base Price Outside Tokyo"
                        name="basepricehoursOutsideTokyo"
                        value={basepricehoursOutsideTokyo}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Image Name:</label>
                    <div className="col-sm-12">
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select pick-up location"
                        autoComplete="off"
                        value={selectedImageOption}
                        // defaultValue={vehicleTypeImageOptions[0]}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            vehicletypeimage: e.label,
                          });
                          setSelectedimageOption(e);
                        }}
                        options={vehicleTypeImageOptions}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Description:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control form-control-admin"
                        id="reg"
                        placeholder="Enter Vehicle Type Description"
                        name="description"
                        value={description}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Maximum Passengers:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Passangers"
                        name="maxpassenger"
                        value={maxpassenger}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Maximum Luggages:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Luggages"
                        name="maxluggages"
                        value={maxluggages}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                </div>
              </div>

              <div className="mb-3">
                <div className="col-sm-offset-2 col-sm-10">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => onFormSubmit(e)}
                  >
                    <i
                      className="fa fa-plus-circle fa-fw"
                      aria-hidden="true"
                    ></i>{" "}
                    Update
                  </button>{" "}
                </div>
              </div>
              {/* </form> */}
            </div>
            <div className="col-md-6">

              <div className="card mb-3">
                <div className="card-header">Vehicle description</div>
                <div className="card-body">

                  <div className="mb-4">
                    <label className="label mb-2">Sub-Title:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        readOnly={false}
                        className="form-control form-control-admin"
                        id="sub"
                        placeholder="Enter Subtitle"
                        name="subtitle"
                        value={subtitle}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="row mb-3">
                      <div className="mb-2">Vehicle amenities:</div>
                      <div className="mb-2">※ Example of how to enter English and Japanese text copy for the vehicle amenities: 9 suitcases / スーツケース9個</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 1:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption1}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option1Icon: e.label,
                            });
                            setSelectedOption1(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="optionText1"
                          placeholder="Enter Option 1 text"
                          name="optionText1"
                          value={optionText1}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 2:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption2}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option2Icon: e.label,
                            });
                            setSelectedOption2(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option2"
                          placeholder="Enter Option 2 text"
                          name="optionText2"
                          value={optionText2}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 3:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption3}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option3Icon: e.label,
                            });
                            setSelectedOption3(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option3"
                          placeholder="Enter Option 3 text"
                          name="optionText3"
                          value={optionText3}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 4:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption4}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option4Icon: e.label,
                            });
                            setSelectedOption4(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option4"
                          placeholder="Enter Option 4 text"
                          name="optionText4"
                          value={optionText4}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 5:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption5}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option5Icon: e.label,
                            });
                            setSelectedOption5(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option5"
                          placeholder="Enter Option 5 text"
                          name="optionText5"
                          value={optionText5}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 6:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption6}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option6Icon: e.label,
                            });
                            setSelectedOption6(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option6"
                          placeholder="Enter Option 6 text"
                          name="optionText6"
                          value={optionText6}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 7:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption7}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option7Icon: e.label,
                            });
                            setSelectedOption7(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option7"
                          placeholder="Enter Option 7 text"
                          name="optionText7"
                          value={optionText7}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="label mb-2">Option 8:</label>
                      </div>

                      <div className="col">
                        <Select
                          autoComplete="off"
                          value={selectedOption8}
                          // defaultValue={vehicleTypeImageOptions[0]}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              option8Icon: e.label,
                            });
                            setSelectedOption8(e);
                          }}
                          options={iconTypes}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          readOnly={false}
                          className="form-control form-control-admin"
                          id="option1"
                          placeholder="Enter Option 8 text"
                          name="optionText8"
                          value={optionText8}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Alert />
      </main>
    </Layout >
  );
};

export default withRouter(UpdateVehicleType);
