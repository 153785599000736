import {
  ADD_VEHICLE,
  VEHICLE_ERROR,
  GET_VEHICLES,
  GET_VEHICLE,
} from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

// get vehicle by id
export const getVehicle = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/vehicles/${id}`);
    dispatch({
      type: GET_VEHICLE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VEHICLE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// get vehicles
export const getVehicles = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/vehicles");
    dispatch({
      type: GET_VEHICLES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VEHICLE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// get all active vehicles
export const getAllActiveVehicles = async () => {
  try {
    const res = await customAxios.get("/api/admin/vehicles/getallactive");
    return res.data;
  } catch (err) {
    return [];
  }
};

// add driver
export const addVehicle =
  (formdata, history, edit = false) =>
  async (dispatch) => {
    try {
      console.log("addDriver action");
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await customAxios.post(
        "/api/admin/vehicles",
        formdata,
        config
      );
      dispatch({
        type: ADD_VEHICLE,
        payload: res.data,
      });
      dispatch(
        setAlert(edit ? "Vehicle Updated" : "Vehicle created", "success")
      );
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        dispatch(
          setAlert(
            "Registration number alrady exist. " +
              errors.codeName +
              " " +
              JSON.stringify(errors.keyValue),
            "danger"
          )
        );
      }
      dispatch({
        type: VEHICLE_ERROR,
        payload: { msg: err.response, status: err.response },
      });
    }
  };
