import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import TitleBar from "../../layout/TitleBar";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Layout from "../../layout/Layout";
import "./bookingStyles.css";
import {
  baggageOptions,
  paxOptions,
  bookingStatusOptions,
} from "../../../utils/dataLists";
import _ from "lodash";
import { setAlert } from "../../../actions/alert";
import Alert from "../../layout/Alert";
import Setting from "../../../utils/settings.json";
import {
  addBooking,
  getAvailableVehicleTypes,
  getAvailableVehicles,
} from "../../../actions/admin/booking";
import {
  getAutocompleteAddresses,
  getLocationByPlaceid,
} from "../../../actions/googleAPI";
import { getRouteCar } from "../../../actions/navitimeAPI";
import AsyncSelect from "react-select/async";
import moment from "moment";
// import moment from "../../../utils/customMoment";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import VehicleTypeList from "./VehicleTypeList";
import {
  getTotal,
  getKey,
  checkTokyoAvailability,
  setSpecialPackage,
  // getHoursInsideMultiplierZone,
} from "../../../utils/Calculator";
import { getSetting } from "../../../actions/admin/settings";
// import { getAllPackages } from "../../../actions/admin/package";

const CreateBooking = () => {
  const settings = useSelector((state) => state.setting);
  const { setting, loading } = settings;

  useEffect(() => {
    dispatch(getSetting());
  }, []);

  // customer
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  // booking details
  const [travelDate, setTravelDate] = useState("");
  const [newtravelDate, setNewTravelDate] = useState(null);
  // const [travelDateWithPickupTime, setTravelDateWithPickupTime] =
  useState(null);

  const [pickuptime, setPickuptime] = useState("");
  const [totalDistance, setTotalDistance] = useState(0);

  const [cutomerChangedDropOffTimeOnly, setcutomerChangedDropOffTimeOnly] =
    useState("");
  const [cutomerActualDropOffTime, setcutomerActualDropOffTime] =
    useState(null);
  const [tripStartTime, setTripStartTime] = useState(null);
  const [
    cutomerDropOffTimeWithAdditinalTime,
    setCutomerDropOffTimeWithAdditinalTime,
  ] = useState(null);
  const [
    garageArivalTimeWithAdditionalTime,
    setGarageArivalTimeWithAdditionalTime,
  ] = useState(null);

  const [garageArivalTime, setgarageArivalTime] = useState("");
  const [estimate, setEstimate] = useState("");
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);

  const [inputValue, setInputValue] = useState("");

  const [pickuplocation, setPickuplocation] = useState(null);
  const [destinations, setDestinations] = useState(null);
  // PAX states
  const [paxtype, setPaxtype] = useState(null);
  const [paxcount, setPaxCount] = useState(1);
  const [paxList, setPaxList] = useState([]);
  // const [noteList, setNoteList] = useState([]);
  // luggage states
  const [luggagetype, setLuggageType] = useState(null);
  const [luggagecount, setLuggageCount] = useState(0);
  const [luggagelist, setLuggageList] = useState([]);
  // valdations
  const [traveldateVal, setTravelDateVal] = useState("");
  const [pickupvalidation, setPickupvalidation] = useState("");
  const [dropoffVal, setDropoffVal] = useState("");
  const [pickupLocationVal, setpickupLocationVal] = useState("#ced4da");
  const [destinationsVal, setDestinationsVal] = useState("#ced4da");
  const [paxtypeVal, setPaxtypeVal] = useState("#ced4da");
  const [paxcountVal, setPaxcountVal] = useState("");
  const [nameval, setNameval] = useState("");
  const [emailval, setEmailval] = useState("");
  const [phoneval, setPhoneval] = useState("");
  const [addressval, setAddressval] = useState("");
  const [bookingStatusval, setBookingStatusval] = useState("#ced4da");

  const [vehicleTypeNames, setVehicleTypeNames] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [clickedVehicleType, setClickedVehicleType] = useState("");
  const [infoSt, setInfoSt] = useState("");
  const [activatedPackages, setActivatedPackages] = useState([]);

  // const [warnings, setWarnings] = useState(null);

  const [tollFare, setTollFare] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const [bookingStatus, setBookingStatus] = useState(null);

  const [bookingAmountInfo, setBookingAmountInfo] = useState({
    totalTime: 0,
    baseprice: 0,
    hourlyprice: 0,
    tollFare: 0,
    isTimeRange: null,
    total: 0,
    vehicleTypeName: null,
    vehicleTypeID: null,
    tripStartTime: null,
    garageArivalTime: null,
    garageArivalTimeWithAdditionalTime: null,
    cutomerActualDropOffTime: null,
    cutomerDropOffTimeWithAdditinalTime: null,
    zoneHours: 0,
    totalhours: 0,
  });
  const [packageAdditionaltime, setPackageAdditionaltime] = useState(0);

  const dispatch = useDispatch();

  // const navtimeState = useSelector((state) => state.navtime);

  const resetform = () => {
    setActivatedPackages([]);
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setTravelDate("");
    // setTravelDateWithPickupTime(null);
    setgarageArivalTime(null);
    setPickuptime("");
    setcutomerChangedDropOffTimeOnly("");
    setcutomerActualDropOffTime(null);
    setCutomerDropOffTimeWithAdditinalTime(null);
    setGarageArivalTimeWithAdditionalTime(null);
    setEstimate("");
    setPickuplocation(null);
    setDestinations(null);
    setPaxCount(1);
    setPaxList([]);
    setLuggageCount(0);
    setLuggageList([]);
    setVehicleTypeNames([]);
    setClickedVehicleType("");
    setBookingAmountInfo({
      totalTime: 0,
      baseprice: 0,
      hourlyprice: 0,
      tollFare: 0,
      isTimeRange: null,
      total: 0,
      vehicleTypeName: null,
      vehicleTypeID: null,
      tripStartTime: null,
      garageArivalTime: null,
      garageArivalTimeWithAdditionalTime: null,
      cutomerActualDropOffTime: null,
      cutomerDropOffTimeWithAdditinalTime: null,
      zoneHours: 0,
      totalhours: 0,
    });
    setTollFare(0);
    setTotalTime(0);
    setInfoSt("");
    setBookingStatus(null);
    setTotalDistance(0);
    setNote("");
  };

  const formValidation = () => {
    if (!travelDate) setTravelDateVal("is-invalid");
    if (!pickuptime) setPickupvalidation("is-invalid");
    // if (!cutomerChangedDropOffTimeOnly) setDropoffVal("is-invalid");
    if (!pickuplocation) setpickupLocationVal("#dc3545");
    if (!destinations || destinations.length === 0)
      setDestinationsVal("#dc3545");
    if (!paxtype) setPaxtypeVal("#dc3545");
    if (paxcount === 0 || !paxcount) setPaxcountVal("is-invalid");
    // if (!name) setNameval("is-invalid");
    // if (!email) setEmailval("is-invalid");
    // if (!phone) setPhoneval("is-invalid");
    // if (!address) setAddressval("is-invalid");
  };

  const bookingConfirmFormValidation = () => {
    formValidation();
    if (!name) setNameval("is-invalid");
    if (!email) setEmailval("is-invalid");
    if (!phone) setPhoneval("is-invalid");
    if (!address) setAddressval("is-invalid");
    if (!cutomerChangedDropOffTimeOnly) setDropoffVal("is-invalid");
    if (!bookingStatus) setBookingStatusval("#dc3545");
  };

  const updatePAXListItem = (ptype, whichvalue, newvalue) => {
    var index = paxList.findIndex((x) => x.paxtype === ptype);
    let g = paxList[index];
    g[whichvalue] = newvalue;
    if (index === -1) {
      console.log("no match");
    } else
      setPaxList([...paxList.slice(0, index), g, ...paxList.slice(index + 1)]);
  };

  const updateLuggageListItem = (ltype, whichvalue, newvalue) => {
    var luIndex = luggagelist.findIndex((x) => x.luggagetype === ltype);
    let h = luggagelist[luIndex];
    h[whichvalue] = newvalue;
    if (luIndex === -1) {
      console.log("no match");
    } else
      setLuggageList([
        ...luggagelist.slice(0, luIndex),
        h,
        ...luggagelist.slice(luIndex + 1),
      ]);
  };

  const addLuggage = (e) => {
    if (!luggagetype) return;
    const exists = luggagelist.some((v) => v.luggagetype === luggagetype.label);
    console.log(exists);
    if (!exists) {
      setLuggageList([
        ...luggagelist,
        { luggagetype: luggagetype.label, luggagecount },
      ]);
    } else {
      const lugFind = _.find(luggagelist, ["luggagetype", luggagetype.label]);
      const newLugCount = lugFind.luggagecount + luggagecount;
      updateLuggageListItem(luggagetype.label, "luggagecount", newLugCount);
    }
  };

  const addPax = (e) => {
    if (!paxtype) return;
    const exists = paxList.some((v) => v.paxtype === paxtype.label);
    if (!exists) {
      setPaxList([...paxList, { paxtype: paxtype.label, paxcount }]);
    } else {
      const paxFind = _.find(paxList, ["paxtype", paxtype.label]);
      const newPaxCount = paxFind.paxcount + paxcount;
      updatePAXListItem(paxtype.label, "paxcount", newPaxCount);
    }
  };

  function sum(prev, next) {
    return prev + next;
  }

  const checkAvailability = async (e) => {
    e.preventDefault();
    setIsloading(true);

    var totalPax = 0;
    var totallug = 0;
    if (paxList.length > 0) {
      totalPax = paxList.map((p) => p.paxcount).reduce(sum);
    }
    if (luggagelist.length > 0) {
      totallug = luggagelist.map((p) => p.luggagecount).reduce(sum);
    }
    formValidation();
    if (
      !travelDate ||
      !pickuptime ||
      !pickuplocation ||
      !destinations ||
      destinations.length === 0 ||
      !paxtype ||
      paxcount === 0 ||
      !paxcount
    ) {
      dispatch(
        setAlert(
          "Please fill in all the required fields (highlighted in red).",
          "danger"
        )
      );
      setIsloading(false);
    } else {
      setVehicleTypeNames([]);
      setInfoSt("");
      setgarageArivalTime(null);
      var hasTokyo = checkTokyoAvailability(pickuplocation, destinations);

      // // Check booking is violating buffer time
      // const buffer_time = Setting.BUFFER_TIME;
      // pickuptime = input value
      console.log(pickuptime);
      var p_arr = pickuptime.split(":");
      var p_h = Number(p_arr[0]);
      var p_m = Number(p_arr[1]);

      // const travel_date_with_pickup_time = moment(travelDate).set({
      //   hour: p_h,
      //   minute: p_m,
      // });
      const travel_date_with_pickup_time = moment(newtravelDate).set({
        hour: p_h,
        minute: p_m,
      });
      // // tell moment how to parse the input string
      // var momentObj = moment(travelDate + +" " + pickuptime, "YYYY-MM-DDE");

      // // conversion
      // var dateTime = momentObj.format("YYYY-MM-DDTHH:mm:s");
      // console.log("dateTime");
      // console.log(dateTime);

      // Check booking is violating buffer time
      // const buffer_time = Setting.BUFFER_TIME;
      const buffer_time = setting.buffertime;
      const date_with_buffer_time = moment().add(buffer_time, "hours");
      var isafter = travel_date_with_pickup_time.isAfter(date_with_buffer_time);
      if (hasTokyo === false) {
        dispatch(
          setAlert(
            "Your pick-up or drop off point must be within Tokyo.",
            "warning"
          )
        );
      } else if (isafter === false) {
        dispatch(
          setAlert("Your pick-up time should be 24 hours from now. ", "warning")
        );
      } else {
        const via = getViaList();

        const travel_date_with_format = travel_date_with_pickup_time.format(
          "YYYY-MM-DDThh:mm:ss"
        );
        const car_r = await getRouteCar(
          "35.6235029,139.7410879",
          via,
          travel_date_with_format
        );

        if (car_r[0].response != null) {
          const status = car_r[0].response.status;
          const statusTest = car_r[0].response.statusText;
          const erMsg =
            "Code :" +
            status +
            " | Message : " +
            statusTest +
            " | Navtime request limit exceeded.";
          dispatch(setAlert(erMsg, "warning"));
          setIsloading(false);
          return;
        }

        if (car_r.length > 0) {
          const summary_arr = car_r[car_r.length - 1].summary;
          const sections_arr = car_r[car_r.length - 1].sections;
          const garage_to_pickup_time = sections_arr[1].time;

          var trip_start_time = travel_date_with_pickup_time.subtract(
            garage_to_pickup_time,
            "minutes"
          );
          setTripStartTime(trip_start_time._d);
          const distance = summary_arr.move.distance;
          setTotalDistance(distance);

          if (summary_arr.move.fare != null) {
            var fare = summary_arr.move.fare.unit_1025_2
              ? summary_arr.move.fare.unit_1025_2
              : 0;
          } else {
            fare = 0;
          }
          setTollFare(fare);
          const dop_time = summary_arr.move.to_time;
          const toll_road_distance = summary_arr.move.toll_road_distance;
          // const sections_count = summary_arr.sections.length;
          const total_time = summary_arr.move.time;
          setTotalTime(total_time);

          // total time must be less tnah 16 hors
          if (Number(total_time / 60) > Number(setting.totaltriptime)) {
            dispatch(
              setAlert(
                "For bookings longer than " +
                  setting.totaltriptime +
                  " hours, please contact our sales team.",
                "warning"
              )
            );
            setIsloading(false);
            return;
          }
          var newtime = travel_date_with_pickup_time;
          var estimated_final_dropoff_time = moment(newtime).add(
            total_time,
            "minutes"
          );

          // ---------------------------------- drop off

          const section = car_r[car_r.length - 1].sections;
          const section_length = section.length;
          // const drop_off_section = section[section_length - 4];
          const final_drop_off_section = section[section_length - 2];
          const final_Drop_to_garrage_time_in_minutes = Number(
            final_drop_off_section.time
          );
          var estimated_customer_final_dropoff_time = moment(newtime).add(
            total_time - final_Drop_to_garrage_time_in_minutes,
            "minutes"
          );
          setcutomerChangedDropOffTimeOnly(
            estimated_customer_final_dropoff_time.format("HH:mm:ss")
          );

          // ------------------------------------drop off end

          // ============================================start

          // var travel_date_with_pickup_time = moment(travelDate).set({
          //   hour: p_h,
          //   minute: p_m,
          // });

          // var d_arr = cutomerChangedDropOffTimeOnly.split(":");
          const ecfdt =
            estimated_customer_final_dropoff_time.format("HH:mm:ss");
          var d_arr = ecfdt.split(":");
          var d_h = Number(d_arr[0]);
          var d_m = Number(d_arr[1]);

          // get totsl minutrd to add frop off dattime
          var h_diff = d_h - p_h;
          var m_diff = d_m - p_m;

          if (h_diff >= 0) {
            // var travel_date_with_dropoff_time = moment(travelDate).set({
            //   hour: d_h,
            //   minute: d_m,
            // });
            var travel_date_with_dropoff_time = moment(newtravelDate).set({
              hour: d_h,
              minute: d_m,
            });
          } else {
            // travel_date_with_dropoff_time = moment(travelDate)
            //   .add(1, "days")
            //   .set({
            //     hour: d_h,
            //     minute: d_m,
            //   });
            travel_date_with_dropoff_time = moment(newtravelDate)
              .add(1, "days")
              .set({
                hour: d_h,
                minute: d_m,
              });
          }
          const tdwdt = travel_date_with_dropoff_time._d.toString();
          setcutomerActualDropOffTime(tdwdt);
          // initiak cusermer chage value set to estimated value
          setCutomerDropOffTimeWithAdditinalTime(tdwdt);
          const gatime = estimated_final_dropoff_time._d.toString();
          setGarageArivalTimeWithAdditionalTime(gatime);
          // =============================================end
          setgarageArivalTime(gatime);
          setInfoSt(
            "Toll fare : " +
              fare +
              " JPY || Toll road distance : " +
              toll_road_distance / 1000 +
              " KM" +
              " | Dispatch from garage : " +
              trip_start_time.format()
          );
        }

        if (destinations.length === 1) {
          var activated_pkg = await setSpecialPackage(
            pickuplocation,
            destinations
          );
          setActivatedPackages(activated_pkg);
        }
        const v_types_arr = await getAvailableVehicleTypes(
          travel_date_with_pickup_time._d.toString(),
          estimated_final_dropoff_time._d.toString(),
          totalPax,
          totallug
        );
        setVehicleTypeNames(v_types_arr);
      }
    }
    setIsloading(false);
  };

  // const setSpecialPackage = async (pickup_location, destinations) => {
  //   const pkg_list = await getAllPackages();
  //   // check airport package in pickip location
  //   var activated_pkg = [];
  //   var pickup_location_has_airport = false;
  //   var destination_has_airport = false;
  //   for (var i = 0; i < pkg_list.length; i++) {
  //     const airport_tags = pkg_list[i].airporttags.trim().split(",");
  //     airport_tags.map((at) => {
  //       if (
  //         pickup_location.label
  //           .trim()
  //           .toLowerCase()
  //           .includes(at.trim().toLowerCase())
  //       ) {
  //         pickup_location_has_airport = true;
  //         var locations_tags_list = pkg_list[i].locationstags.trim().split(",");
  //         if (
  //           locations_tags_list.some((item) =>
  //             destinations[0].label
  //               .trim()
  //               .toLowerCase()
  //               .includes(item.trim().toLowerCase())
  //           )
  //         ) {
  //           // has destination. package activated.
  //           activated_pkg.push(pkg_list[i]);
  //         }
  //       }
  //     });
  //   }
  //   // // if not pickp point has any airport then check it in destination point
  //   if (!pickup_location_has_airport) {
  //     for (var j = 0; j < pkg_list.length; j++) {
  //       // console.log(pkg_list[j]);
  //       const airport_tags = pkg_list[j].airporttags.trim().split(",");
  //       airport_tags.map((at) => {
  //         if (
  //           destinations[0].label
  //             .trim()
  //             .toLowerCase()
  //             .includes(at.trim().toLowerCase())
  //         ) {
  //           destination_has_airport = true;
  //           // search matcing destination
  //           var airport_tags_list = pkg_list[j].locationstags.trim().split(",");
  //           if (
  //             airport_tags_list.some((item) =>
  //               pickup_location.label
  //                 .trim()
  //                 .toLowerCase()
  //                 .includes(item.trim().toLowerCase())
  //             )
  //           ) {
  //             // has destination. package activated.
  //             activated_pkg.push(pkg_list[j]);
  //           }
  //         }
  //       });
  //     }
  //   }
  //   setActivatedPackages(activated_pkg);
  // };

  const changeCustomerDropOffValue = (val) => {
    var d_time = moment(cutomerActualDropOffTime).format("HH:mm:ss");
    var d_arr = d_time.split(":");
    var et_h = Number(d_arr[0]);
    var et_m = Number(d_arr[1]);

    // customer added time split
    var c_added_arr = val.split(":");
    var c_d_h = Number(c_added_arr[0]);
    var c_d_m = Number(c_added_arr[1]);

    // const customer_additionaly_time = moment.duration(
    //   moment(cutomerDropOffTimeWithAdditinalTime).diff(
    //     moment(cutomerActualDropOffTime)
    //   )
    // );
    // var customer_additionally_added_minutes =
    //   customer_additionaly_time.asMinutes();
    // console.log("customer_additionally_added_minutes");
    // console.log(customer_additionally_added_minutes);

    // const garage_arival_time_with_additinal = moment(garageArivalTime).add(
    //   customer_additionally_added_minutes,
    //   "minutes"
    // );
    // console.log(
    //   "((((((((((((((((garage_arival_time_with_additinal)))))))))))))))))))"
    // );
    // console.log(garage_arival_time_with_additinal._d);
    // setGarageArivalTimeWithAdditionalTime(garage_arival_time_with_additinal);

    if (et_h <= c_d_h) {
      var customer_changed_dropoff_datetime = moment(
        cutomerActualDropOffTime
      ).set({
        hour: c_d_h,
        minute: c_d_m,
      });
    } else {
      customer_changed_dropoff_datetime = moment(cutomerActualDropOffTime)
        .add(1, "days")
        .set({
          hour: c_d_h,
          minute: c_d_m,
        });
    }

    const customer_additionaly_time = moment.duration(
      moment(customer_changed_dropoff_datetime).diff(
        moment(cutomerActualDropOffTime)
      )
    );
    var customer_additionally_added_minutes =
      customer_additionaly_time.asMinutes();

    const garage_arival_time_with_additinal = moment(garageArivalTime).add(
      customer_additionally_added_minutes,
      "minutes"
    );
    setGarageArivalTimeWithAdditionalTime(garage_arival_time_with_additinal._d);
    const cutomerActualDropOffTime_datetime = moment(cutomerActualDropOffTime);
    var duration = moment.duration(
      customer_changed_dropoff_datetime.diff(cutomerActualDropOffTime_datetime)
    );
    var duration_inminutes = duration.asMinutes();
    var duration_inhours = duration.asHours();
    if (duration_inminutes < 0 || duration_inhours > 16) {
      // user added dropoff time is lesthan actual dropoff time . this must denny
      resetDropoffTimeToEstimatedValue(d_time);
    } else {
      setCutomerDropOffTimeWithAdditinalTime(
        customer_changed_dropoff_datetime._d.toString()
      );
    }
  };

  const resetDropoffTimeToEstimatedValue = (inputValue) => {
    if (typeof inputValue == "undefined" || inputValue === "") {
      return;
    }
    if (window.my_timer1 != null) {
      clearTimeout(window.my_timer1);
    }
    window.my_timer1 = setTimeout(() => {
      window.my_timer1 = null;
      try {
        dispatch(
          setAlert(
            "Dropoff time cannot be less than actual estimated drop off time.",
            "warning"
          )
        );
        setcutomerChangedDropOffTimeOnly(inputValue);
        setGarageArivalTimeWithAdditionalTime(garageArivalTime);
        setCutomerDropOffTimeWithAdditinalTime(cutomerActualDropOffTime);
      } catch (error) {}
    }, 2000);
  };

  const isDateInMultiplierZone = (pd_date) => {
    if (pd_date) {
      const mm_date = moment(pd_date.toString());
      const hour = Number(mm_date.hour());
      var isInZone =
        (0 <= hour && hour < 5) || (22 <= hour && hour < 24) ? true : false;
    }
    return isInZone;
  };

  // p_date_time = client pick datetime
  // d_date_time = cutomerActualDropOffTime or cutomerChangedDropOffTimeOnly
  // return ruondup hours
  const getHoursInsideMultiplierZone = (p_date_time, d_date_time) => {
    if (p_date_time && d_date_time) {
      var zone_minutes = 0;
      // var margin_tem_pm = moment(travelDate).set({
      //   hour: 22,
      //   minute: 0,
      // });
      var margin_tem_pm = moment(newtravelDate).set({
        hour: 22,
        minute: 0,
      });
      var margin_five_am_drop_off = moment(d_date_time).set({
        hour: 6,
        minute: 0,
      });
      const pickup_datetime = moment(p_date_time.toString());
      const dropoff_datetime = moment(d_date_time.toString());
      const is_pick_in_zone = isDateInMultiplierZone(p_date_time);
      const is_dropoff_in_zone = isDateInMultiplierZone(d_date_time);
      if (is_dropoff_in_zone && !is_pick_in_zone) {
        const dropoff_duration = moment.duration(
          dropoff_datetime.diff(margin_tem_pm)
        );
        zone_minutes = dropoff_duration.asMinutes();
      } else if (!is_dropoff_in_zone && is_pick_in_zone) {
        // ger dropoff date
        const pickup_zone_duration = moment.duration(
          margin_five_am_drop_off.diff(pickup_datetime)
        );
        zone_minutes = pickup_zone_duration.asMinutes();
      } else if (is_dropoff_in_zone && is_pick_in_zone) {
        const both_in_same_zone_duration = moment.duration(
          dropoff_datetime.diff(pickup_datetime)
        );
        zone_minutes = both_in_same_zone_duration.asMinutes();
      } else if (!is_dropoff_in_zone && !is_pick_in_zone) {
        // pickup point and dripoff point not in zone
        const is_pic_and_drop_same_day = pickup_datetime.isSame(
          dropoff_datetime,
          "day"
        );
        // 7 * 60 minutes in zone
        zone_minutes = !is_pic_and_drop_same_day ? 420 : 0;
      }
      // console.log(zone_minutes / 60);
      var roundedValInHours = roundThirtyMinValue(zone_minutes);
    }
    return roundedValInHours;
  };

  const getTotalHours = () => {
    // current total hours
    // get additionaly added time
    // add it to current total hours
    const customer_additionaly_time = moment.duration(
      moment(cutomerDropOffTimeWithAdditinalTime).diff(
        moment(cutomerActualDropOffTime)
      )
    );
    var customer_additionally_added_minutes =
      customer_additionaly_time.asMinutes();
    // var total_hours_with_customer_added_time = (
    //   (totalTime + customer_additionally_added_minutes) /
    //   60
    // ).toFixed(2);
    // current code
    // var total_hours_with_customer_added_time = roundThirtyMinValue(
    //   totalTime + customer_additionally_added_minutes
    // );

    // with changes

    var additionalTime =
      ((customer_additionally_added_minutes + totalTime) / 30) * 5;
    console.log("additionalTime>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    console.log(additionalTime);
    console.log(
      "additionalTime<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );

    var total_hours_with_customer_added_time = roundThirtyMinValue(
      totalTime + customer_additionally_added_minutes + additionalTime
    );
    return total_hours_with_customer_added_time;
  };

  // value must have in minutes
  const roundThirtyMinValue = (value) => {
    const inHours = value / 60;
    const onlydecemal = inHours % 1;
    const onlyHour = float2int(inHours);
    var f_val = 0;
    if (onlydecemal > 0 && onlydecemal < 0.5) {
      f_val = onlyHour + 0.5;
    } else if (onlydecemal > 0.5 && onlydecemal < 1) {
      f_val = onlyHour + 1;
    } else if (onlydecemal === 0) {
      f_val = onlyHour;
    } else if (onlydecemal === 0.5) {
      f_val = inHours;
    }
    return f_val;
  };

  const float2int = (value) => {
    return value | 0;
  };

  // on click event - when vehicle type clicked
  // hourlyprice = 30 minute rate
  const applyToTheEquation = (e, vehicle_type) => {
    setSelectedVehicleType(vehicle_type);
    const zoneIncludedHoursRounded = getHoursInsideMultiplierZone(
      tripStartTime,
      garageArivalTimeWithAdditionalTime
    );

    // totHoursComplete = total hours - pickup garage - dropoff garage - customer additionally added dropoff time
    const totHoursComplete = getTotalHours();
    const totalAmount = getTotal(
      vehicle_type,
      totHoursComplete,
      tollFare,
      zoneIncludedHoursRounded,
      activatedPackages,
      packageAdditionaltime
    );
    setClickedVehicleType(vehicle_type.typename);
    setBookingAmountInfo({
      totalTime,
      baseprice: vehicle_type.baseprice,
      hourlyprice: vehicle_type.hourlyprice,
      tollFare,
      isTimeRange: null,
      total: totalAmount.total,
      //new fields
      vehicleTypeName: vehicle_type.typename,
      vehicleTypeID: vehicle_type._id,
      tripStartTime: tripStartTime,
      garageArivalTime: garageArivalTimeWithAdditionalTime,
      garageArivalTimeWithAdditionalTime: garageArivalTimeWithAdditionalTime,
      cutomerActualDropOffTime: cutomerActualDropOffTime,
      cutomerDropOffTimeWithAdditinalTime: cutomerDropOffTimeWithAdditinalTime,
      zoneHours: zoneIncludedHoursRounded,
      totalhours: totHoursComplete,
    });
  };

  const getViaList = () => {
    var via = [];
    var pickip_p_obj = {
      lat: pickuplocation.lat,
      lon: pickuplocation.lon,
    };
    via.push(pickip_p_obj);
    destinations.map((d) => {
      var des_obj = {
        lat: d.lat,
        lon: d.lon,
      };
      via.push(des_obj);
    });
    return JSON.stringify(via);
  };

  const confirmBooking = async (e) => {
    bookingConfirmFormValidation();
    if (
      !cutomerChangedDropOffTimeOnly ||
      !name ||
      !email ||
      !phone ||
      !address ||
      !clickedVehicleType ||
      !bookingStatus
    )
      dispatch(
        setAlert(
          "Please fill in all the required fields (highlighted in red) and select a vehicle type.",
          "danger"
        )
      );
    else {
      setIsloading(true);
      const available_vehicles = await getAvailableVehicles(
        tripStartTime,
        garageArivalTimeWithAdditionalTime,
        clickedVehicleType
      );
      setIsloading(false);

      var p_arr = pickuptime.split(":");
      var p_h = Number(p_arr[0]);
      var p_m = Number(p_arr[1]);

      // const travel_date_with_pickup_time = moment(travelDate).set({
      //   hour: p_h,
      //   minute: p_m,
      // });
      const travel_date_with_pickup_time = moment(newtravelDate).set({
        hour: p_h,
        minute: p_m,
      });

      const note_arr = [{ note: note }];
      // console.log(note_arr);

      if (available_vehicles) {
        const available_vehicle_id = available_vehicles[0]._id;

        dispatch(
          addBooking(
            getKey,
            travel_date_with_pickup_time._d,
            // moment(),
            pickuptime,
            pickuplocation,
            destinations,
            paxList,
            luggagelist,
            cutomerActualDropOffTime,
            garageArivalTime,
            cutomerChangedDropOffTimeOnly,
            name,
            email,
            phone,
            address,
            estimate,
            available_vehicle_id,
            bookingAmountInfo,
            bookingStatus.label,
            totalDistance,
            bookingAmountInfo.tripStartTime,
            bookingAmountInfo.garageArivalTimeWithAdditionalTime,
            note_arr,
            "Admin",
            getActivePackage()
          )
        );
      }
      resetform();
    }
  };

  const getActivePackage = () => {
    const pkg = activatedPackages.find(
      (e) => e.vehicletype === clickedVehicleType
    );
    if (pkg) return pkg;
  };

  const styleBookingStatus = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + bookingStatusval,
        boxShadow: "none",
      };
    },
  };

  // #dc3545  #ced4da
  const stylePickupLocation = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + pickupLocationVal,
        boxShadow: "none",
      };
    },
  };
  const styleDestinations = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + destinationsVal,
        boxShadow: "none",
      };
    },
  };
  const stylePaxtype = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + paxtypeVal,
        boxShadow: "none",
      };
    },
  };

  //undefind
  const loadOptions = (inputValue, callback) => {
    if (typeof inputValue == "undefined" || inputValue === "") {
      return;
    }
    if (window.my_timer != null) {
      clearTimeout(window.my_timer);
    }
    window.my_timer = setTimeout(() => {
      window.my_timer = null;
      try {
        fetchAddresses(callback, inputValue);
      } catch (error) {}
    }, 1000);
  };

  const fetchAddresses = async (callback, inputValue) => {
    const my_address = await getAutocompleteAddresses(inputValue);
    // console.log(my_address);
    const data = my_address.map((row) => {
      return {
        value: row.place_id,
        label: row.description,
        city:
          row.terms.length >= 2 ? row.terms[row.terms.length - 2].value : "",
        lat: null,
        lon: null,
      };
    });
    callback(data);
  };

  const handleInputChange = (newValue) => {
    setInputValue({ inputValue });
    return inputValue;
  };

  const onChangePickupValue = async (e) => {
    setIsloading(true);
    try {
      var place_info = await getLocationByPlaceid(e.value);
    } catch (error) {}
    if (e) {
      e.lat = place_info.geometry.location.lat;
      e.lon = place_info.geometry.location.lng;
    }
    setPickuplocation(e);
    setpickupLocationVal("#ced4da");
    setIsloading(false);
  };

  const onChangeDestinationsValue = async (e) => {
    setIsloading(true);
    try {
      var place_info = await getLocationByPlaceid(e[e.length - 1].value);
    } catch (error) {}
    if (e && e.length > 0) {
      e[e.length - 1].lat = place_info.geometry.location.lat;
      e[e.length - 1].lon = place_info.geometry.location.lng;
    }

    setDestinations(e);
    setDestinationsVal("#ced4da");
    setIsloading(false);
  };

  const changeDropOffValueTimer = (inputValue) => {
    if (typeof inputValue == "undefined" || inputValue === "") {
      return;
    }
    if (window.my_timer != null) {
      clearTimeout(window.my_timer);
    }
    window.my_timer = setTimeout(() => {
      window.my_timer = null;
      try {
        changeCustomerDropOffValue(inputValue);
        getTimeDifferenceIN30MinRounded(inputValue);
      } catch (error) {}
    }, 1000);
  };

  // retun in minutes
  const getTimeDifferenceIN30MinRounded = async (val) => {
    // todo - not updatin total vale in real time
    try {
      // console.log("object");
      var d_time = moment(cutomerActualDropOffTime).format("HH:mm:ss");
      var d_arr = d_time.split(":");
      var et_h = Number(d_arr[0]);
      // customer added time split
      var c_added_arr = val.split(":");
      var c_d_h = Number(c_added_arr[0]);
      var c_d_m = Number(c_added_arr[1]);

      if (et_h <= c_d_h) {
        var customer_changed_dropoff_datetime = moment(
          cutomerActualDropOffTime
        ).set({
          hour: c_d_h,
          minute: c_d_m,
        });
      } else {
        customer_changed_dropoff_datetime = moment(cutomerActualDropOffTime)
          .add(1, "days")
          .set({
            hour: c_d_h,
            minute: c_d_m,
          });
      }

      // this function
      var duration = moment.duration(
        moment(customer_changed_dropoff_datetime._d).diff(
          moment(cutomerActualDropOffTime)
        )
      );
      var minutes = duration.asMinutes();
      const rounded = Math.ceil(minutes / 30) * 30;
      // return rounded;
      setPackageAdditionaltime(rounded);
    } catch {}
  };

  useEffect(() => {
    try {
      // getTimeDifferenceIN30MinRounded(cutomerChangedDropOffTimeOnly);
      applyToTheEquation(null, selectedVehicleType);
    } catch {}
  }, [garageArivalTimeWithAdditionalTime, packageAdditionaltime]);

  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Create Booking" />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <form onSubmit={checkAvailability}>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col">
                        <label className="label mb-2">Travel Date:</label>
                        <div className="col">
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            className={`form-control ${traveldateVal}`}
                            selected={travelDate}
                            onChange={(date) => {
                              const d = new Date(date);
                              const newTravelDate = moment(d).add(1, "days");
                              setNewTravelDate(newTravelDate);
                              setTravelDate(date);
                              setTravelDateVal("");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <label className="label mb-2">Pick-up time:</label>
                        <div className="col">
                          <input
                            type="time"
                            className={`form-control ${pickupvalidation}`}
                            placeholder="Enter picktime"
                            name="pickuptime"
                            value={pickuptime}
                            onChange={(e) => {
                              setPickuptime(e.target.value);
                              setPickupvalidation("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Pick-up location:</label>
                    <div className="col">
                      <AsyncSelect
                        placeholder="Type address..."
                        isClearable={true}
                        loadOptions={loadOptions}
                        defaultOptions
                        value={pickuplocation}
                        onChange={(e) => {
                          onChangePickupValue(e);
                        }}
                        //onInputChange={handleInputChange}
                        styles={stylePickupLocation}
                        noOptionsMessage={() => "No Address Found"}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Add Destination's :</label>
                    <div className="col">
                      <AsyncSelect
                        isMulti
                        placeholder="Type address..."
                        isClearable={true}
                        loadOptions={loadOptions}
                        defaultOptions
                        value={destinations}
                        onChange={(e) => {
                          onChangeDestinationsValue(e);
                        }}
                        onInputChange={handleInputChange}
                        styles={styleDestinations}
                        noOptionsMessage={() => "No Address Found"}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Number of PAX:</label>

                    <div className="row">
                      <div className="col-5">
                        <Select
                          name="paxtype"
                          value={paxtype}
                          onChange={(e) => {
                            setPaxtype(e);
                            setPaxtypeVal("#ced4da");
                          }}
                          options={paxOptions}
                          classNamePrefix="select"
                          autoComplete="off"
                          styles={stylePaxtype}
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="number"
                          className={`form-control ${paxcountVal}`}
                          placeholder="PAX"
                          name="paxcount"
                          value={paxcount}
                          onChange={(e) => {
                            setPaxCount(Number(e.target.value));
                            setPaxcountVal("");
                          }}
                        />
                      </div>

                      <div className="col-1">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={(e) => addPax(e)}
                        >
                          <i
                            className="fa fa-plus fa-fw"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    {paxList.map((x, i = 100) => (
                      <span
                        key={i++}
                        className="badge bg-warning text-dark pax-card"
                      >
                        {x.paxcount} - {x.paxtype}{" "}
                        <a href="#!">
                          <i
                            className="fa fa-times fa-fw"
                            aria-hidden="true"
                            onClick={(e) => {
                              e.preventDefault();
                              setPaxList(
                                paxList.filter(
                                  (item) => item.paxtype !== x.paxtype
                                )
                              );
                            }}
                          ></i>
                        </a>
                      </span>
                    ))}
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Pieces of Luggage:</label>
                    <div className="row">
                      <div className="col-5">
                        <Select
                          name="luggagetype"
                          value={luggagetype}
                          options={baggageOptions}
                          onChange={setLuggageType}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="number"
                          className="form-control"
                          id="pieces"
                          placeholder="Pieces"
                          name="luggagecount"
                          value={luggagecount}
                          onChange={(e) =>
                            setLuggageCount(Number(e.target.value))
                          }
                        />
                      </div>

                      <div className="col-1">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={(e) => addLuggage(e)}
                        >
                          <i
                            className="fa fa-plus fa-fw"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    {luggagelist.map((x, i = 1) => (
                      <span
                        key={i++}
                        className="badge bg-warning text-dark pax-card"
                      >
                        {x.luggagecount} - {x.luggagetype}{" "}
                        <a href="#!">
                          <i
                            className="fa fa-times fa-fw"
                            aria-hidden="true"
                            onClick={(e) => {
                              e.preventDefault();
                              setLuggageList(
                                luggagelist.filter(
                                  (item) => item.luggagetype !== x.luggagetype
                                )
                              );
                            }}
                          ></i>
                        </a>
                      </span>
                    ))}
                  </div>

                  <hr />
                  <button
                    type="submit"
                    value="submit"
                    className="btn btn-secondary"
                  >
                    <i className="fa fa-check fa-fw" aria-hidden="true"></i>{" "}
                    Check Availability
                  </button>
                  <hr />
                </form>

                <div className="mb-3">
                  <label className="label mb-2">
                    Available Vehicle Types (Click to select):
                  </label>
                  <div className="col">
                    <div className="list-group">
                      {vehicleTypeNames.map((vt) => (
                        <a
                          key={vt._id}
                          onClick={(e) => {
                            applyToTheEquation(e, vt);
                          }}
                          className={`list-group-item list-group-item-action list-group-item-info ${
                            clickedVehicleType === vt.typename ? "active" : ""
                          }`}
                          aria-current="true"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <h6 className="mb-1">{vt.typename}</h6>

                            <span>
                              <VehicleTypeList
                                vehicle_type={vt}
                                activatedPackages={activatedPackages}
                                customerAddedAditionalMinutes={
                                  packageAdditionaltime
                                }
                                total_hours={getTotalHours()}
                                toll_fare={tollFare}
                                zone_hours={getHoursInsideMultiplierZone(
                                  tripStartTime,
                                  garageArivalTimeWithAdditionalTime
                                )}
                              />
                            </span>
                          </div>
                        </a>
                      ))}
                    </div>

                    <div className="alert alert-warning">{infoSt}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <label className="form-label badge bg-success">
                        Client drop-off time: [ Arrival at garage:{" "}
                        {garageArivalTimeWithAdditionalTime
                          ? moment(garageArivalTimeWithAdditionalTime).format(
                              "HH:mm:ss"
                            )
                          : ""}{" "}
                        ]
                      </label>
                      <div className="col">
                        <input
                          type="time"
                          className={`form-control ${dropoffVal}`}
                          id="picktime"
                          placeholder="Enter picktime"
                          name="cutomerChangedDropOffTimeOnly"
                          value={cutomerChangedDropOffTimeOnly}
                          onChange={(e) => {
                            if (selectedVehicleType != null) {
                              setcutomerChangedDropOffTimeOnly(e.target.value);
                              // changeCustomerDropOffValue(e.target.value);
                              setDropoffVal("");
                              changeDropOffValueTimer(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label className="label mb-2">Estimate amount:</label>
                      <div className="col">
                        <input
                          type="number"
                          className={`form-control`}
                          id="picktime"
                          placeholder="Enter estimated value"
                          name="estimate"
                          value={estimate}
                          onChange={(e) => {
                            setEstimate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label className="label mb-2">Customer Name:</label>
                  <div className="col">
                    <input
                      className={`form-control ${nameval}`}
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameval("");
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-8">
                      <label className="label mb-2">Email:</label>
                      <div className="col">
                        <input
                          className={`form-control ${emailval}`}
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailval("");
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="label mb-2">Contact Number:</label>
                      <div className="col">
                        <input
                          className={`form-control ${phoneval}`}
                          placeholder="Phone"
                          name="phone"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneval("");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label className="label mb-2">Address :</label>
                  <div className="col">
                    <textarea
                      className={`form-control ${addressval}`}
                      rows="2"
                      placeholder="Residential Address"
                      name="address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        setAddressval("");
                      }}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="label mb-2">Booking Status :</label>
                  <div className="col-6">
                    <Select
                      styles={styleBookingStatus}
                      classNamePrefix="select pick-up location"
                      autoComplete="off"
                      value={bookingStatus}
                      onChange={(e) => {
                        setBookingStatus(e);
                        setBookingStatusval("#ced4da");
                      }}
                      options={bookingStatusOptions}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="label mb-2">Note :</label>
                  <div className="col">
                    <textarea
                      className={`form-control`}
                      rows="2"
                      placeholder="Add Note..."
                      name="note"
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <hr />
                  <button
                    onClick={confirmBooking}
                    className="btn btn-secondary"
                  >
                    <i className="fa fa-check fa-fw" aria-hidden="true"></i>{" "}
                    Confirm Booking
                  </button>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default CreateBooking;
