import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Alert from "../../layout/Alert";
import Layout from "../../layout/Layout";
import TitleBar from "../../layout/TitleBar";
import ScaleLoader from "react-spinners/ScaleLoader";
import AsyncSelect from "react-select/async";
import {
  getAutocompleteAddresses,
  getLocationByPlaceid,
} from "../../../actions/googleAPI";
import { addAirportToDB, getAirport } from "../../../actions/admin/airport";
import { useDispatch, useSelector } from "react-redux";

const UpdateAirport = ({ match }) => {
  const dispatch = useDispatch();
  const air_state = useSelector((state) => state.airport);
  const { airportState, loading } = air_state;
  const [isloading, setIsloading] = useState(false);
  const [pickupLocationVal, setpickupLocationVal] = useState("#ced4da");

  const [formData, setFormData] = useState({
    _id: null,
    airportname: "",
    airport: "",
    city: "",
    isactive: true,
    address_components: null,
  });
  const { _id, airport, airportname, isactive, city } = formData;

  useEffect(() => {
    dispatch(getAirport(match.params.id));
  }, []);

  useEffect(() => {
    console.log("airportState");
    console.log(airportState);
    if (!airportState) return;
    setFormData({
      _id: airportState._id,
      airportname: airportState.airportname,
      airport: airportState.airport,
      city: airportState.city,
      isactive: airportState.isactive,
      address_components: airportState.address_components,
    });
  }, [airportState, loading]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    dispatch(addAirportToDB(formData));
  };

  //undefind
  const loadOptions = (inputValue, callback) => {
    if (typeof inputValue == "undefined" || inputValue === "") {
      return;
    }
    if (window.my_timer != null) {
      clearTimeout(window.my_timer);
    }
    window.my_timer = setTimeout(() => {
      window.my_timer = null;
      try {
        fetchAddresses(callback, inputValue);
      } catch (error) { }
    }, 1000);
  };
  const fetchAddresses = async (callback, inputValue) => {
    const my_address = await getAutocompleteAddresses(inputValue);
    // console.log(my_address);
    const data = my_address.map((row) => {
      return {
        value: row.place_id,
        label: row.description,
        city:
          row.terms.length >= 2 ? row.terms[row.terms.length - 2].value : "",
        lat: null,
        lon: null,
      };
    });
    callback(data);
  };

  const onChangeAirportValue = async (e) => {
    setIsloading(true);
    try {
      var place_info = await getLocationByPlaceid(e.value);
    } catch (error) { }
    if (e) {
      // console.log(place_info);
      e.lat = place_info.geometry.location.lat;
      e.lon = place_info.geometry.location.lng;
    }
    const city = place_info.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    setFormData({
      ...formData,
      airport: e,
      city,
      address_components: place_info.address_components,
    });
    setpickupLocationVal("#ced4da");
    setIsloading(false);
  };
  const stylePickupLocation = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + pickupLocationVal,
        boxShadow: "none",
      };
    },
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <LoadingOverlay active={loading} spinner={<ScaleLoader color="#36D7B7" />}>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Update Airport" />
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={(e) => onFormSubmit(e)}>

                <div className="mb-3">
                  <div className="col-sm-offset-2 col-sm-10">
                    <Link className="btn btn-secondary" to="/admin/airportlist">
                      <i
                        className="fa fa-arrow-left fa-fw"
                        aria-hidden="true"
                      ></i>{" "}
                      Back To List
                    </Link>
                  </div>
                </div>

                <div className="col-md-6">

                  <div className="card mb-3">
                    <div className="card-header">Airport status</div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="form-check-label form-check-label-admin">Airport is active</label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={isactive}
                            name="isactive"
                            onChange={(e) => {
                              setFormData({ ...formData, isactive: !isactive });
                            }}
                          />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">Airport details</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Airport Name:</label>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter airport name"
                            name="airportname"
                            value={airportname}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="label mb-2">Airport:</label>
                        <div className="col">
                          <AsyncSelect
                            placeholder="Type address..."
                            isClearable={true}
                            loadOptions={loadOptions}
                            defaultOptions
                            value={airport}
                            onChange={(e) => {
                              onChangeAirportValue(e);
                            }}
                            styles={stylePickupLocation}
                            noOptionsMessage={() => "No Address Found"}
                          />
                        </div>
                      </div>

                    </div>
                  </div>



                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update Airport
                      </button>
                    </div>
                  </div>



                </div>
              </form>
            </div>
          </div>
          <Alert />
        </main>
      </Layout >
    </LoadingOverlay >
  );
};

export default UpdateAirport;
