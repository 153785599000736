import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";
import {
  GET_AIRPORTS,
  AIRPORT_ERROR,
  GET_AIRPORT,
  DELETE_AIRPORT,
} from "../types";

export const deleteAirport = (id) => async (dispatch) => {
  try {
    const res = await customAxios.delete(`/api/admin/airports/${id}`);
    dispatch({
      type: DELETE_AIRPORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AIRPORT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const getAirport = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/airports/${id}`);
    dispatch({
      type: GET_AIRPORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AIRPORT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const getAirports = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/airports");
    dispatch({
      type: GET_AIRPORTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AIRPORT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const getActiveAirports = (status) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/airports/status/${status}`);
    dispatch({
      type: GET_AIRPORTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AIRPORT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// add driver
export const addAirportToDB = (formdata) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post("/api/admin/airports", formdata, config);
    dispatch(setAlert("Airport added", "success"));
    // console.log(res);
    return res;
  } catch (err) {
    const errors = err.response.data.errors;
    return errors;
  }
};
