import customAxios from "../../utils/customAxios";
import axios from "axios";

export const getUploadUrl = async (name) => {
  try {
    const response = await customAxios.get(`/api/admin/awsservice/${name}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadFile = async (url, data) => {
  try {
    // for data, add file -> f.file.target.files[0]
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.put(url, data, config);
    
    return response.status;
  } catch (err) {
    return err;
  }
};
