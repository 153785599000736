import React, { useState, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import { addPackage, getPackage } from "../../../actions/admin/package";
// import { getVehicleTypes } from "../../../actions/admin/vehicletype";
import Alert from "../../layout/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  getAutocompleteAddresses,
  getLocationByPlaceid,
} from "../../../actions/googleAPI";
import AsyncSelect from "react-select/async";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link, withRouter } from "react-router-dom";
import { getAllVehicleTypes } from "../../../actions/admin/vehicletype";

const UpdatePackage = ({ match }) => {
  const dispatch = useDispatch();
  const trippackage = useSelector((state) => state.trippackage);
  const { tpackage, loading } = trippackage;
  const [destinationsVal, setDestinationsVal] = useState("#ced4da");
  const [pickupLocationVal, setpickupLocationVal] = useState("#ced4da");
  const [isloading, setIsloading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    _id: null,
    airport: "",
    airporttags: "",
    locations: null,
    locationstags: "",
    vehicletype: null,
    rate: 0,
    packagename: "",
    isactive: true,
  });

  const {
    _id,
    airport,
    locations,
    vehicletype,
    rate,
    packagename,
    isactive,
    airporttags,
    locationstags,
  } = formData;
  useEffect(() => {
    dispatch(getPackage(match.params.id));
    fetchVehicleTypes(callbackfunck);
  }, [dispatch, match.params.id]);
  const fetchVehicleTypes = async (callback) => {
    setIsloading(true);
    const vt_data = await getAllVehicleTypes();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.typename,
      };
    });
    setIsloading(false);
    callback(data);
  };
  const callbackfunck = (e_data) => {
    setVehicleTypeOptions(e_data);
  };
  useEffect(() => {
    const opt = vehicleTypeOptions.find(
      (op) => op.label === tpackage?.vehicletype
    );

    setFormData({
      _id: loading || !tpackage?._id ? "" : tpackage?._id,
      airport: loading || !tpackage?.airport ? "" : tpackage?.airport,
      rate: loading || !tpackage?.rate ? "" : tpackage?.rate,
      airporttags:
        loading || !tpackage?.airporttags ? "" : tpackage?.airporttags,
      locationstags:
        loading || !tpackage?.locationstags ? "" : tpackage?.locationstags,
      packagename:
        loading || !tpackage?.packagename ? "" : tpackage?.packagename,
      vehicletype: opt?.label,
      locations: loading || !tpackage?.locations ? "" : tpackage?.locations,
      isactive: loading || !tpackage?.isactive ? false : tpackage?.isactive,
    });
    setSelectedOption(opt);
  }, [vehicleTypeOptions, loading, tpackage]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (!formData.airport) {
      setpickupLocationVal("#dc3545");
      return;
    }
    if (!formData.locations) {
      setDestinationsVal("#dc3545");
      return;
    }
    // console.log(formData);
    dispatch(addPackage(formData));
  };

  //undefind
  const loadOptions = (inputValue, callback) => {
    if (typeof inputValue == "undefined" || inputValue === "") {
      return;
    }
    if (window.my_timer != null) {
      clearTimeout(window.my_timer);
    }
    window.my_timer = setTimeout(() => {
      window.my_timer = null;
      try {
        fetchAddresses(callback, inputValue);
      } catch (error) {}
    }, 1000);
  };

  const fetchAddresses = async (callback, inputValue) => {
    const my_address = await getAutocompleteAddresses(inputValue);
    // console.log(my_address);
    const data = my_address.map((row) => {
      return {
        value: row.place_id,
        label: row.description,
        city:
          row.terms.length >= 2 ? row.terms[row.terms.length - 2].value : "",
        lat: null,
        lon: null,
      };
    });
    callback(data);
  };

  const onChangeAirportValue = async (e) => {
    setIsloading(true);
    try {
      var place_info = await getLocationByPlaceid(e.value);
    } catch (error) {}
    var airtags = "";
    if (e) {
      console.log(place_info);
      e.lat = place_info.geometry.location.lat;
      e.lon = place_info.geometry.location.lng;
      airtags = place_info.address_components[0].long_name;
    }
    setFormData({
      ...formData,
      airport: e,
      airporttags: airtags,
    });
    setpickupLocationVal("#ced4da");
    setIsloading(false);
  };

  const onChangeLocationPointsValue = async (e) => {
    setIsloading(true);
    try {
      var place_info = await getLocationByPlaceid(e[e.length - 1].value);
      console.log(place_info);
    } catch (error) {}
    var locationtags = "";
    if (e && e.length > 0) {
      e[e.length - 1].lat = place_info.geometry.location.lat;
      e[e.length - 1].lon = place_info.geometry.location.lng;
      locationtags = place_info.address_components[0].long_name;
    }
    setFormData({
      ...formData,
      locations: e,
      locationstags: locationstags,
    });
    setDestinationsVal("#ced4da");
    setIsloading(false);
  };

  const handleInputChange = (newValue) => {
    setInputValue({ inputValue });
    return inputValue;
  };

  const styleDestinations = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + destinationsVal,
        boxShadow: "none",
      };
    },
  };

  const stylePickupLocation = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + pickupLocationVal,
        boxShadow: "none",
      };
    },
  };

  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Update Package" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label mb-2">Airport:</label>
                  <div className="col">
                    <AsyncSelect
                      placeholder="Type address..."
                      isClearable={true}
                      loadOptions={loadOptions}
                      defaultOptions
                      value={airport}
                      onChange={(e) => {
                        onChangeAirportValue(e);
                      }}
                      styles={stylePickupLocation}
                      noOptionsMessage={() => "No Address Found"}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="label mb-2">
                    # Airport Search tags(comma separated):
                  </label>
                  <div className="col">
                    <textarea
                      type="text"
                      className="form-control form-control-admin"
                      placeholder="tag1, tag2, tag3, ..."
                      name="airporttags"
                      value={airporttags}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="label mb-2">Location Points :</label>
                  <div className="col">
                    <AsyncSelect
                      isMulti
                      placeholder="Locations..."
                      isClearable={true}
                      loadOptions={loadOptions}
                      defaultOptions
                      value={formData.locations}
                      onChange={(e) => {
                        onChangeLocationPointsValue(e);
                      }}
                      onInputChange={handleInputChange}
                      styles={styleDestinations}
                      noOptionsMessage={() => "No Address Found"}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="label mb-2">
                    # Locations search tags(comma separated):
                  </label>
                  <div className="col">
                    <textarea
                      type="text"
                      className="form-control form-control-admin"
                      placeholder="tag1, tag2, tag3, ..."
                      name="locationstags"
                      value={locationstags}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <form onSubmit={(e) => onFormSubmit(e)}>
                  <div className="mb-3">
                    <label className="label mb-2">Package Name:</label>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control form-control-admin"
                        placeholder="Enter package name"
                        name="packagename"
                        value={packagename}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Vehicle Type:</label>
                    <div className="col">
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select pick-up location"
                        autoComplete="off"
                        value={selectedOption}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            vehicletype: e.label,
                          });
                          setSelectedOption(e);
                        }}
                        options={vehicleTypeOptions}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Rate:</label>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter rate"
                        name="rate"
                        value={rate}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={isactive}
                        name="isactive"
                        onChange={(e) => {
                          setFormData({ ...formData, isactive: !isactive });
                        }}
                      />
                      <label className="form-check-label">Active status</label>
                    </div>
                  </div>

                  <hr />
                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-secondary">
                        <i
                          className="fa fa-pencil fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update Package
                      </button>{" "}
                      <Link className="btn btn-warning" to="/admin/packagelist">
                        <i
                          className="fa fa-arrow-left fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Back To List
                      </Link>
                    </div>
                  </div>
                  <hr />
                </form>
              </div>
            </div>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default withRouter(UpdatePackage);
