import { ADD_DRIVER, DRIVER_ERROR, GET_DRIVERS, GET_DRIVER } from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

// get driver by id
export const getDriver = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/drivers/${id}`);
    dispatch({
      type: GET_DRIVER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DRIVER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// get drivers
export const getDrivers = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/drivers");
    dispatch({
      type: GET_DRIVERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DRIVER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// get all active vehicles
export const getAllDrivers = async () => {
  try {
    const res = await customAxios.get("/api/admin/drivers");
    return res.data;
  } catch (err) {
    return [];
  }
};

// add driver
export const addDriver =
  (formdata, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await customAxios.post(
        "/api/admin/drivers",
        formdata,
        config
      );
      dispatch({
        type: ADD_DRIVER,
        payload: res.data,
      });
      dispatch(setAlert(edit ? "Driver Updated" : "Driver created", "success"));
    } catch (err) {
      console.log(err);
      const errors = err.response.data.errors;
      if (errors) {
        dispatch(setAlert(JSON.stringify(errors), "danger"));
      }
      dispatch({
        type: DRIVER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };
