import React, { Fragment, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getVehicles } from "../../../actions/admin/vehicle";
import VehicleItem from "./VehicleItem";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";

const VehicleList = () => {
  const vehicle = useSelector((state) => state.vehicle);
  const { loading, vehicles } = vehicle;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getVehicles());
  }, [dispatch]);

  return (
    <Fragment>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Vehicle List" />
          <h4 className="my-4">All available Vehicles</h4>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Vehicle Type</th>
                      <th scope="col">Vehicle Regisration</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicles.map((vehicle, i = 0) => (
                      <VehicleItem
                        key={vehicle._id}
                        vehicle={vehicle}
                        count={i++}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default VehicleList;
