import React, { useState, useEffect } from "react";
// import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import { getAllActiveVehicles } from "../../../actions/admin/vehicle";
import { getDrivers } from "../../../actions/admin/driver";
import { getAllUsersAdmin } from "../../../actions/auth";
import { getCalanderBookingsFilter } from "../../../actions/admin/booking";
import moment from "moment";
import DashBookingItem from "./DashBookingItem";
import Spinner from "../../layout/Spinner";

// import { getSetting } from "../../../actions/admin/settings";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driver);
  const { drivers } = driver;
  const auth = useSelector((state) => state.auth);
  const { users } = auth;
  const booking = useSelector((state) => state.booking);
  const { loading, calanderBooking } = booking;
  const [activeVehicles, setActiveVehicles] = useState(0);
  const [isloading, setIsloading] = useState(false);

  const [dateRange, setdateRange] = useState({
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  });

  useEffect(() => {
    dispatch(getDrivers());
    fetchActiveVehicles(activeVehicleCallBackFunc);
    dispatch(getAllUsersAdmin());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getCalanderBookingsFilter(
        "",
        dateRange.startDate,
        dateRange.endDate,
        false
      )
    );
  }, []);


  const fetchActiveVehicles = async (callback) => {
    setIsloading(true);
    const data = await getAllActiveVehicles();
    callback(data);
  };

  const activeVehicleCallBackFunc = (data) => {
    // set count
    setActiveVehicles(data?.length);
    setIsloading(false);
  };

  return (
    // <LoadingOverlay
    //   active={isloading || loading}
    //   spinner={<ScaleLoader color="#36D7B7" />}
    // >
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Dashboard" />

        {isloading || loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card-counter primary">
                    <i className="fa fa-code-fork pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={calanderBooking?.length}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">bookings this month</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter danger">
                    <i className="fa fa-ticket pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={users?.length}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">total users</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter success">
                    <i className="fa fa-database pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={activeVehicles}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">active vehicles</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter info">
                    <i className="fa fa-users pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={drivers.filter((i) => i.isactive === true).length}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">active drivers</span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <h4 className="mb-4">This Month bookings</h4>
            <div>
              <table className="table-sort table table-hover table-sm booking-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">CartID</th>
                    <th scope="col">Vehicle Type</th>
                    <th scope="col">Booking Status</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Pickup</th>
                    <th scope="col">Dropoff</th>
                    <th scope="col">Pickup Location</th>
                  </tr>
                </thead>
                <tbody>
                  {calanderBooking?.map((booking, i = 0) => (
                    <DashBookingItem
                      key={booking._id}
                      booking={booking}
                      count={i++}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
    </Layout>
    // </LoadingOverlay>
  );
};

AdminDashboard.propType = {
  isAuthenticatedAdmin: PropTypes.bool,
};

const mapsStateToProps = (state) => ({
  isAuthenticatedAdmin: state.auth.isAuthenticatedAdmin,
});

export default connect(mapsStateToProps)(AdminDashboard);
