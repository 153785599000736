import React, { useState, Fragment, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getPackages, deletePackage } from "../../../actions/admin/package";
import PackageItem from "./PackageItem";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";

const PackageList = () => {
  const trippackage = useSelector((state) => state.trippackage);
  const { loading, tpackages } = trippackage;
  const [delId, setDelId] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPackages());
  }, [loading]);

  const removePackage = (id) => {
    setDelId(id);
  };

  const delPkgfromPopup = () => {
    dispatch(deletePackage(delId));
    setDelId(null);
  };

  console.log(tpackages);
  return (
    <Fragment>
      <Layout>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Warning!
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete this package?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={delPkgfromPopup}
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Package List" />
          <h4>All available packages</h4>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                {/* <button
                  type="button"
                  classname="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Launch demo modal
                </button> */}
                <table className="table table-hover table-sm booking-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Package Name</th>
                      <th scope="col">Airport</th>
                      <th scope="col">Locations</th>
                      <th scope="col">Vehicle Type</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tpackages?.map((p, i = 0) => (
                      <PackageItem
                        key={p._id}
                        package={p}
                        count={i++}
                        deletePackage={removePackage}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default PackageList;
