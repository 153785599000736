import React from "react";
import { Link } from "react-router-dom";

const TemplateItem = ({ template, deleteTemplate, count }) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>{template.templatename}</td>
      <td>{template.iscustomeremail ? "yes" : "no"}</td>
      <td className="px-0" style={{ width: "6%" }}>
        <Link
          type="button"
          className="btn btn-secondary py-1 px-3"
          to={`/admin/templatelist/edit/${template._id}`}
        >
          Edit
        </Link>
      </td>
      <td className="ps-1" style={{ width: "5%" }}>
        <button
          type="button"
          className="btn btn-outline-danger py-1 px-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            deleteTemplate(template._id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default TemplateItem;
