import {
    ADD_GARAGE,
    UPDATE_GARAGE,
    DELETE_GARAGE,
    GET_GARAGES,
    GET_GARAGE,
    GARAGE_ERROR,
} from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const addGarage = (formData, history, edit = false) => async (dispatch) => {
    try {
        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
        const res = await customAxios.post(
            `/api/admin/garages`,
            formData,
            config
        );
        dispatch({
            type: ADD_GARAGE,
            payload: res.data,
        });

        window.location.href = '/admin/garagelist';
    } catch (err) {
        dispatch({
            type: GARAGE_ERROR,
            payload: { msg: err.response, status: err.response.status },
        });
    }
};

export const updateGarage = (formData, id) => async (dispatch) => {
    try {
        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
        const res = await customAxios.post(
            `/api/admin/garages/${id}`,
            formData,
            config
        );

        dispatch({
            type: UPDATE_GARAGE,
            payload: res.data.data,
        });
        dispatch(setAlert("Garage Data Updated"));

        window.location.href = '/admin/garagelist';
    } catch (err) {
        dispatch({
            type: GARAGE_ERROR,
            payload: { msg: err.response, status: err.response.status },
        });
    }
};

export const getGarage = (id) => async (dispatch) => {
    try {
        const res = await customAxios.get(`/api/admin/garages/${id}`);
        dispatch({
            type: GET_GARAGE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GARAGE_ERROR,
            payload: { msg: err.response, status: err.response.status },
        });
    }
};

export const getGarages = () => async (dispatch) => {
    try {
        const res = await customAxios.get(`/api/admin/garages`);
        dispatch({
            type: GET_GARAGES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GARAGE_ERROR,
            payload: { msg: err.response, status: err.response.status },
        });
    }
};

export const deleteGarage = (id) => async (dispatch) => {
    try {
        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
        const res = await customAxios.delete(
            `/api/admin/garages/del/${id}`,
            config
        );
        dispatch({
            type: DELETE_GARAGE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GARAGE_ERROR,
            payload: { msg: err.response, status: err.response.status },
        });
    }
};