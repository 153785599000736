import {
    CHANGE_BOOKING_VEHICLE,
} from "./types";
import customAxios from "../utils/customAxios";
import { setAlertEditBooking } from "./alert";
import setAuthToken from "../utils/setAuthToken";

export const postRequest = async (reqBody) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            `/api/request-change-booking`,
            reqBody,
            config
        );
        
        return res.data;
    } catch (err) {
        console.log(err)
        return [err];
    }
};

export const updateRequest = async (reqBody) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            `/api/request-change-booking/update`,
            reqBody,
            config
        );
        
        return res.data;
    } catch (err) {
        console.log(err)
        return [err];
    }
};

export const addNote = (reqBody) => async (dispatch) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            `/api/admin/bookings/addnote`,
            reqBody,
            config
        );
        dispatch({
            type: CHANGE_BOOKING_VEHICLE,
            payload: res.data,
        });
        dispatch(setAlertEditBooking("addNote", true));
    } catch (err) {
        dispatch(setAlertEditBooking("addNote", false));
        console.log(err)
        return [err];
    }
};

export const saveFlightNumber = (reqBody) => async (dispatch) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            `/api/admin/bookings/change-flight-number`,
            reqBody,
            config
        );
        dispatch({
            type: CHANGE_BOOKING_VEHICLE,
            payload: res.data,
        });
        dispatch(setAlertEditBooking("changeFlightNumber", true));
    } catch (err) {
        dispatch(setAlertEditBooking("changeFlightNumber", false));
        console.log(err)
        return [err];
    }
};

export const updateRequestPaymentStatus = async (reqBody) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            `/api/request-change-booking/add-stripe-ref`,
            reqBody,
            config
        );
        
        return res.data;
    } catch (err) {
        console.log(err)
        return [err];
    }
}

export const deleteCanceledRequestPaymentStatus = async (reqBody) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            `/api/request-change-booking/delete`,
            reqBody,
            config
        );
        
        return res.data;
    } catch (err) {
        console.log(err)
        return [err];
    }
}

export const updateBookingState = (booking) => async (dispatch) => {
 try{   
    dispatch({
        type: CHANGE_BOOKING_VEHICLE,
        payload: booking,
    });
} catch (err) {
    console.error(err)
}
}


