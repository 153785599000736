import React, { Fragment, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getCancelRescheduleRequests } from "../../../actions/admin/cancelreschedulerequest";
import CancelRescheduleRequestItem from "./CancelRescheduleRequestItem";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";

const CancelRescheduleRequestList = () => {
  const cancelreschedulerequest = useSelector((state) => state.cancelreschedule);
  const { loading, cancelreschedulerequests } = cancelreschedulerequest;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCancelRescheduleRequests());
  }, [dispatch]);

  return (
    <Fragment>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Cancellation and Rescheule Requests" />
          <h4 className="my-4">All Cancellation and Rescheule Requests</h4>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Customer Name - Email</th>
                      <th scope="col">Request Type</th>
                      <th scope="col">Request Date</th>
                      <th scope="col">Booking Start Time</th>
                      <th scope="col">Request Start Time</th>
                      <th scope="col">Payment Status</th>
                      <th scope="col">Approval Status</th>
                      <th scope="col">Admin</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cancelreschedulerequests.map((cancelreschedulerequest, i = 0) => (
                      <CancelRescheduleRequestItem
                        key={cancelreschedulerequest._id}
                        cancelreschedulerequest={cancelreschedulerequest}
                        count={i++}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default CancelRescheduleRequestList;
