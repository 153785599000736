import customAxios from "../utils/customAxios";
//This handles all the Stripe API configuration. See the Stripe API for more information. 
// Function to check the status of a Stripe reference session
export const checkStripeRefSession = async (striperef) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/stripe/check-striperef-session",
      { striperef },
      config
    );
    // console.log(res.data.session);
    return res.data.session;
  } catch (err) {
    // console.log(err);
    // return [err];
  }
};
// Function to test the Stripe checkout session creation
export const testStripePost = async (customerData, cart, lang) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/stripe/create-checkout-session",
      { customerData, cart, lang },
      config
    );
    // console.log(res.data.session);
    return res.data.session;
  } catch (err) {
    // console.log(err);
    // return [err];
  }
};

// Function to test the Stripe checkout session creation
export const checkoutEditBooking = async (editBookingForm, priceToCheckout) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/stripe/create-edit-booking-stripe-session",
      {editBookingForm, priceToCheckout},
      config
    );
    // console.log(res.data.session);
    return res.data.session;
  } catch (err) {
    // console.log(err);
    // return [err];
  }
};

// Function to update the Stripe payment intent
export const updatePaymentIntent = async (amount, key) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/stripe/update-payment-intent",
      {
        amount,
        key,
      },
      config
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return [err];
  }
};

// Function to create a payment intent
export const createPaymentIntent = async (amount, info) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/stripe/create-payment-intent",
      {
        amount,
        info,
      },
      config
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return [err];
  }
};
