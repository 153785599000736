import {
    GET_NOTIFICATIONS_CUSTOMER,
    GET_NOTIFICATION_CUSTOMER,
    GET_NOTIFICATIONS_CUSTOMER_ERROR
  } from "../actions/types";
  
  const initialState = {
    notifications: [],
    notification: null,
    loading: true,
    error: {},
  };
  
  export default function foo(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_NOTIFICATIONS_CUSTOMER:
        return {
          ...state,
          notifications: payload,
          loading: false,
        };
      case GET_NOTIFICATION_CUSTOMER:
        return {
          ...state,
          notification: payload,
          loading: false,
        };
      case GET_NOTIFICATIONS_CUSTOMER_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      default:
        return state;
    }
  }
  