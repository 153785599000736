import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import { addGarage } from "../../../actions/admin/garage";
import { getAllVehicleTypes } from "../../../actions/admin/vehicletype";
import Alert from "../../layout/Alert";
import { useDispatch } from "react-redux";
import Creatable from "react-select/creatable";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const AddGarage = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        prefecture: [],
        availableVehicleTypes: [],
        lon: "",
        lat: "",
        isActive: true,
        defaultGarage: false,
    });

    const {
        name,
        prefecture,
        lon,
        lat,
        isActive,
        defaultGarage,
    } = formData;

    const [lonState, setLon] = useState('');
    const [latState, setLat] = useState('');
    const [isActiveState, setIsActiveState] = useState(true);
    const [defaultGarageState, setDefaultGarageState] = useState(false);
    const [marker, setMarker] = useState(null);
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
    const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);

    const fetchAllVehicleType = async () => {
        const vehicleTypes = await getAllVehicleTypes();

        const options = vehicleTypes.map((v) =>  ({"value": v._id, "label": v.typename}));

        setVehicleTypeOptions(options);
    }

    useEffect(() => {
        fetchAllVehicleType();
    }, []);

    const selectVehicleTypeHandler = (selections) => {
        setSelectedVehicleTypes(selections);
        setFormData({ ...formData, availableVehicleTypes: selections })
    }

    const onChange = (e) => {
        console.log(e.target.name, e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'lon') setLon(e.target.value);
        if (e.target.name === 'lat') setLat(e.target.value);
        if (e.target.name === 'isActive') setIsActiveState(!isActiveState);
        if (e.target.name === 'defaultGarage') setDefaultGarageState(!defaultGarageState);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        dispatch(addGarage(formData));
        setFormData({
            name: "",
            prefecture: [],
            availableVehicleTypes: [],
            lon: "",
            lat: "",
            isActive: true,
            defaultGarage: false,
        });


    };

    const onChangePrefecture = async (e) => {
        const lowercaseArr = e.map((element) => {
            return {
                label: element.label.toLowerCase(),
                value: element.value.toLowerCase(),
            };
        });
        setFormData({ ...formData, prefecture: lowercaseArr });
    };

    const onMapClick = (e) => {
        setLat(e.latLng.lat());
        setLon(e.latLng.lng());
        setFormData({
            ...formData,
            "lon": e.latLng.lng(),
            "lat": e.latLng.lat(),
        });
        setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    };

    return (
        <Layout>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <TitleBar title="Add Garage" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            {/* <form onSubmit={(e) => onFormSubmit(e)}> */}

                            <div className="card mb-3">
                                <div className="card-header">Garage details</div>
                                <div className="card-body">

                                    <div className="mb-3">
                                        <label className="label mb-2">Name:</label>
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                className="form-control form-control-admin"
                                                id="name"
                                                placeholder="Enter Garage"
                                                name="name"
                                                value={name}
                                                onChange={(e) => onChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="label mb-2">Prefecture Tags:</label>
                                        <div className="col">
                                            <Creatable
                                                // className="w-full border border-gray-200 focus:border-gray-500 focus:outline-none focus:bg-white"
                                                isClearable
                                                isMulti={true}
                                                onChange={(v) => onChangePrefecture(v)}
                                                options={[]}
                                                value={formData.prefecture}
                                                placeholder={"Type tag name and press enter..."}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="label mb-2">Available Vehicle Types:</label>
                                        <div className="col-sm-12">
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isMulti
                                                value={selectedVehicleTypes}
                                                options={vehicleTypeOptions}
                                                onChange={selectVehicleTypeHandler}
                                                placeholder="Available Vehicle Type(s)"
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="label mb-2">Lon:</label>
                                        <div className="col-sm-12">
                                            <input
                                                type="number"
                                                className="form-control form-control-admin"
                                                id="name"
                                                placeholder="Enter Longitude"
                                                name="lon"
                                                value={lonState}
                                                onChange={(e) => onChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="label mb-2">Lat:</label>
                                        <div className="col-sm-12">
                                            <input
                                                type="number"
                                                className="form-control form-control-admin"
                                                id="name"
                                                placeholder="Enter Latitude"
                                                name="lat"
                                                value={latState}
                                                onChange={(e) => onChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}>
                                            <GoogleMap
                                                mapContainerStyle={{ height: "400px", width: "100%" }}
                                                center={{ lat: 35.682846, lng: 139.769026 }}
                                                zoom={13}
                                                onClick={onMapClick}
                                            >
                                                {marker && <Marker position={marker} />}
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>

                                    <div className="mb-3">
                                        <div className="col-sm-12">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexSwitchCheckDefault"
                                                checked={isActiveState}
                                                name="isActive"
                                                onChange={(e) => {
                                                    setIsActiveState(!isActiveState);
                                                    setFormData({ ...formData, isActive: !isActiveState });
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="isActive">Active</label>
                                        </div>
                                        <div className="col-sm-12">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="defaultGarage"
                                                name="defaultGarage"
                                                checked={defaultGarageState}
                                                onChange={(e) => {
                                                    setDefaultGarageState(!defaultGarageState);
                                                    setFormData({ ...formData, isActive: !defaultGarageState });
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="defaultGarage">Default Garage</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="col-sm-offset-2 col-sm-10">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => onFormSubmit(e)}
                                    >
                                        <i
                                            className="fa fa-plus-circle fa-fw"
                                            aria-hidden="true"
                                        ></i>{" "}
                                        Add Garage
                                    </button>
                                </div>
                            </div>

                            {/* </form> */}
                        </div>
                    </div>
                </div>
                <Alert />
            </main>
        </Layout>
    );
};

export default AddGarage;
