import React from "react";
import { Link } from "react-router-dom";
// import Moment from "react-moment";
import Alphard from "../../../images/alphard-new23.png";
import GC from "../../../images/hiace-grand-new23.png";
import LC from "../../../images/hiace-luxury-new23.png";
import Granace from "../../../images/granace-new23.png";
import BenzVClass from "../../../images/benz-v-class23.jpg";

const VehicleTypeItem = ({
  vehicletypes: {
    _id,
    typename,
    hourlyprice,
    vehicletypeimage,
    maxpassenger,
    maxluggages,
    date,
  },
  count,
}) => {
  var imageURi = null;

  if (vehicletypeimage === "Alphard") imageURi = Alphard;
  if (vehicletypeimage === "Granace") imageURi = Granace;
  if (vehicletypeimage === "Luxury-Cabin") imageURi = LC;
  if (vehicletypeimage === "Grand-Cabin") imageURi = GC;
  if (vehicletypeimage === "Benz V Class") imageURi = BenzVClass;
  return (
    <div className="col-sm-3 my-3">
      <div className="card custom-card h-100">
        <img src={imageURi} className="card-img-top custom-border-top px-4 h-100" alt="..." />
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{typename}</h5>
          <p className="card-text">
            30 Minutes Rate : {hourlyprice} | Max Passengers : {maxpassenger} |
            Max luggages : {maxluggages}
          </p>
          <div className="mt-auto">
            <Link
              type="button"
              className="btn btn-secondary"
              to={`/admin/vehicletypelist/edit/${_id}`}
            >
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
    // <tr>
    //   <td>{count + 1}</td>
    //   <td>{typename}</td>
    //   <td>{hourlyprice}</td>
    //   <td>{vehicletypeimage}</td>
    //   <td>{maxpassenger}</td>
    //   <td>{maxluggages}</td>
    //   <td>
    //     <Moment format="YYYY/MM/DD">{date}</Moment>
    //   </td>
    //   <td>
    //     <Link
    //       type="button"
    //       className="btn btn-secondary btn-sm"
    //       to={`/admin/vehicletypelist/edit/${_id}`}
    //     >
    //       Edit
    //     </Link>
    //   </td>
    // </tr>
  );
};

export default VehicleTypeItem;
