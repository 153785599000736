import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import Layout from "../../layout/Layout";
import Alert from "../../layout/Alert";
import TitleBar from "../../layout/TitleBar";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCMSPageContent,
  getPageContents,
} from "../../../actions/admin/cmspage";

const CMSCart = () => {
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage } = cmspage_state;
  const page_name = "cms-cart";
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    pagename: page_name,
    page_content_en: {
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
    },
    page_content_jp: {
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
    },
  });

  useEffect(() => {
    if (cmspage?.pagename === page_name) setFormData(cmspage);
  }, [cmspage]);

  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  const onChangeEn = (e) =>
    setFormData({
      ...formData,
      page_content_en: {
        ...formData.page_content_en,
        [e.target.name]: e.target.value,
      },
    });

  const onChangeJp = (e) =>
    setFormData({
      ...formData,
      page_content_jp: {
        ...formData.page_content_jp,
        [e.target.name]: e.target.value,
      },
    });

  const onUpdateData = (e) => {
    e.preventDefault();
    dispatch(updateCMSPageContent(formData));
  };

  return (
    <LoadingOverlay active={false} spinner={<ScaleLoader color="#36D7B7" />}>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="CMS | cart" />
          <div className="container-fluid">
            <form onSubmit={(e) => {}}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label mb-2">1. Page Title (en)</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content1"
                        value={formData?.page_content_en.content1}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      2. "Booking Expires in" Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content2"
                        value={formData?.page_content_en.content2}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      3. Button - Add Another Booking Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content3"
                        value={formData?.page_content_en.content3}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      4. Side Card (Price Breakdown) - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content4"
                        value={formData?.page_content_en.content4}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      5. Side Card (Price Breakdown) - Items Total Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content5"
                        value={formData?.page_content_en.content5}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      6. Side Card (Price Breakdown) - Tax Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content6"
                        value={formData?.page_content_en.content6}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      7. Side Card (Price Breakdown) - Total Price Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content7"
                        value={formData?.page_content_en.content7}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      8. Side Card (Price Breakdown) - Terms & Conditions
                      Agreement Statement
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content8"
                        value={formData?.page_content_en.content8}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      9. Side Card (Price Breakdown) - Begin Checkout - Button
                      Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content9"
                        value={formData?.page_content_en.content9}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      10. Side Card (Price Breakdown) - Payment Methods Accepted
                      Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content10"
                        value={formData?.page_content_en.content10}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  {/* second cards */}
                </div>

                {/*  */}
                <div className="col-md-6 bg-light">
                  <div className="mb-3">
                    <label className="label mb-2">Page Title (jp)</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content1"
                        value={formData?.page_content_jp.content1}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      "Booking Expires in" Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content2"
                        value={formData?.page_content_jp.content2}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Button - Add Another Booking Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content3"
                        value={formData?.page_content_jp.content3}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content4"
                        value={formData?.page_content_jp.content4}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Items Total Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content5"
                        value={formData?.page_content_jp.content5}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Tax Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content6"
                        value={formData?.page_content_jp.content6}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Total Price Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content7"
                        value={formData?.page_content_jp.content7}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Terms & Conditions Agreement
                      Statement
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content8"
                        value={formData?.page_content_jp.content8}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Begin Checkout - Button Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content9"
                        value={formData?.page_content_jp.content9}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Side Card (Price Breakdown) - Payment Methods Accepted
                      Text
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content10"
                        value={formData?.page_content_jp.content10}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={onUpdateData}
                      >
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update content
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </form>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default CMSCart;
