import React from "react";
import { Link } from "react-router-dom";

const CategoryItem = ({ deleteCategory, category, count }) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td >{category.categoryname}</td>
      <td className="px-0" style={{width:"6%"}}>
        <Link
          type="button"
          className="btn btn-secondary py-1 px-3"
          to={`/admin/categorylist/edit/${category._id}`}
        >
          Edit
        </Link>
      </td>
      <td className="ps-1" style={{width:"5%"}}>
        <button
          type="button"
          className="btn btn-outline-danger py-1 px-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            deleteCategory(category._id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default CategoryItem;
