import {
  PAGE_CONTENT_ERROR,
  ADD_PAGE_CONTENT,
  GET_PAGE_CONTENT,
  ON_GET_PAGE_CONTENT
} from "../../actions/types";

const initialState = {
  cmspage: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ON_GET_PAGE_CONTENT:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGE_CONTENT:
      return {
        ...state,
        cmspage: payload,
        loading: false,
      };
    case ADD_PAGE_CONTENT:
      return {
        ...state,
        cmspage: payload,
        loading: false,
      };
    case PAGE_CONTENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
