import React, { useState, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import { addVehicle, getVehicle } from "../../../actions/admin/vehicle";
import Alert from "../../layout/Alert";
// import { vehicleTypeOptions } from "../../../utils/dataLists";
import { useDispatch, useSelector } from "react-redux";
// import LoadingSpinner from "../../common/LoadingSpinner";
import Spinner from "../../layout/Spinner";
import { getAllVehicleTypes } from "../../../actions/admin/vehicletype";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";

const UpdateVehicle = ({ match, history }) => {
  const dispatch = useDispatch();
  const [isloading, setIsloading] = useState(false);
  const vehicleState = useSelector((state) => state.vehicle);
  const { loading, vehicle } = vehicleState;
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);

  // const vehicleTypeOptions = vehicletypes.map((d) => ({
  //   value: d._id,
  //   label: d.typename,
  // }));

  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    _id: "",
    vehiclename: "",
    vehicletype: "",
    registration: "",
    isactive: false,
  });
  const { vehiclename, registration, isactive } = formData;

  useEffect(() => {
    dispatch(getVehicle(match.params.id));
    fetchVehicleTypes(callbackfunck);
  }, [dispatch, match.params.id]);

  const callbackfunck = (e_data) => {
    setVehicleTypeOptions(e_data);
  };

  const fetchVehicleTypes = async (callback) => {
    setIsloading(true);
    const vt_data = await getAllVehicleTypes();
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.typename,
      };
    });
    setIsloading(false);
    callback(data);
  };

  useEffect(() => {
    const opt = vehicleTypeOptions.find(
      (op) => op.label === vehicle?.vehicletype
    );

    // console.log(opt);
    setFormData({
      _id: loading || !vehicle?._id ? "" : vehicle?._id,
      vehiclename: loading || !vehicle?.vehiclename ? "" : vehicle?.vehiclename,
      vehicletype: opt?.label,
      registration:
        loading || !vehicle?.registration ? "" : vehicle?.registration,
      isactive: loading || !vehicle?.isactive ? false : vehicle?.isactive,
    });
    // console.log(opt);
    setSelectedOption(opt);
  }, [vehicleTypeOptions, loading, vehicle]);

  //console.log(selectedOption);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFormSubmit = (e) => {
    e.preventDefault();
    // setFormData({ ...formData, vehicletype: selectedOption.label });
    dispatch(addVehicle(formData, history, true));
    // setFormData({
    //   ...formData,
    //   vehiclename: "",
    //   registration: "",
    //   isactive: false,
    // });
    //console.log(selectedOption);
    // console.log(formData);
  };

  //   const setOption = (e) => {
  //     //setFormData({ ...formData, vehicletype: e.label });
  //     setSelectedOption();
  //   };
  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Update Vehicle" />
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <form onSubmit={(e) => onFormSubmit(e)}>
                      <div className="mb-3">
                        <div className="col-sm-offset-2 col-sm-10">
                          <Link
                            className="btn btn-secondary"
                            to="/admin/vehiclelist"
                          >
                            <i
                              className="fa fa-arrow-left fa-fw"
                              aria-hidden="true"
                            ></i>{" "}
                            Back To List
                          </Link>
                        </div>
                      </div>

                      <div className="card mb-3">
                        <div className="card-header">Vehicle status</div>
                        <div className="card-body">
                          <div className="mb-3">
                            <label className="form-check-label form-check-label-admin">
                              Vehicle is active:
                            </label>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isactive}
                                name="isactive"
                                onChange={(e) => {
                                  setFormData({ ...formData, isactive: !isactive });
                                }}
                              />

                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="card mb-3">
                        <div className="card-header">Vehicle details</div>
                        <div className="card-body">

                          <div className="mb-3">
                            <label className="label mb-2">Vehicle Name:</label>
                            <div className="col-sm-12">
                              <input
                                type="text"
                                className="form-control form-control-admin"
                                id="name"
                                placeholder="Enter name"
                                name="vehiclename"
                                value={vehiclename}
                                onChange={(e) => onChange(e)}
                                required
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <label className="label mb-2">Vehicle Type:</label>
                            <div className="col-sm-12">
                              <Select
                                className="basic-multi-select"
                                classNamePrefix="select pick-up location"
                                value={selectedOption}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    vehicletype: e.label,
                                  });
                                  setSelectedOption(e);
                                }}
                                options={vehicleTypeOptions}
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <label className="label mb-2">
                              Vehicle Registration:
                            </label>
                            <div className="col-sm-12">
                              <input
                                type="text"
                                className="form-control form-control-admin"
                                id="reg"
                                placeholder="Enter Vehicle Registration"
                                name="registration"
                                value={registration}
                                onChange={(e) => onChange(e)}
                                required
                              />
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="col-sm-offset-2 col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            <i
                              className="fa fa-plus-circle fa-fw"
                              aria-hidden="true"
                            ></i>{" "}
                            Update
                          </button>{" "}

                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default withRouter(UpdateVehicle);
