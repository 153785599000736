import React, { Fragment, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getAllUsersAdmin } from "../../../actions/auth";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";
import AdminUserItem from "./AdminUseritem";

const AdminUserList = () => {
  const auth = useSelector((state) => state.auth);
  const { loading, users, user } = auth;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsersAdmin());
  }, [dispatch]);

  return (
    <Fragment>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Users List" />
          <h4 className="my-4">Available Admin Users</h4>
          <div>
            {loading && !users ? (
              <Spinner />
            ) : (
              <Fragment>
                <table className="table table-hover table-sm booking-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Created Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((u, i = 0) => (
                      <AdminUserItem
                        key={u._id}
                        user={u}
                        currentUser={user}
                        count={i++}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default AdminUserList;
