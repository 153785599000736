import React from "react";
import { Link } from "react-router-dom";

const AirportItem = ({ deleteAirport, airport, count }) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>{airport.airportname}</td>
      <td>{airport.airport.label}</td>
      <td>{airport.city}</td>
      <td>
        {airport.isactive ? (
          <span className="badge bg-success py-2">Active</span>
        ) : (
          <span className="badge bg-secondary py-2">Inactive</span>
        )}
      </td>
      <td className="px-0">
        <Link
          type="button"
          className="btn btn-secondary py-1 px-3"
          to={`/admin/airportlist/edit/${airport._id}`}
        >
          Edit
        </Link>
      </td>
      <td className="ps-1">
        <button
          type="button"
          className="btn btn-outline-danger py-1 px-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            deleteAirport(airport._id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default AirportItem;
