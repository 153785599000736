import React, { Fragment, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getVehicleTypes } from "../../../actions/admin/vehicletype";
import VehicleTypeItem from "./VehicleTypeItem";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";

const VehicleTypeList = () => {
  const vehicleType = useSelector((state) => state.vehicletype);
  const { loading, vehicletypes } = vehicleType;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getVehicleTypes());
  }, [dispatch]);

  return (
    <Fragment>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Vehicle Type List" />
          <h4>All available Vehicle Types</h4>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <div className="container-fluid">
                  <div className="row">
                    {vehicletypes.map((vt, i = 0) => (
                      <VehicleTypeItem
                        key={vt._id}
                        vehicletypes={vt}
                        count={i++}
                      />
                    ))}
                  </div>
                </div>

                {/* <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Type Name</th>
                      <th scope="col">Hourly Price</th>
                      <th scope="col">Image Name</th>
                      <th scope="col">Maximum Passengers</th>
                      <th scope="col">Maximum Luggages</th>
                      <th scope="col">Created Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicletypes.map((vt, i = 0) => (
                      <VehicleTypeItem
                        key={vt._id}
                        vehicletypes={vt}
                        count={i++}
                      />
                    ))}
                  </tbody>
                </table> */}
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default VehicleTypeList;
