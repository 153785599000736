import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleDestinationFormToState,
  addMultipleDestinationFormToState,
  addToCartReduxStore,
  addAirportFormToState,
  addByTheHourFormToState
} from "../../../actions/admin/booking";
import {
  getObjectElementSum,
  checkTokyoAvailability,
  checkTokyoAvailabilitySingleForm,
  getTravelDateWithPickupTime,
  setSpecialPackage,
  getTotal,
  isDateInMultiplierZone,
  getKey,
  getImage,
  getTotalPaxCount,
  getTotalLuggageCount,
  getTravelDateWithDropoffTime,
} from "../../../utils/Calculator";
import {
  removeAllCartItem,
  addBooking,
  getAvailableVehicleTypes,
  getAvailableVehicles,
  deleteCartItem,
} from "../../../actions/admin/booking";
import moment from "../../../utils/customMoment";
// import moment from "moment";
import { isAfter } from "date-fns";
import { getRouteCar } from "../../../actions/navitimeAPI";
import { getSetting } from "../../../actions/admin/settings";
import VehicleTypeCard from "../../layout-new-theme/cards/VehicleTypeCard";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import { withAlert, types } from "react-alert";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useHistory } from "react-router-dom";
import { filterNewPackage } from "../../../actions/admin/package";
import toyota_granace from "../../../images/toyota_granace.png";
import { ColorRing } from "react-loader-spinner";
import Layout from "../../layout/Layout";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import { bookingStatusOptions } from "../../../utils/dataLists";
import Spinner from "../../layout/Spinner";
import { setAlert } from "../../../actions/alert";
import Alert from "../../layout/Alert";
import ByTheHourForm from "../../layout-new-theme/form-card/ByTheHourForm";

const CreateBookingNew = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const listRef = useRef(null);
  // const refScrollToSearchResult = useRef(null);
  const [tabName, setTabName] = useState("airport");
  const { singleFormData, multiFormData, airportFormData, byTheHourFormData, cart } = useSelector(
    (state) => state.booking
  );
  const settings = useSelector((state) => state.setting);
  const { setting, loading } = settings;
  const [isloading, setIsloading] = useState(false);

  const [newtravelDate, setNewTravelDate] = useState(null);

  const [vehicleTypeNames, setVehicleTypeNames] = useState([]);
  const [garageArivalTime, setgarageArivalTime] = useState("");
  const [tripStartTime, setTripStartTime] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [tollFare, setTollFare] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [cutomerChangedDropOffTimeOnly, setcutomerChangedDropOffTimeOnly] =
    useState("");
  const [cutomerActualDropOffTime, setcutomerActualDropOffTime] =
    useState(null);
  const [
    cutomerDropOffTimeWithAdditinalTime,
    setCutomerDropOffTimeWithAdditinalTime,
  ] = useState(null);
  const [
    garageArivalTimeWithAdditionalTime,
    setGarageArivalTimeWithAdditionalTime,
  ] = useState(null);
  const [activatedPackages, setActivatedPackages] = useState([]);
  const [packageList, setPackageList] = useState([]);

  const [clickedVehicleType, setClickedVehicleType] = useState("");
  const [bookingAmountInfo, setBookingAmountInfo] = useState({
    totalTime: 0,
    baseprice: 0,
    hourlyprice: 0,
    tollFare: 0,
    isTimeRange: null,
    total: 0,
    vehicleTypeName: null,
    vehicleTypeID: null,
    tripStartTime: null,
    garageArivalTime: null,
    garageArivalTimeWithAdditionalTime: null,
    cutomerActualDropOffTime: null,
    cutomerDropOffTimeWithAdditinalTime: null,
    zoneHours: 0,
    totalhours: 0,
  });
  const [packageAdditionaltime, setPackageAdditionaltime] = useState(0);
  const [showDetailsPannels, setShowDetailsPannels] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [sfd, setSfd] = useState({
    pickuplocation: null,
    dropofflocations: [],
    traveldate: "",
    pickuptime: "",
    dropofftime: "",
    pax: null,
    flightNo: "",
    isFromAirport: true,
  });
  const [note, setNote] = useState("");
  const [cartID, setCartID] = useState(null);
  const [carRoute, setCarRoute] = useState(null);
  const [checkoutDisable, setCheckoutDisable] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [nameval, setNameval] = useState("");
  const [emailval, setEmailval] = useState("");
  const [phoneval, setPhoneval] = useState("");
  const [addressval, setAddressval] = useState("");
  const [bookingStatusval, setBookingStatusval] = useState("#ced4da");
  const [bookingStatus, setBookingStatus] = useState(null);

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  useEffect(() => {
    if (cart?.length === 0 || cart === null) return;
    setCartID(cart[0].randomId);
  }, [cart]);

  useEffect(() => {
    if (packageList.length === 0) return;
    setActivatedPackages([packageList[0]]);
  }, [packageList]);

  const setDisableButton = (status) => {
    setCheckoutDisable(status);
  };

  // test comment
  const resetFormValues = () => {
    setShowDetailsPannels(false);
    setSfd({
      pickuplocation: null,
      dropofflocations: [],
      traveldate: "",
      pickuptime: "",
      dropofftime: "",
      pax: null,
      flightNo: "",
      isFromAirport: true,
    });
    setcutomerChangedDropOffTimeOnly("");
  };

  const sigleFormAvailableVehicle = (singleFormData) => {
    setShowDetailsPannels(true);
    setTabName("single");
    setSfd(singleFormData);
    dispatch(addAirportFormToState(null));
    dispatch(addSingleDestinationFormToState(singleFormData));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addByTheHourFormToState(null));
    checkAvailability(singleFormData);
  };

  const multiFormAvailableVehicle = (multiFormData) => {
    setShowDetailsPannels(true);
    setTabName("multi");
    setSfd(multiFormData);
    dispatch(addAirportFormToState(null));
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(multiFormData));
    dispatch(addByTheHourFormToState(null));
    checkAvailability(multiFormData);
  };

  const airportFormAvailableVehicle = (airportFormData) => {
    setShowDetailsPannels(true);
    setTabName("airport");
    setSfd(airportFormData);
    dispatch(addAirportFormToState(airportFormData));
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addByTheHourFormToState(null));
    checkAvailability(airportFormData);
    // console.log("packageList-----------------------------------");
    // console.log(packageList);
    // setActivatedPackages(packageList.length > 0 ? packageList[0] : null);
  };

  const byTheHourFormAvailableVehicle = (byTheHourFormData) => {
    setShowDetailsPannels(true);
    setTabName("byTheHour");
    setSfd(byTheHourFormData);
    dispatch(addAirportFormToState(null));
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addByTheHourFormToState(byTheHourFormData));
    dispatch(addMultipleDestinationFormToState(null));
    checkAvailability(byTheHourFormData);
  };

  useEffect(() => {
    if (!settings) dispatch(getSetting());
    if (singleFormData) {
      sigleFormAvailableVehicle(singleFormData);
    } else if (multiFormData) {
      multiFormAvailableVehicle(multiFormData);
    } else if (airportFormData) {
      airportFormAvailableVehicle(airportFormData);
    } else if (byTheHourFormData) {
      byTheHourFormAvailableVehicle(byTheHourFormData);
    }
  }, [multiFormData, singleFormData, airportFormData, byTheHourFormData]);

  const availabilityValueReset = () => {
    setShowDetailsPannels(false);
    setActivatedPackages([]);
    setPackageList([]);
    setClickedVehicleType(null);
    setSelectedVehicleType(null);
    setVehicleTypeNames([]);
    setBookingAmountInfo({
      totalTime: 0,
      baseprice: 0,
      hourlyprice: 0,
      tollFare: 0,
      isTimeRange: null,
      total: 0,
      vehicleTypeName: null,
      vehicleTypeID: null,
      tripStartTime: null,
      garageArivalTime: null,
      garageArivalTimeWithAdditionalTime: null,
      cutomerActualDropOffTime: null,
      cutomerDropOffTimeWithAdditinalTime: null,
      zoneHours: 0,
      totalhours: 0,
    });
  };

  const checkAvailability = async (sfd) => {
    setIsloading(true);
    availabilityValueReset();
    // formValidation();
    console.log("checkAvailability");
    const buffer_time = setting.buffertime !== null ? setting.buffertime : 0;
    console.log("buffer_time");
    console.log(buffer_time);

    // const locality =
    //   airportFormData.dropofflocations[0].address_components.find(
    //     (c) => c.types[0] === "locality"
    //   ).long_name;
    // console.log("locality");
    // console.log(locality);
    // setIsloading(false);
    // return;

    const totalPax = getTotalPaxCount(sfd.pax);
    if (
      !sfd.traveldate ||
      !sfd.pickuptime ||
      !sfd.pickuplocation ||
      sfd.dropofflocations.length === 0 ||
      totalPax === 0
    ) {
      // alert.show(
      //   t("Please fill in all the required fields (highlighted in red)."),
      //   { type: types.ERROR }
      // );
      dispatch(
        setAlert(
          t("Please fill in all the required fields (highlighted in red)."),
          "danger"
        )
      );
      setIsloading(false);
    } else {
      setVehicleTypeNames([]);
      setgarageArivalTime(null);

      var hasTokyo = checkTokyoAvailability(
        sfd.pickuplocation,
        sfd.dropofflocations
      );

      console.log("sfd-------");
      console.log(sfd);
      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);

      setNewTravelDate(travel_date_with_pickup_time);

      // Check booking is violating buffer time YYYY-MM-DD HH:mm

      // const ddd = moment(new Date())
      //   .format("YYYY-MM-DD HH:mm")
      //   .add(buffer_time, "hours");
      // console.log(ddd);
      const date_with_buffer_time = moment()
        .tz("Asia/Tokyo")
        .add(buffer_time, "hours");
      // .format("YYYY-MM-DD HH:mm");

      console.log("travel_date_with_pickup_time");
      console.log(travel_date_with_pickup_time._d);
      console.log("date_with_buffer_time");
      console.log(date_with_buffer_time._d);

      // var isafter = travel_date_with_pickup_time.isAfter(date_with_buffer_time); //false
      // console.log("bool1");
      // console.log(isafter);

      var isafter = isAfter(
        travel_date_with_pickup_time._d,
        date_with_buffer_time._d
      );

      if (hasTokyo === false) {
        // alert.show(t("Your pick-up or drop off point must be within Tokyo."), {
        //   type: types.INFO,
        // });
        dispatch(
          setAlert(
            t("Your pick-up or drop off point must be within Tokyo."),
            "danger"
          )
        );
      } else if (isafter === false) {
        // alert.show(
        //   t("Your pick-up time should be") +
        //     " " +
        //     setting.buffertime +
        //     " " +
        //     t("hours from now"),
        //   {
        //     type: types.INFO,
        //   }
        // );
        dispatch(
          setAlert(
            t("Your pick-up time should be") +
              " " +
              setting.buffertime +
              " " +
              t("hours from now"),
            "danger"
          )
        );
      } else {
        const via = getViaList(sfd);
        const travel_date_with_format = travel_date_with_pickup_time.format(
          "YYYY-MM-DDThh:mm:ss"
        );
        const car_r = await getRouteCar(
          "35.6235029,139.7410879",
          via,
          travel_date_with_format
        );

        // set to session
        setCarRoute(car_r);
      }
    }
    setIsloading(false);
  };

  // console.log(cutomerChangedDropOffTimeOnly);

  useEffect(() => {
    if (carRoute === null) return;
    if (carRoute[0].response != null) {
      const status = carRoute[0].response.status;
      const statusTest = carRoute[0].response.statusText;
      const erMsg =
        "Code :" +
        status +
        " | Message : " +
        statusTest +
        " | Navtime request limit exceeded.";
      console.log(erMsg);
      dispatch(setAlert(erMsg, "danger"));
      // alert.show(erMsg, { type: types.ERROR });
      setIsloading(false);
      return;
    }

    //  set navtime data to state here
    var efdt = null;
    if (carRoute.length > 0) {
      const {
        trip_start_time,
        distance,
        fare,
        total_time,
        estimated_final_dropoff_time,
        estimated_customer_final_dropoff_time,
        tdwdt,
        gatime,
      } = getSelectedRouteData(carRoute, sfd);

      //  time must be less tnah 16 hors todo
      console.log(setting.totaltriptime);
      console.log(total_time);
      if (Number(total_time / 60) > Number(setting.totaltriptime)) {
        // alert.show(
        //   "For bookings longer than " +
        //     setting.totaltriptime +
        //     " hours, please contact our sales team.",
        //   {
        //     type: types.ERROR,
        //   }
        // );
        dispatch(
          setAlert(
            "For bookings longer than " +
              setting.totaltriptime +
              " hours, please contact our sales team.",
            "danger"
          )
        );
        setIsloading(false);
        return;
      }

      efdt = estimated_final_dropoff_time;
      setTripStartTime(trip_start_time._d);
      setTotalDistance(distance);
      setTollFare(fare);
      setTotalTime(total_time);

      // set cus chhanged time// nothig to chage here. vvalues will be set in top funtion
      setcutomerChangedDropOffTimeOnly(
        estimated_customer_final_dropoff_time.format("HH:mm")
      );
      console.log("setcutomerActualDropOffTime");
      console.log(tdwdt);
      setcutomerActualDropOffTime(tdwdt);
      setCutomerDropOffTimeWithAdditinalTime(tdwdt);
      setGarageArivalTimeWithAdditionalTime(gatime);
      setgarageArivalTime(gatime);

      const totallug = getTotalLuggageCount(sfd.pax);
      const totalPax = getTotalPaxCount(sfd.pax);

      // get available vehicle types
      setVehicleTypeArray(trip_start_time, efdt, totalPax, totallug);
      // refScrollToSearchResult.current?.scrollIntoView({ behavior: "smooth" });
      const element = document.getElementById("scroll01");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [carRoute]);

  const setVehicleTypeArray = async (
    trip_start_time,
    efdt,
    totalPax,
    totallug
  ) => {
    const v_types_arr = await getAvailableVehicleTypes(
      trip_start_time._d.toString(),
      efdt._d.toString(),
      totalPax,
      totallug
    );

    if (v_types_arr.length === 0) {
      alert.show("We have no available vehicles on the selected day.", {
        type: types.INFO,
      });
      return;
    }

    console.log("airportFormData>>>>>>>>>>>>>>>>>>");
    console.log(airportFormData);
    if (airportFormData === null) {
      setVehicleTypeNames(v_types_arr);
      setShowDetailsPannels(true);
    } else {
      var locality = "";
      var airportid = "";
      console.log("if (airportFormData.isFromAirport !== null) {");
      console.log(airportFormData.isFromAirport);
      if (
        airportFormData.isFromAirport !== null &&
        airportFormData.isFromAirport === false
      ) {
        airportid = airportFormData.dropofflocations[0].value;
        locality = airportFormData.pickuplocation.address_components
          .find((c) => c.types[0].toLowerCase() === "locality")
          .long_name.toLowerCase();
      } else {
        airportid = airportFormData.pickuplocation.value;
        locality = airportFormData.dropofflocations[0].address_components
          .find((c) => c.types[0].toLowerCase() === "locality")
          .long_name.toLowerCase();
      }
      console.log("locality");
      console.log(locality);
      // airport pkg acticated.. addttional step added.
      const pkges = await filterNewPackage(
        airportid,
        // airportFormData.dropofflocations[0].city,
        locality,
        airportFormData.isFromAirport
      );
      setPackageList(pkges);
      // setActivatedPackages(pkges);
      setVehicleTypeNames(v_types_arr);
      setShowDetailsPannels(true);
      // listRef.current?.lastElementChild?.scrollIntoView();
      // todo
      // const element = document.getElementById("scroll01");
      // if (element) {
      //   element.scrollIntoView({ behavior: "smooth" });
      // }
    }
  };

  // console.log(activatedPackages);

  const getSelectedRouteData = (car_r, sfd) => {
    const { travel_date_with_pickup_time, p_h, p_m } =
      getTravelDateWithPickupTime(sfd);
    const { travel_date_with_dropoff_time_initial } =
      getTravelDateWithDropoffTime(sfd);

    const summary_arr = car_r[car_r.length - 1].summary;
    const sections_arr = car_r[car_r.length - 1].sections;
    const garage_to_pickup_time = sections_arr[1].time;

    const section = car_r[car_r.length - 1].sections;
    const section_length = section.length;
    const final_drop_off_section = section[section_length - 2];

    // total_time : add aditional time - user added aditinal drop off time here
    // const total_time = summary_arr.move.time + userDdedAditionalTime;
    const total_time = summary_arr.move.time;
    var newtime = travel_date_with_pickup_time;
    var estimated_final_dropoff_time = moment(newtime).add(
      total_time,
      "minutes"
    );

    // trip star time no changes
    var trip_start_time = travel_date_with_pickup_time.subtract(
      garage_to_pickup_time,
      "minutes"
    );

    const distance = summary_arr.move.distance;
    if (summary_arr.move.fare != null) {
      var fare = summary_arr.move.fare.unit_1025_2
        ? summary_arr.move.fare.unit_1025_2
        : 0;
    } else {
      fare = 0;
    }

    // const section = car_r[car_r.length - 1].sections;
    // const section_length = section.length;
    // const final_drop_off_section = section[section_length - 2];

    const final_Drop_to_garrage_time_in_minutes = Number(
      final_drop_off_section.time
    );
    var estimated_customer_final_dropoff_time = moment(newtime).add(
      total_time - final_Drop_to_garrage_time_in_minutes,
      "minutes"
    );

    // isAfterEstimatedTime = true = > customer drop off time is grater than estimated time
    const isCustomerDropOffAfterEstimatedTime =
      travel_date_with_dropoff_time_initial.isAfter(
        estimated_customer_final_dropoff_time
      );

    var userDdedAditionalTime = isCustomerDropOffAfterEstimatedTime
      ? travel_date_with_dropoff_time_initial.diff(
          estimated_customer_final_dropoff_time,
          "minutes"
        )
      : 0;

    estimated_final_dropoff_time = moment(newtime).add(
      total_time + userDdedAditionalTime,
      "minutes"
    );

    if (isCustomerDropOffAfterEstimatedTime) {
      estimated_customer_final_dropoff_time = moment(newtime).add(
        total_time +
          userDdedAditionalTime -
          final_Drop_to_garrage_time_in_minutes,
        "minutes"
      );
    }

    console.log("cutomerChangedDropOffTimeOnly-------------------");
    console.log(cutomerChangedDropOffTimeOnly);
    console.log(sfd);
    console.log("travel_date_with_dropoff_time_initial");
    console.log(travel_date_with_dropoff_time_initial);
    // console.log("estimated_customer_final_dropoff_time");
    // console.log(estimated_customer_final_dropoff_time);

    const ecfdt = estimated_customer_final_dropoff_time.format("HH:mm:ss");
    var d_arr = ecfdt.split(":");
    var d_h = Number(d_arr[0]);
    var d_m = Number(d_arr[1]);

    var travel_date_with_dropoff_time = moment(newtravelDate).set({
      hour: d_h,
      minute: d_m,
    });
    console.log("travel_date_with_dropoff_time");
    console.log(travel_date_with_dropoff_time);
    const tdwdt = travel_date_with_dropoff_time._d.toString();
    console.log("tdwdt");
    console.log(tdwdt);
    const gatime = estimated_final_dropoff_time._d.toString();

    return {
      trip_start_time,
      distance,
      fare,
      total_time,
      estimated_final_dropoff_time,
      estimated_customer_final_dropoff_time,
      tdwdt,
      gatime,
    };
  };

  const getViaList = (sfd) => {
    console.log("------------------sfd-----------------");
    console.log(sfd);
    var via = [];
    var pickip_p_obj = {
      lat: sfd.pickuplocation.lat,
      lon: sfd.pickuplocation.lon,
    };
    via.push(pickip_p_obj);

    sfd.dropofflocations.map((d) => {
      var des_obj = {
        lat: d.lat,
        lon: d.lon,
      };
      via.push(des_obj);
    });
    return JSON.stringify(via);
  };

  const changeCustomerDropOffValue = (val) => {
    var d_time = moment(cutomerActualDropOffTime).format("HH:mm:ss");
    var d_arr = d_time.split(":");
    var et_h = Number(d_arr[0]);
    // customer added time split
    var c_added_arr = val.split(":");
    var c_d_h = Number(c_added_arr[0]);
    var c_d_m = Number(c_added_arr[1]);

    if (et_h <= c_d_h) {
      var customer_changed_dropoff_datetime = moment(
        cutomerActualDropOffTime
      ).set({
        hour: c_d_h,
        minute: c_d_m,
      });
    } else {
      customer_changed_dropoff_datetime = moment(cutomerActualDropOffTime)
        .add(1, "days")
        .set({
          hour: c_d_h,
          minute: c_d_m,
        });
    }
    const customer_additionaly_time = moment.duration(
      moment(customer_changed_dropoff_datetime).diff(
        moment(cutomerActualDropOffTime)
      )
    );
    var customer_additionally_added_minutes =
      customer_additionaly_time.asMinutes();
    const garage_arival_time_with_additinal = moment(garageArivalTime).add(
      customer_additionally_added_minutes,
      "minutes"
    );
    setGarageArivalTimeWithAdditionalTime(garage_arival_time_with_additinal._d);
    const cutomerActualDropOffTime_datetime = moment(cutomerActualDropOffTime);
    var duration = moment.duration(
      customer_changed_dropoff_datetime.diff(cutomerActualDropOffTime_datetime)
    );
    var duration_inminutes = duration.asMinutes();
    var duration_inhours = duration.asHours();

    if (duration_inminutes < 0 || duration_inhours > 16) {
      // user added dropoff time is lesthan actual dropoff time . this must denny
      resetDropoffTimeToEstimatedValue(d_time);
    } else {
      setCutomerDropOffTimeWithAdditinalTime(
        customer_changed_dropoff_datetime._d.toString()
      );
      // update booking info
      onchangeDropOffValueUpdateBookingInfo(
        garage_arival_time_with_additinal._d,
        customer_changed_dropoff_datetime._d
      );
    }
  };

  const onchangeDropOffValueUpdateBookingInfo = (
    grageArivalTimeWithAidtinal,
    cusDropOffWithAditional
  ) => {
    const zoneIncludedHoursRounded = getHoursInsideMultiplierZone(
      tripStartTime,
      grageArivalTimeWithAidtinal
    );
    const totHoursComplete = getTotalHours(grageArivalTimeWithAidtinal);
    const totalAmount = getTotal(
      selectedVehicleType,
      totHoursComplete,
      tollFare,
      zoneIncludedHoursRounded,
      activatedPackages,
      packageAdditionaltime
    ).total;

    // new cost addtitional time todo

    setBookingAmountInfo({
      ...bookingAmountInfo,
      total: totalAmount,
      //new fields
      garageArivalTimeWithAdditionalTime: grageArivalTimeWithAidtinal,
      cutomerDropOffTimeWithAdditinalTime: cusDropOffWithAditional,
      zoneHours: zoneIncludedHoursRounded,
      totalhours: totHoursComplete,
    });
  };

  const resetDropoffTimeToEstimatedValue = (inputValue) => {
    if (typeof inputValue == "undefined" || inputValue === "") {
      return;
    }
    if (window.my_timer1 != null) {
      clearTimeout(window.my_timer1);
    }
    window.my_timer1 = setTimeout(() => {
      window.my_timer1 = null;
      try {
        // alert.show(
        //   t("Dropoff time cannot be less than actual estimated drop off time."),
        //   { type: types.INFO }
        // );
        dispatch(
          setAlert(
            t(
              "Dropoff time cannot be less than actual estimated drop off time."
            ),
            "danger"
          )
        );
        // applyToTheEquation(null, selectedVehicleType);
        setcutomerChangedDropOffTimeOnly(inputValue);
        setGarageArivalTimeWithAdditionalTime(garageArivalTime);
        setCutomerDropOffTimeWithAdditinalTime(cutomerActualDropOffTime);
        // onchangeDropOffValueUpdateBookingInfo(
        //   garageArivalTime,
        //   cutomerActualDropOffTime
        // );
      } catch (error) {}
    }, 2000);
  };

  // retun in minutes
  const getTimeDifferenceIN30MinRounded = async (val) => {
    // todo - not updatin total vale in real time
    try {
      // console.log("object");
      var d_time = moment(cutomerActualDropOffTime).format("HH:mm:ss");
      var d_arr = d_time.split(":");
      var et_h = Number(d_arr[0]);
      // customer added time split
      var c_added_arr = val.split(":");
      var c_d_h = Number(c_added_arr[0]);
      var c_d_m = Number(c_added_arr[1]);

      if (et_h <= c_d_h) {
        var customer_changed_dropoff_datetime = moment(
          cutomerActualDropOffTime
        ).set({
          hour: c_d_h,
          minute: c_d_m,
        });
      } else {
        customer_changed_dropoff_datetime = moment(cutomerActualDropOffTime)
          .add(1, "days")
          .set({
            hour: c_d_h,
            minute: c_d_m,
          });
      }

      // this function
      var duration = moment.duration(
        moment(customer_changed_dropoff_datetime._d).diff(
          moment(cutomerActualDropOffTime)
        )
      );
      var minutes = duration.asMinutes();
      const rounded = Math.ceil(minutes / 30) * 30;
      // return rounded;
      setPackageAdditionaltime(rounded);
    } catch {}
  };

  const getTab = () => {
    if (tabName === "byTheHour")
      return (
        <ByTheHourForm
          cutomerChangedDropOffTimeOnly={cutomerChangedDropOffTimeOnly}
          byTheHourForm={byTheHourFormData}
          checkByTheHourFormAvailableVehicle={byTheHourFormAvailableVehicle}
          setDisableButton={setDisableButton}
          setcutomerChangedDropOffTimeOnly={(val) => {
            setcutomerChangedDropOffTimeOnly(val);
            // setSfd({ ...sfd, dropofftime: val });
          }}
          availabilityValueReset={availabilityValueReset}
        />
      );
  };

  // on click event - when vehicle type clicked
  // hourlyprice = 30 minute rate
  const applyToTheEquation = (e, vehicle_type) => {
    setSelectedVehicleType(vehicle_type);
    const zoneIncludedHoursRounded = getHoursInsideMultiplierZone(
      tripStartTime,
      garageArivalTimeWithAdditionalTime
    );
    // totHoursComplete = total hours - pickup garage - dropoff garage - customer additionally added dropoff time
    const totHoursComplete = getTotalHours(garageArivalTimeWithAdditionalTime);
    const totalAmount = getTotal(
      vehicle_type,
      totHoursComplete,
      tollFare,
      zoneIncludedHoursRounded,
      activatedPackages,
      packageAdditionaltime
    ).total;
    // -------------------------------------------------------------------------------------------------------------------------

    setClickedVehicleType(vehicle_type.typename);

    console.log("cutomerActualDropOffTime");
    console.log(cutomerActualDropOffTime);
    console.log("cutomerDropOffTimeWithAdditinalTime");
    console.log(cutomerDropOffTimeWithAdditinalTime);

    setBookingAmountInfo({
      totalTime,
      baseprice: vehicle_type.baseprice,
      hourlyprice: vehicle_type.hourlyprice,
      tollFare,
      isTimeRange: null,
      total: totalAmount,
      //new fields
      vehicleTypeName: vehicle_type.typename,
      vehicleTypeID: vehicle_type._id,
      tripStartTime: tripStartTime,
      garageArivalTime: garageArivalTime,
      garageArivalTimeWithAdditionalTime: garageArivalTimeWithAdditionalTime,
      cutomerActualDropOffTime: cutomerActualDropOffTime,
      cutomerDropOffTimeWithAdditinalTime: cutomerDropOffTimeWithAdditinalTime,
      zoneHours: zoneIncludedHoursRounded,
      totalhours: totHoursComplete,
    });
  };

  const roundThirtyMinValue = (value) => {
    const inHours = value / 60;
    const onlydecemal = inHours % 1;
    const onlyHour = float2int(inHours);
    var f_val = 0;
    if (onlydecemal > 0 && onlydecemal < 0.5) {
      f_val = onlyHour + 0.5;
    } else if (onlydecemal > 0.5 && onlydecemal < 1) {
      f_val = onlyHour + 1;
    } else if (onlydecemal === 0) {
      f_val = onlyHour;
    } else if (onlydecemal === 0.5) {
      f_val = inHours;
    }
    return f_val;
  };

  const float2int = (value) => {
    return value | 0;
  };

  const getTotalHours = (garageArival) => {
    const totalduration = moment.duration(
      moment(garageArival).diff(moment(tripStartTime))
    );
    var total_minutes = totalduration.asMinutes();
    var additionalTime = (total_minutes / 30) * 5;
    var totalHrs = roundThirtyMinValue(total_minutes + additionalTime);
    return totalHrs;
  };

  const getHoursInsideMultiplierZone = (p_date_time, d_date_time) => {
    if (p_date_time && d_date_time) {
      var zone_minutes = 0;
      var margin_tem_pm = moment(newtravelDate).set({
        hour: 22,
        minute: 0,
      });
      var margin_five_am_drop_off = moment(d_date_time).set({
        hour: 6,
        minute: 0,
      });
      const pickup_datetime = moment(p_date_time.toString());
      const dropoff_datetime = moment(d_date_time.toString());
      const is_pick_in_zone = isDateInMultiplierZone(p_date_time);
      const is_dropoff_in_zone = isDateInMultiplierZone(d_date_time);
      if (is_dropoff_in_zone && !is_pick_in_zone) {
        const dropoff_duration = moment.duration(
          dropoff_datetime.diff(margin_tem_pm)
        );
        zone_minutes = dropoff_duration.asMinutes();
      } else if (!is_dropoff_in_zone && is_pick_in_zone) {
        // ger dropoff date
        const pickup_zone_duration = moment.duration(
          margin_five_am_drop_off.diff(pickup_datetime)
        );
        zone_minutes = pickup_zone_duration.asMinutes();
      } else if (is_dropoff_in_zone && is_pick_in_zone) {
        const both_in_same_zone_duration = moment.duration(
          dropoff_datetime.diff(pickup_datetime)
        );
        zone_minutes = both_in_same_zone_duration.asMinutes();
      } else if (!is_dropoff_in_zone && !is_pick_in_zone) {
        // pickup point and dripoff point not in zone
        const is_pic_and_drop_same_day = pickup_datetime.isSame(
          dropoff_datetime,
          "day"
        );
        // 7 * 60 minutes in zone
        zone_minutes = !is_pic_and_drop_same_day ? 420 : 0;
      }
      // console.log(zone_minutes / 60);
      var roundedValInHours = roundThirtyMinValue(zone_minutes);
    }
    return roundedValInHours;
  };

  const bookingConfirmFormValidation = () => {
    if (!name) setNameval("is-invalid");
    if (!email) setEmailval("is-invalid");
    if (!phone) setPhoneval("is-invalid");
    if (!address) setAddressval("is-invalid");
    if (!bookingStatus) setBookingStatusval("#dc3545");
  };

  const addTocart = async (isCart) => {
    bookingConfirmFormValidation();
    const totalPax = getTotalPaxCount(sfd.pax);
    if (
      !sfd.traveldate ||
      !sfd.pickuptime ||
      !sfd.pickuplocation ||
      sfd.dropofflocations.length === 0 ||
      totalPax === 0 ||
      !name ||
      !email ||
      !phone ||
      !address ||
      !bookingStatus
    ) {
      // alert.show(t("Please fill in all the required fields"), {
      //   type: types.ERROR,
      // });
      dispatch(setAlert(t("Please fill in all the required fields"), "danger"));
      setIsloading(false);
    } else {
      // check total time take
      const trip_total_time = Number(bookingAmountInfo.totalhours);
      if (trip_total_time / 60 > Number(setting.totaltriptime)) {
        // alert.show(
        //   t(
        //     "For bookings longer than 16 hours, please contact our sales team."
        //   ),
        //   {
        //     type: types.ERROR,
        //   }
        // );
        dispatch(
          setAlert(
            t(
              "For bookings longer than 16 hours, please contact our sales team."
            ),
            "danger"
          )
        );
        setIsloading(false);
        return;
      }
      setIsloading(true);
      const available_vehicles = await getAvailableVehicles(
        tripStartTime,
        garageArivalTimeWithAdditionalTime,
        clickedVehicleType
      );

      console.log("available_vehicles");
      console.log(available_vehicles);
      setIsloading(false);
      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);
      const note_arr = [{ note: note }];
      if (available_vehicles && available_vehicles.length > 0) {
        const available_vehicle_id = available_vehicles[0]._id;
        var paxList = [
          {
            paxtype: "Adult",
            paxcount: Number(sfd.pax.adult),
          },
          {
            paxtype: "Child",
            paxcount: Number(sfd.pax.child),
          },
          {
            paxtype: "Toddler",
            paxcount: Number(sfd.pax.toddler),
          },
          {
            paxtype: "Baby",
            paxcount: Number(sfd.pax.baby),
          },
        ];

        var luggagelist = [
          {
            luggagetype: "CHECK-IN SIZE",
            luggagecount: Number(sfd.pax.checkinSize),
          },
          {
            luggagetype: "CABIN SIZE",
            luggagecount: Number(sfd.pax.cabinSize),
          },
        ];

        if (cartID === null) {
          var randomchar =
            Math.floor(Math.random() * 16777215).toString(16) +
            Math.floor(Math.random() * 1000 + 1);
          setCartID(randomchar);
        }

        // set cart item added time all set to first added time

        var addedTime = moment();
        console.log("cart");
        console.log(cart);
        if (cart?.length > 0) {
          addedTime = moment(cart[0].date);
        }

        const cart_itm = {
          key: getKey(),
          randomId: cartID === null ? randomchar : cartID,
          travelDateWithPickupTime: travel_date_with_pickup_time._d.toString(),
          pickuptime: sfd.pickuptime,
          pickuplocation: sfd.pickuplocation,
          destinations: sfd.dropofflocations,
          paxList,
          luggagelist,
          flightNo: sfd.flightNo,
          isFromAirport: sfd.isFromAirport,
          cutomerActualDropOffTime,
          garageArivalTime,
          cutomerChangedDropOffTimeOnly,
          bookingAmountInfo,
          totalDistance,
          tripStartTime: bookingAmountInfo.tripStartTime,
          garageArivalTimeWithAdditionalTime:
            bookingAmountInfo.garageArivalTimeWithAdditionalTime,
          note_arr,
          availableVehicleId: available_vehicle_id,
          date: addedTime,
          activePackage: getActivePackage(),
        };
        // setCart([...cart, cart_itm]);
        addItemCartToStore(cart_itm);
        resetform();
        // alert.show(t("Booking added to the cart."), { type: types.SUCCESS });
        if (isCart === true) history.push("./Cart");
        else window.location.reload(false);
      } else {
        // alert.show(
        //   "There are no vehicles available at the selected date and time.",
        //   { type: types.SUCCESS }
        // );

        dispatch(
          setAlert(
            "There are no vehicles available at the selected date and time.",
            "danger"
          )
        );
      }
    }
  };

  const resetform = () => {
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addAirportFormToState(null));
    setSfd({
      pickuplocation: null,
      dropofflocations: [],
      traveldate: "",
      pickuptime: "",
      dropofftime: "",
      pax: null,
      flightNo: "",
      isFromAirport: true,
    });
    setPackageAdditionaltime(0);
    setActivatedPackages([]);
    setPackageList([]);
    // setTravelDate("");
    setgarageArivalTime(null);
    // setPickuptime("");
    setcutomerChangedDropOffTimeOnly("");
    setcutomerActualDropOffTime(null);
    setCutomerDropOffTimeWithAdditinalTime(null);
    setGarageArivalTimeWithAdditionalTime(null);
    // setPickuplocation(null);
    // setDestinations(null);
    setVehicleTypeNames([]);
    setClickedVehicleType("");
    setBookingAmountInfo({
      totalTime: 0,
      baseprice: 0,
      hourlyprice: 0,
      tollFare: 0,
      isTimeRange: null,
      total: 0,
      vehicleTypeName: null,
      vehicleTypeID: null,
      tripStartTime: null,
      garageArivalTime: null,
      garageArivalTimeWithAdditionalTime: null,
      cutomerActualDropOffTime: null,
      cutomerDropOffTimeWithAdditinalTime: null,
      zoneHours: 0,
      totalhours: 0,
    });
    setTollFare(0);
    setTotalTime(0);
    setTotalDistance(0);
    setNote("");
  };
  console.log("activatedPackages");
  console.log(activatedPackages);

  const getActivePackage = () => {
    // const pkg = activatedPackages.find(
    //   (e) => e.vehicletype === clickedVehicleType
    // );
    if (activatedPackages.length === 0) return null;
    const pkg = activatedPackages[0];
    if (pkg) return pkg;
  };

  const hasActivePackage = () => {
    return activatedPackages?.length > 0 ? true : false;
  };

  const addItemCartToStore = (item) => {
    const localStoteItem = JSON.parse(localStorage.getItem("cart"));
    if (localStoteItem !== null && localStoteItem.length > 0) {
      var store_cart = JSON.parse(localStorage.getItem("cart"));
      store_cart.push(item);
      localStorage.setItem("cart", JSON.stringify(store_cart));
      dispatch(addToCartReduxStore(store_cart));
    } else {
      var cart_array_store = [];
      cart_array_store.push(item);
      localStorage.setItem("cart", JSON.stringify(cart_array_store));
      dispatch(addToCartReduxStore(cart_array_store));
    }
    addCartToDB(item);
  };

  const addCartToDB = (item) => {
    // console.log(item.activePackage);
    dispatch(
      addBooking(
        item.key,
        item.travelDateWithPickupTime,
        item.pickuptime,
        item.pickuplocation,
        item.destinations,
        item.paxList,
        item.luggagelist,
        item.flightNo,
        item.isFromAirport,
        item.cutomerActualDropOffTime,
        item.garageArivalTime,
        item.cutomerChangedDropOffTimeOnly,
        name,
        email,
        phone,
        address,
        0,
        item.availableVehicleId,
        item.bookingAmountInfo,
        bookingStatus.label,
        item.totalDistance,
        item.tripStartTime,
        item.garageArivalTimeWithAdditionalTime,
        item.note_arr,
        "Admin",
        item.activePackage
      )
    );
    dispatch(setAlert("Booking created", "success"));
  };

  const styleBookingStatus = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid " + bookingStatusval,
        boxShadow: "none",
      };
    },
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Create Booking" />

        <div className="container-fluid">
          <div className="col-md-10 col-lg-8 col-xl-6 mb-3">
            <ul className="nav text-center border-0 d-flex flex-column flex-md-row">
              <li className="nav-item py-2 py-md-0">
                <div
                  className={`nav-link nav-link-light-bg px-0 me-md-5 ${
                    tabName === "airport" ? "active" : ""
                  }`}
                  onClick={() => {
                    setTabName("airport");
                  }}
                  role={"button"}
                >
                  {t("Airport.Transfer")}
                </div>
              </li>
              <li className="nav-item py-2 py-md-0">
                <div
                  className={`nav-link nav-link-light-bg px-0 me-md-5 ${
                    tabName === "single" ? "active" : ""
                  }`}
                  aria-current="true"
                  onClick={() => {
                    resetFormValues();
                    setTabName("single");
                  }}
                  role={"button"}
                >
                  {t("Single.Destination")}
                </div>
              </li>
              <li className={`nav-item py-2 py-md-0 `}>
                <div
                  className={`nav-link nav-link-light-bg px-0 me-md-5 ${
                    tabName === "multi" ? "active" : ""
                  }`}
                  onClick={() => {
                    resetFormValues();
                    setTabName("multi");
                  }}
                  role={"button"}
                >
                  {t("Multiple.Destinations")}
                </div>
              </li>
              <li className={`nav-item py-2 py-md-0 `}>
                <div
                  className={`nav-link nav-link-light-bg px-0 me-md-5 ${
                    tabName === "byTheHour" ? "active" : ""
                  }`}
                  onClick={() => {
                    resetFormValues();
                    setTabName("byTheHour");
                  }}
                  role={"button"}
                >
                  {t("ByTheHour.Destinations")}
                </div>
              </li>
              
            </ul>
          </div>

          {getTab()}
          {isloading ? <Spinner /> : ""}
          <Alert />
          {showDetailsPannels ? (
            <Fragment>
              <div className="container px-md-5 my-5 py-5 bg-beige rounded">
                <div
                  id="scroll01"
                  className="row mb-5"
                  // ref={refScrollToSearchResult}
                  // ref={listRef}
                >
                  <h2>{t("Discover.Heading.Two")}</h2>
                </div>
                <div className="row gy-4">
                  {packageList.length > 0 ? (
                    packageList.map((p) => (
                      <div
                        key={p._id}
                        className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                      >
                        <div className="card custom-card h-100">
                          <img
                            src={toyota_granace}
                            className="card-img-top"
                            alt="Chauffeur driving a car"
                          />

                          <div className="card-header ps-3 pt-3 pb-0">
                            Airport transfer
                          </div>
                          <div className="card-body d-flex flex-column pt-1">
                            <h5 className="card-title fs-4 mb-3">
                              {p.packagename}
                            </h5>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-sm-flex justify-content-end">
                              <div className="d-grid gap-2 d-sm-block">
                                <button
                                  onClick={() => setActivatedPackages([p])}
                                  type="button"
                                  className={`btn ${
                                    activatedPackages[0]?.packagename ===
                                    p.packagename
                                      ? " btn-primary "
                                      : " btn-secondary "
                                  } btn-lg text-dark px-1 mt-3 mb-0`}
                                >
                                  <div className="px-2 d-inline">
                                    <i
                                      className={`bi ${
                                        activatedPackages[0]?.packagename ===
                                        p.packagename
                                          ? " bi-check-circle "
                                          : " bi-plus-circle "
                                      } pe-2`}
                                    ></i>
                                    {activatedPackages[0]?.packagename ===
                                    p.packagename
                                      ? "Applied"
                                      : "Apply Package"}
                                  </div>
                                </button>

                                {/* <button
                            onClick={() => {
                              setActivatedPackages([p]);
                            }}
                            type="button"
                            className="btn btn-primary btn-lg text-dark px-1 mt-3 mb-0"
                          >
                            <div className="px-2 d-inline">
                              <i className="bi bi-check-circle pe-2"></i>
                              {activatedPackages[0]?.packagename ===
                              p.packagename
                                ? "Applied"
                                : "Apply"}
                            </div>
                          </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-dark">
                      {t("Discover.Heading.Two.Paragraph")}{" "}
                      <a href="#" className="nav-link-light-bg">
                        {t("Discover.Heading.Two.Paragraph.Link")}
                      </a>
                    </p>
                  )}
                </div>
              </div>
              {/* <!-- section 1 select vehicle --> */}
              <div className="container px-md-5 my-5 py-5 bg-beige rounded">
                <div className="row mb-5">
                  <h2>{t("Discover.Heading.Three")}</h2>
                </div>
                <div className="row gy-4">
                  {vehicleTypeNames.map((vt, i = 0) => (
                    <VehicleTypeCard
                      key={vt._id}
                      count={i++}
                      clickedVehicleType={clickedVehicleType}
                      onClick={(e) => applyToTheEquation(e, vt)}
                      vehicle_type={vt}
                      customerAddedAditionalMinutes={packageAdditionaltime}
                      activatedPackages={activatedPackages}
                      total_hours={getTotalHours(
                        garageArivalTimeWithAdditionalTime
                      )}
                      toll_fare={tollFare}
                      zone_hours={getHoursInsideMultiplierZone(
                        tripStartTime,
                        garageArivalTimeWithAdditionalTime
                      )}
                    />
                  ))}
                </div>
              </div>
              {/* <!-- section 1 end --> */}

              <div className="container px-md-5 my-5 py-5 bg-grey rounded">
                <div className="row gy-4">
                  <div className="col-12">
                    <div className="row mb-5">
                      <h2 className="text-light">
                        {t("Discover.Heading.Four")}
                      </h2>
                    </div>

                    {/* <!-- card from cart --> */}
                    <div className="card mb-5 px-3 py-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col col-lg-6">
                            <ul className="list-group list-group-flush border-0">
                              <li className="list-group-item border-0 text-dark">
                                <div className="row">
                                  <div className="col-12 col-sm-4 col-md-3 ps-0">
                                    <p className="fw-bold text-dark my-0">
                                      <img
                                        src={getImage(clickedVehicleType)}
                                        className="img-fluid rounded-start"
                                      />
                                    </p>
                                  </div>
                                  <div className="col-12 col-md offset-md-1 mt-5 my-md-auto ps-0">
                                    <h5 className="fs-2">
                                      {clickedVehicleType}
                                    </h5>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-lg-6 d-flex align-self-start">
                          <ul className="list-group list-group-flush border-0">
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 col-lg-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.No.Of.Passengers")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {sfd.pax ? getTotalPaxCount(sfd?.pax) : 0}
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 col-lg-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Date")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {sfd ? sfd?.traveldate : "----/--/--"}
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 col-lg-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Pick.Up.Time")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {sfd ? sfd?.pickuptime : "--/--"}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Drop.Off.Time")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {cutomerChangedDropOffTimeOnly
                                      ? cutomerChangedDropOffTimeOnly
                                      : "--/--"}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Pick.Up.Location")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {sfd.pickuplocation
                                      ? sfd?.pickuplocation.label
                                      : "[pick up location]"}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Drop.Off.Location")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {/* {sfd.dropofflocations
                                    ? sfd?.dropofflocations.formatted_address
                                    : "[drop off location]"} */}
                                    {sfd.dropofflocations
                                      ? sfd?.dropofflocations
                                          .map((d) => {
                                            return d.label;
                                          })
                                          .join(" / ")
                                      : "[drop off location]"}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-lg-6">
                          <ul className="list-group list-group-flush border-0">
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Package")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {activatedPackages.length > 0
                                      ? activatedPackages[0].packagename
                                      : "No active packages"}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.30.Min.Price")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {selectedVehicleType?.hourlyprice} JPY
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Nightly.Rate")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {bookingAmountInfo?.zoneHours} Hours
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Base.Price")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {hasActivePackage() === false
                                      ? bookingAmountInfo?.baseprice + " JPY"
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold text-dark my-0">
                                    {t("Discover.Tolls")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="my-0">
                                    {bookingAmountInfo?.tollFare}
                                  </p>
                                </div>
                              </div>
                            </li>
                            {/* <li className="list-group-item border-0 text-dark">
                          <div className="row">
                            <div className="col col-md-4 mt-0">
                              <p className="fw-bold text-dark my-0">
                                {t("Discover.Package.Discount")}
                              </p>
                            </div>
                            <div className="col-12 col-sm">
                              <p className="my-0 text-danger">-- JPY</p>
                            </div>
                          </div>
                        </li> */}
                          </ul>
                        </div>

                        <div className="col-lg-6 offset-lg-6 mt-3">
                          <ul className="list-group list-group-flush border-0 mt-auto">
                            <li className="list-group-item border-0 text-dark">
                              <div className="row">
                                <div className="col col-md-4 mt-0">
                                  <p className="fw-bold fs-5 text-dark my-0">
                                    {t("Discover.Total.Price")}
                                  </p>
                                </div>
                                <div className="col-12 col-sm">
                                  <p className="fs-5 fw-bold my-0">
                                    {bookingAmountInfo?.total} {t("JPY")}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="border-0">
                            <div className="card-body border-top">
                              <p className="fw-bold text-dark mt-3">
                                {t("Discover.Additional.Costs")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- card from cart end --> */}

                  <div className="row mb-3">
                    <h3 className="text-light">{t("Discover.Comments")}</h3>
                  </div>

                  <div className="card mb-2 my-0 bg-transparent border-0">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="form-floating">
                          <textarea
                            className="form-control py-5 h-100"
                            placeholder="Leave a comment here"
                            id="floatingTextarea"
                            rows="5"
                            value={note}
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                          ></textarea>
                          <label htmlFor="floatingTextarea">
                            {t("Discover.Write.Comment")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="label mb-2" style={{ color: "white" }}>
                      Booking Status :
                    </label>
                    <div className="col-6">
                      <Select
                        styles={styleBookingStatus}
                        classNamePrefix="select pick-up location"
                        autoComplete="off"
                        value={bookingStatus}
                        onChange={(e) => {
                          setBookingStatus(e);
                          setBookingStatusval("#ced4da");
                        }}
                        options={bookingStatusOptions}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="label mb-2" style={{ color: "white" }}>
                      Customer Name:
                    </label>
                    <div className="col">
                      <input
                        className={`form-control ${nameval}`}
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameval("");
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="label mb-2" style={{ color: "white" }}>
                      Email:
                    </label>
                    <div className="col">
                      <input
                        className={`form-control ${emailval}`}
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailval("");
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="label mb-2" style={{ color: "white" }}>
                      Contact Number:
                    </label>
                    <div className="col">
                      <input
                        className={`form-control ${phoneval}`}
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setPhoneval("");
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="label mb-2" style={{ color: "white" }}>
                      Address:
                    </label>
                    <div className="col">
                      <textarea
                        className={`form-control ${addressval}`}
                        rows="2"
                        placeholder="Residential Address"
                        name="address"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          setAddressval("");
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-md-flex justify-content-md-end">
                    <div className="d-grid gap-2 d-md-block mt-auto">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg px-1 me-md-2"
                        onClick={() => {
                          addTocart(false);
                        }}
                      >
                        <div className="px-2 d-inline">
                          <i className="bi bi-plus-circle"></i>{" "}
                          {"Make a booking"}
                        </div>
                      </button>
                      {/* <button
                        disabled={checkoutDisable}
                        type="button"
                        className="btn btn-secondary-dark-bg btn-lg px-1"
                        onClick={() => {
                          addTocart(true);
                        }}
                      >
                        <div className="px-2 d-inline">
                          {t("Checkout")}
                          <i className="bi bi-chevron-right"></i>
                        </div>
                      </button> */}
                    </div>
                  </div>
                  <Alert />
                </div>
              </div>
            </Fragment>
          ) : (
            ""
          )}
        </div>
      </main>
    </Layout>
  );
};

export default CreateBookingNew;
