import React, { Fragment } from "react";
// import spinner from "./spinner.gif";

const Spinner = () => (
  <Fragment>
    <div>
      <hr />
      <br />
      <br />

      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-warning" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>

      <br />
      <br />
      <br />
      <hr />
    </div>

    {/* <img
      src={spinner}
      style={{ width: "200px", margin: "auto", display: "block" }}
      alt="loading... "
    /> */}
  </Fragment>
);

export default Spinner;
