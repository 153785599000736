/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { logoutAdmin } from "../../actions/auth";

const Navbar = ({ logout, logoutAdmin }) => {
  const location = useLocation();
  //console.log(location.pathname);

  // const authLinks = <div></div>;

  // const gustLinks = <div></div>;

  return (
    // <div className="side-shadow">
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse "
    >
      <div className="position-sticky">
        <ul className="nav flex-column mb-s">
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/dashboard" ? "active" : ""
              }`}
              to="/admin/dashboard"
            >
              <i className="fa fa-home fa-fw" aria-hidden="true"></i>&nbsp;
              Dashboard
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/bookinglist" ? "active" : ""
              }`}
              to="/admin/bookinglist"
            >
              <i className="fas fa-info-circle" aria-hidden="true"></i>&nbsp;
              Bookings
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/abandentcartlist" ? "active" : ""
              }`}
              to="/admin/abandentcartlist"
            >
              <i className="fas fa-info-circle" aria-hidden="true"></i>&nbsp;
              Abandoned Carts
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cancel-reschedule-request" ? "active" : ""
              }`}
              to="/admin/cancel-reschedule-request"
            >
              <i className="fas fa-info-circle" aria-hidden="true"></i>&nbsp;
              Reschedule and Cancellation Request
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/garagelist" ? "active" : ""
              }`}
              to="/admin/garagelist"
            >
              <i className="fas fa-warehouse" aria-hidden="true"></i>&nbsp;
              Garages
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/vehicletypelist" ? "active" : ""
              }`}
              to="/admin/vehicletypelist"
            >
              <i className="fas fa-warehouse" aria-hidden="true"></i>&nbsp;
              Vehicle Types
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/vehiclelist" ? "active" : ""
              }`}
              to="/admin/vehiclelist"
            >
              <i className="fas fa-car" aria-hidden="true"></i>&nbsp; Vehicles
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/driverlist" ? "active" : ""
              }`}
              to="/admin/driverlist"
            >
              <i className="fas fa-user-nurse" aria-hidden="true"></i>&nbsp;
              Drivers
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/userlist" ? "active" : ""
              }`}
              to="/admin/userlist"
            >
              <i className="fas fa-user-friends" aria-hidden="true"></i>&nbsp;
              Users
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/userlist-admin" ? "active" : ""
              }`}
              to="/admin/userlist-admin"
            >
              <i className="fas fa-user-friends" aria-hidden="true"></i>&nbsp;
              Admin Users
            </Link>
          </li>
          {/* <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/packagelist" ? "active" : ""
              }`}
              to="/admin/packagelist"
            >
              <i className="fas fa-gift" aria-hidden="true"></i>&nbsp; Packages
            </Link>
          </li> */}

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/newpackagelist" ? "active" : ""
              }`}
              to="/admin/newpackagelist"
            >
              <i className="fas fa-gift" aria-hidden="true"></i>&nbsp; Packages
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/airportlist" ? "active" : ""
              }`}
              to="/admin/airportlist"
            >
              <i className="fas fa-gift" aria-hidden="true"></i>&nbsp; Airports
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/categorylist" ? "active" : ""
              }`}
              to="/admin/categorylist"
            >
              <i className="fas fa-gift" aria-hidden="true"></i>&nbsp;
              Categories
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/bookingcalander" ? "active" : ""
              }`}
              to="/admin/bookingcalander"
            >
              <i
                className="fa fa-calendar-check-o fa-fw"
                aria-hidden="true"
              ></i>
              &nbsp; Booking Calendar
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/templatelist" ? "active" : ""
              }`}
              to="/admin/templatelist"
            >
              <i
                className="fa fa-calendar-check-o fa-fw"
                aria-hidden="true"
              ></i>
              &nbsp; Email Templates
            </Link>
          </li>
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Manage records</span>
          {/* <a className="link-secondary" href="#!" aria-label="Add a new record"> test </a>*/}
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addcategory" ? "active" : ""
              }`}
              to="/admin/addcategory"
            >
              <i className="fa fa-paper-plane fa-fw" aria-hidden="true"></i>
              &nbsp; Add Category
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addairport" ? "active" : ""
              }`}
              to="/admin/addairport"
            >
              <i className="fa fa-paper-plane fa-fw" aria-hidden="true"></i>
              &nbsp; Add Airport
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addemailtemplate" ? "active" : ""
              }`}
              to="/admin/addemailtemplate"
            >
              <i className="fa fa-paper-plane fa-fw" aria-hidden="true"></i>
              &nbsp; Add Email Template
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/createbookingnew" ? "active" : ""
              }`}
              to="/admin/createbookingnew"
            >
              <i className="fa fa-paper-plane fa-fw" aria-hidden="true"></i>
              &nbsp; Make a Booking
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addvehicletype" ? "active" : ""
              }`}
              to="/admin/addvehicletype"
            >
              <i className="fas fa-plus-square" aria-hidden="true"></i>&nbsp;
              Add New Vehicle Type
            </Link>
            {/* <a className="list-group-item" href="#">
              <i className="fa fa-male fa-fw" aria-hidden="true"></i>&nbsp;
              Driver Details
            </a> */}
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addvehicle" ? "active" : ""
              }`}
              to="/admin/addvehicle"
            >
              <i className="fas fa-plus-square" aria-hidden="true"></i>&nbsp;
              Add New Vehicle
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/adddriver" ? "active" : ""
              }`}
              to="/admin/adddriver"
            >
              <i className="fas fa-plus-square" aria-hidden="true"></i>&nbsp;
              Add New Driver
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/adduser" ? "active" : ""
              }`}
              to="/admin/adduser"
            >
              <i className="fas fa-plus-square" aria-hidden="true"></i>&nbsp;
              Add New Admin User
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addpackage" ? "active" : ""
              }`}
              to="/admin/addpackage"
            >
              <i className="fas fa-plus-square" aria-hidden="true"></i>&nbsp;
              Add New Package
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/addgarage" ? "active" : ""
              }`}
              to="/admin/addgarage"
            >
              <i className="fas fa-plus-square" aria-hidden="true"></i>&nbsp;
              Add New Garage
            </Link>
          </li>
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>CMS</span>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cmsindex" ? "active" : ""
              }`}
              to="/admin/cms/cmsindex"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              index
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-airport-transfer"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-airport-transfer"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              airport-transfer
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-weddings-anniversaries"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-weddings-anniversaries"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              weddings-anniversaries
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-private-tour"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-private-tour"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              private-tour
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-executive-transfer"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-executive-transfer"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              executive-transfer
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-fleet" ? "active" : ""
              }`}
              to="/admin/cms/cms-fleet"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              fleet
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-pricing" ? "active" : ""
              }`}
              to="/admin/cms/cms-pricing"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              pricing
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-alphard" ? "active" : ""
              }`}
              to="/admin/cms/cms-alphard"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              alphard
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-granace" ? "active" : ""
              }`}
              to="/admin/cms/cms-granace"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              granace
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-hiace-grand-cabin"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-hiace-grand-cabin"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              hiace-grand-cabin
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-hiace-luxury-cabin"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-hiace-luxury-cabin"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              hiace-luxury-cabin
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-maps" ? "active" : ""
              }`}
              to="/admin/cms/cms-maps"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp; maps
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-haneda-airport"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-haneda-airport"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              haneda-airport
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-narita-airport"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-narita-airport"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              narita-airport
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-tokyo-station"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-tokyo-station"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              tokyo-station
            </Link>
          </li>
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-cancellation-policy"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-cancellation-policy"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              cancellation-policy
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-careers" ? "active" : ""
              }`}
              to="/admin/cms/cms-careers"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              careers
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-corporate" ? "active" : ""
              }`}
              to="/admin/cms/cms-corporate"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              corporate
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-cart" ? "active" : ""
              }`}
              to="/admin/cms/cms-cart"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp; cart
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-terms" ? "active" : ""
              }`}
              to="/admin/cms/cms-terms"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              terms
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-haneda-airport-transfer"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-haneda-airport-transfer"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              haneda-airport-transfer
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-narita-airport-transfer"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-narita-airport-transfer"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              narita-airport-transfer
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-contact"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-contact"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              contact
            </Link>
          </li>

          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/cms/cms-checkout"
                  ? "active"
                  : ""
              }`}
              to="/admin/cms/cms-checkout"
            >
              <i className="fa fa-cube fa-fw" aria-hidden="true"></i>&nbsp;
              checkout
            </Link>
          </li>

        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Manage Account</span>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item-admin">
            <Link
              className={`list-group-item ${
                location.pathname === "/admin/settings" ? "active" : ""
              }`}
              to="/admin/settings"
            >
              <i className="fa fa-cogs fa-fw" aria-hidden="true"></i>&nbsp;
              Settings
            </Link>
          </li>
          <li className="nav-item-admin">
            <a href="#" onClick={logoutAdmin} className={`list-group-item`}>
              <i className="fas fa-door-open" aria-hidden="true"></i>&nbsp; Sign
              Out
            </a>
          </li>
        </ul>
      </div>
    </nav>
    // </div>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout, logoutAdmin })(Navbar);
