import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const DriverItem = ({
  drivers: { _id, name, email, phone, date, isfulltime, isactive },
  count,
}) => (
  <tr>
    <td>{count + 1}</td>
    <td>{name}</td>
    <td>{email}</td>
    <td>{phone}</td>
    <td>
      {isfulltime ? (
        <span className="badge bg-info py-2">Full Time</span>
      ) : (
        <span className="badge bg-primary py-2">Part Time</span>
      )}
    </td>
    <td>
      {isactive ? (
        <span className="badge bg-success py-2">Active</span>
      ) : (
        <span className="badge bg-grey-2 py-2">inactive</span>
      )}
    </td>
    <td>
      <Moment format="YYYY/MM/DD">{date}</Moment>
    </td>
    <td>
      <Link
        type="button"
        className="btn btn-secondary btn-sm py-2 px-4"
        to={`/admin/driverlist/edit/${_id}`}
      >
        Edit
      </Link>
    </td>
  </tr>
);

export default DriverItem;
