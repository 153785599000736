import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import Alert from "../../layout/Alert";
import Layout from "../../layout/Layout";
import TitleBar from "../../layout/TitleBar";
import ScaleLoader from "react-spinners/ScaleLoader";
import EmailEditor from "react-email-editor";
import { useDispatch, useSelector } from "react-redux";
import { saveEmailTemplate } from "../../../actions/admin/mail";
// import cusjs from "./custom";

const AddEmailTemplate = () => {
  // const React = window.unlayer.React;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const [isCutomerEmail, setIsCustomerEmail] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [subject, setSubject] = useState("");
  const emailEditorRef = useRef(null);

  const onLoad = () => {};

  const exportHtml = () => {
    emailEditorRef.current?.editor?.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      console.log("design", design);
      // return;
      const currentUser = { id: user._id, name: user.name, email: user.email };
      dispatch(
        saveEmailTemplate(
          null,
          html,
          design,
          templateName,
          isCutomerEmail,
          currentUser,
          subject
        )
      );
    });
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Add Email Template" />
        <div className="container-fluid">
          <div className="row mb-2">
            <Alert />
            <div className="col-3">
              <label className="label mb-2">Template Name:</label>
              <input
                type="text"
                className="form-control form-control-admin"
                placeholder="Enter template name"
                name="templatename"
                value={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
                required
              />
            </div>
            <div className="col-3">
              <label className="label mb-2">Default Email Subject:</label>
              <input
                type="text"
                className="form-control form-control-admin"
                placeholder="Enter subject"
                name="subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                required
              />
            </div>
            <div className="col-3 ">
            <label className="mb-2">
                  Mark as default customer email
                </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isCutomerEmail}
                  name="isactive"
                  onChange={(e) => {
                    setIsCustomerEmail(!isCutomerEmail);
                  }}
                />
                
              </div>
            </div>

            
          </div>
        
          <div className="mb-2">
            <div className="mt-3 mb-0">Available dynamic tags:</div>
            <div><p className="card-title">Copy and add these tags to a paragraph, div or heading in the template to automatically insert dynamic data (eg:customer name, cart table) when the email is sent.</p></div>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{pickuplocation}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{dropofflocation}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{drivername}}"}</label> 
            <label  className="badge bg-light border border-dark p-2 my-2 me-3">{"{{vehiclename}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{vehicletype}}"}</label> 
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{pickuptime}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{dropofftime}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{customername}}"}</label>
            <label  className="badge bg-light border border-dark p-2 my-2 me-3">{"{{cart}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{packagename}}"}</label> 
            <label  className="badge bg-light border border-dark p-2 my-2 me-3">{"{{totalcost}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{flightno}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2">{"{{vehicleregistration}}"}</label>
          </div>
          <div className="my-4 col-3 d-flex align-items-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={exportHtml}
              >
                <i className="fa fa-plus-circle fa-fw" aria-hidden="true"></i>{" "}
                Save Template
              </button>
            </div>
          <div className="row">
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              onReady={onReady}
              minHeight={"80vh"}
              // tools={null}
              // options={{
              //   customJS: ["http://localhost:3000/custom.js"],
              // }}
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default AddEmailTemplate;

// const saveDesign = () => {
//   emailEditorRef.current?.editor?.saveDesign((design) => {
//     console.log("saveDesign", design);
//     alert("Design JSON has been logged in your developer console.");
//   });
// };

// const togglePreview = () => {
//   if (preview) {
//     emailEditorRef.current?.editor?.hidePreview();
//     setPreview(false);
//   } else {
//     emailEditorRef.current?.editor?.showPreview("desktop");
//     setPreview(true);
//   }
// };

// window.unlayer.registerTool({
//   name: "my_tool",
//   label: "My Tool",
//   icon: "fa-smile",
//   supportedDisplayModes: ["web", "email"],
//   options: {},
//   values: {},
//   renderer: {
//     Viewer: Viewer, // our React Viewer
//     exporters: {
//       web: function (values) {
//         return "<div>I am a custom tool.</div>";
//       },
//       email: function (values) {
//         return "<div>I am a custom tool.</div>";
//       },
//     },
//     head: {
//       css: function (values) {},
//       js: function (values) {},
//     },
//   },
// });

// window.unlayer.init({
//   id: "editor-container",
//   displayMode: "email",
//   customJS: [
//     "https://examples.unlayer.com/examples/react-custom-tool/custom.js",
//   ],
// });
