import React, { useState, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import Alert from "../../layout/Alert";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import { setAlert } from "../../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import setting_file from "../../../utils/settings.json";
import { updateSettings, getSetting } from "../../../actions/admin/settings";

const Settings = () => {
  var CryptoJS = require("crypto-js");
  const crp_secret = setting_file.CRYPTO_SECRET;
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.setting);
  const { setting, loading } = settings;
  const [formData, setFormData] = useState({
    id: "",
    buffertime: "",
    carttimeout: "15",
    totaltriptime: "",
    smtpemail: "",
    smtppass: "",
  });

  useEffect(() => {
    dispatch(getSetting());
  }, []);

  useEffect(() => {
    setFormData({
      id: loading || !setting?._id ? "" : setting?._id,
      buffertime: loading || !setting?.buffertime ? "" : setting?.buffertime,
      carttimeout: loading || !setting?.carttimeout ? "" : setting?.carttimeout,
      totaltriptime:
        loading || !setting?.totaltriptime ? "" : setting?.totaltriptime,
      smtpemail: loading || !setting?.smtpemail ? "" : setting?.smtpemail,
      smtppass:
        loading || !setting?.smtppass
          ? ""
          : JSON.parse(
            CryptoJS.AES.decrypt(setting?.smtppass, crp_secret).toString(
              CryptoJS.enc.Utf8
            )
          ),
    });
  }, [setting]);

  const { buffertime, carttimeout, totaltriptime, smtpemail, smtppass } =
    formData;

  const [isloading, setIsloading] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("onSubmit");
    setIsloading(true);

    const encrypt_pass = CryptoJS.AES.encrypt(
      JSON.stringify(formData.smtppass),
      crp_secret
    ).toString();
    console.log(encrypt_pass);
    // setFormData({
    //   id: formData.id,
    //   buffertime: formData.buffertime,
    //   carttimeout: formData.carttimeout,
    //   totaltriptime: formData.totaltriptime,
    //   smtpemail: formData.smtpemail,
    //   smtppass: encrypt_pass,
    // });
    dispatch(
      updateSettings({
        id: formData.id,
        buffertime: formData.buffertime,
        carttimeout: formData.carttimeout,
        totaltriptime: formData.totaltriptime,
        smtpemail: formData.smtpemail,
        smtppass: encrypt_pass,
      })
    );
    setIsloading(false);
  };

  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Update site settings" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <form onSubmit={(e) => onSubmit(e)}>

                  <div className="card mb-3">
                    <div className="card-header">Settings for bookings</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">
                          Booking Lead Time : [hours]
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter buffer time"
                            name="buffertime"
                            value={buffertime}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">
                          Max total trip time : [hours]
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter trip time"
                            name="totaltriptime"
                            value={totaltriptime}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">Settings for connected email account</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">
                          smtp email : [gmail account]
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter trip time"
                            name="smtpemail"
                            value={smtpemail}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="label mb-2">smtp email password:</label>
                        <div className="col-sm-12">
                          <input
                            type="password"
                            className="form-control form-control-admin"
                            placeholder="Enter trip time"
                            name="smtppass"
                            value={smtppass}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update
                      </button>
                    </div>
                  </div>

                  <div className="mb-3 invisible">
                    <label className="label mb-2">
                      Cart timeout : [minutes]
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control form-control-admin"
                        placeholder="Enter timeout"
                        name="carttimeout"
                        value={carttimeout}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default Settings;
