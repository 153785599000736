import { GET_ADDRESS_LIST, NAVTIME_ERROR } from "../actions/types";

const initialState = {
  addresses: [],
  address: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADDRESS_LIST:
      return {
        ...state,
        addresses: payload,
        loading: false,
      };

    case NAVTIME_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
