/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const BookingItem = ({
  bookings: {
    _id,
    bookingStatus,
    picuplocation,
    pickupLocation,
    travelDate,
    date,
    customer,
    bookingAmountInfo,
    cartID,
    bookingInfo,
    byTheHourBook,
    bookingType,
    destinations,
    isAirportTransfer,
    paymentStatus,
    dataVersion = "v1",
  },
  count,
}) => {
  const [style, setStyle] = useState("null");
  const [stylePaymentStatus, setStylePaymentStatus] = useState("null");
  useEffect(() => {
    setBookingStyle();
    setPaymentStatusStyle();
  }, []);

  const setBookingStyle = () => {
    switch (bookingStatus) {
      case "CONFIRMED":
        setStyle("badge bg-success py-2");
        break;
      case "PAYMENT_PENDING":
        setStyle("badge bg-primary py-2");
        break;
      case "INQUIRY":
        setStyle("badge bg-warning py-2");
        break;
      case "CANCELLED":
        setStyle("badge bg-danger py-2");
        break;
      default:
        setStyle("badge bg-dark py-2");
        break;
    }
  };

  const setPaymentStatusStyle = () => {
    switch (paymentStatus) {
      case "CHARGED":
        setStylePaymentStatus("badge bg-success py-2");
        break;
      case "CHARGE_PENDING":
        setStylePaymentStatus("badge bg-warning py-2");
        break;
      case "PAID":
        setStylePaymentStatus("badge bg-primary py-2");
        break;
      case "INCOMPLETED":
        setStylePaymentStatus("badge bg-danger py-2");
        break;
      default:
        setStylePaymentStatus("badge bg-dark py-2");
        break;
    }
  };

  // Extract vehicle type names from bookingInfo array
  const vehicleTypeNames = bookingInfo.map((info) => info.vehicleTypeName);

  // Combine vehicle type names with commas
  const combinedVehicleTypeNames = vehicleTypeNames.join(', ');

  return (
    <tr>
      <td>{count + 1}</td>
      <td>{customer.name}</td>
      <td>
        <span
          className="badge text-dark py-2"
          style={{
            border: "1px solid #" + cartID.substring(0, 6),
          }}
        >
          {cartID}
        </span>
      </td>
      <td>{combinedVehicleTypeNames}</td>
      <td>
        <div className={style}>{bookingStatus}</div>
      </td>
      <td>
        <div className={bookingType === "byTheHour" ? 'badge bg-secondary py-2' : isAirportTransfer ? 'badge bg-light py-2 text-dark' : 'badge bg-dark py-2'}>{bookingType === "byTheHour" ? 'By The Hour' : isAirportTransfer ? 'Airport Transfer' : 'Specified Destination'}</div>
      </td>
      <td>
        <div className={stylePaymentStatus}>{`${paymentStatus === "paid" || paymentStatus === "incompleted" ? "stripe - " : "flywire - "} ${paymentStatus}`}</div>
      </td>
      <td>
        <Moment format="YYYY/MM/DD">{date}</Moment>
      </td>
      <td>
        <Moment format="YYYY/MM/DD hh:mm A">{travelDate}</Moment>
      </td>

      <td>
        <Moment format="YYYY/MM/DD hh:mm A">
          {bookingInfo[0].cutomerDropOffTimeWithAdditinalTime}
        </Moment>
      </td>
      <td>{picuplocation !== undefined ?picuplocation.label : pickupLocation.label}</td>
      <td>
        <Link
          type="button"
          className="btn btn-secondary btn-sm py-2"
          to={ dataVersion === "v2" ? `/admin/bookinglist/edit/v2/${_id}` : `/admin/bookinglist/edit/${_id}`}
        >
          Manage
        </Link>
      </td>

      {/*  <td>
      <Moment format="YYYY/MM/DD">{date}</Moment>
    </td>
    
     */}
    </tr>
  );
};

export default BookingItem;
