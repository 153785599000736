// import axios from "axios";
import customAxios from "../utils/customAxios";
import axios from "axios";
import {
  REGISTER_SUCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  ALL_USERS,
  EDIT_USER,
  LOGOUT_ADMIN,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import  jwtDecode  from "jwt-decode";


// load user
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await customAxios.get("/api/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/users/getuser/${id}`);
    dispatch({
      type: EDIT_USER,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getUserDetail = async (id) => {
  try {
    console.log(localStorage.token);
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await customAxios.get(`/api/auth/user-detail/${id}`);
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error)
  }
};

export const updatePaymentInfo = async (requestBody) => {
  try {
    console.log(localStorage.token);
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await customAxios.post(
      "/api/users/update-user-payment-method",
      requestBody
    );
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error)
  }
};

export const getAllUsersAdmin = () => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/users/getallusers/admin`);
    dispatch({
      type: ALL_USERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/users/getallusers`);
    console.log(res)
    dispatch({
      type: ALL_USERS,
      payload: res.data.users,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllUsersCount = async () => {
  try {
    const res = await customAxios.get(`/api/users/getallusers`);
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error)
  }
};

export const postApproveUser = async (id) => {
  try {
    const res = await customAxios.post(`/api/users/approveuser/${id}`);
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error)
  }
};

export const updateUser = async (_id, name, email, password) => {
  try {
    // const body = JSON.stringify({ name, email, password });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/users/updateuser",
      {
        _id,
        name,
        email,
        password,
      },
      config
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return [err];
  }
};

export const addUserToDB = async (name, email, password) => {
  try {
    // const body = JSON.stringify({ name, email, password });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/users",
      {
        name,
        email,
        password,
      },
      config
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return [err];
  }
};

// register customer
// export const customerRegister = async ({
//   name,
//   email,
//   password,
//   billingInformation,
//   paymentMethod,
//   googleSignUpToken = null,
//   avatar = null,
// }) => async (dispatch) => {
//   try {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const body = JSON.stringify({
//       name,
//       email,
//       password,
//       billingInformation,
//       paymentMethod,
//       accessToken: googleSignUpToken,
//       avatar: avatar
//     });

//     const res = await customAxios.post(
//       "/api/users/register-individual",
//       body,
//       config
//     );
//     console.log(res)

//     if (res.data.code === 201 && res.data.withGoogle) {
//       dispatch({
//         type: LOGIN_SUCCESS,
//         payload: { token: res.data.token },
//       });
//       dispatch(loadUser());
//     }
//     return res;
//   } catch (err) {
//     return false;
//   }
// };

export const accountEligibility = async (
  email,
  type
) => {
  try {
    console.log(email, type)
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/users/account-eligibility",
      {
        email,
        type,
      },
      config
    );

    console.log(res)

    return res.data; // Return the response directly
  } catch (err) {
    const errorResponse = {
      "data": {
        "code": err.response.status,
        "msg": err.response.data.errors.msg,
        eligibilityStatus: false
      },
    };
    return errorResponse;
  }
};

export const customerRegister = ({
  name,
  email,
  password,
  billingInformation,
  paymentMethod,
  googleSignUpToken = null,
  avatar = null,
  lang = "EN"
}) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name,
      email,
      password,
      lang: lang,
      billingInformation,
      paymentMethod,
      accessToken: googleSignUpToken,
      avatar: avatar
    });

    const res = await customAxios.post(
      "/api/users/register-individual",
      body,
      config
    );

    if (res.data.code === 201 && res.data.withGoogle) {
      var dataFromToken = JSON.parse(atob(res.data.token.split('.')[1]));

      const dataToPush = {
        event: 'signup',
        authentication_method: res.data.withGoogle ? 'google' : 'email',
        user_id: dataFromToken.user.id._id
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataToPush);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token: res.data.token },
      });
      dispatch(loadUser());
    }

    return res; // Return the response directly
  } catch (err) {
    const errorResponse = {
      "data": {
        "code": err.response.status,
        "msg": err.response.data.errors.msg
      },
    };
    return errorResponse;
  }
};

// register business
export const businessRegister = async ({
  name,
  email,
  password,
  businessInformation,
  authorizedRepresentativeContact,
  billingInformation,
  paymentMethod,
  emailRecipients,
}) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      name,
      email,
      password,
      businessInformation,
      authorizedRepresentativeContact,
      billingInformation,
      paymentMethod,
      emailRecipients,
    });

    const res = await customAxios.post(
      "/api/users/register-business",
      body,
      config
    );
    return res;
  } catch (err) {
    return [err];
  }
};

// validate customer
export const validateUser = async ({ token }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ token });
    const res = await customAxios.post(
      "/api/users/email-verification",
      body,
      config
    );
    return res;
  } catch (err) {
    return [];
  }
};

// login user
export const checkEmailBeforeRegister = async (body) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/auth/check-email", body, config);

    return res;
  } catch (error) {
    console.log("CATCH", error);
    return false;
  }
};

// register user
export const register =
  ({ name, email, password }) =>
    async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ name, email, password });
      try {
        const res = await customAxios.post("/api/users", body, config);
        dispatch({
          type: REGISTER_SUCESS,
          payload: res.data,
        });
        dispatch(loadUser());
      } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
          type: REGISTER_FAIL,
        });
      }
    };
// login user
export const login = (email, password, type = null, isAdmin = false) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    let body, res;
    if (type === null) {
      body = JSON.stringify({ email, password, isAdmin });
      res = await axios.post("/api/auth", body, config);
    } else if (type === 'google') {
      body = JSON.stringify({ email, token: password });
      res = await axios.post("/api/auth/with-google", body, config);
    }
    
    if (res.status == 200) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());

      var dataFromToken = jwtDecode(res.data.token)
      var loginType;

      if (type === 'google') {
        loginType = 'google';
      } else {
        loginType = 'email';
      }

      const dataToPush = {
        event: 'signin',
        authentication_method: loginType,
        user_id: dataFromToken.user.id._id
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataToPush);
      return true;
    } else {
      console.log(res);
      dispatch({
        type: LOGIN_FAIL,
      });
      return false;
    }
  } catch (error) {
    console.log("CATCH", error);
    dispatch({
      type: LOGIN_FAIL,
    });
    return false;
  }
};
//login with google
export const loginWithGoogle = (email, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, token });
  // console.log(body);
  try {
    const res = await customAxios.post("/api/auth/with-google", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    return true;
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
    return false;
  }
};

export const postBusinessLicense = async (businessLicense) => {
  try {
    // const config = {
    //   headers: {    
    //     'Content-Type': 'multipart/form-data',
    //   },
    // };
    // const formData = new FormData();
    // formData.append("businessLicense", businessLicense);
    // const body = formData;
    // const res = await customAxios.post(
    //   "/api/users/upload-business-license",
    //   formData,
    //   config
    // );
    const formData = new FormData();
    formData.append('file', businessLicense);

    const res = await axios.post(`${process.env.NODE_ENV !== "production" ? "http://localhost:5001" : ""}/api/users/upload-business-license`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

// Logout / clear profile

export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};

export const logoutAdmin = () => (dispatch) => {
  // dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT_ADMIN });
};

export const requestForgotPassword = async (email, lang) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body, res;
    body = JSON.stringify({ email, lang });
    res = await axios.post("/api/auth/request-forgot-password", body, config);
    return res.data;
  } catch (error) {
      var errorRes = {
        code : 401,
        desc : "Email not found"
      }
      return errorRes;
  }
}

export const resetPassword = async (email, token, password, passwordConfirm) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body, res;
    body = JSON.stringify({ email, token, password, password_confirmation :  passwordConfirm });
    res = await axios.post("/api/auth/send-forgot-password", body, config);
    return res.data;
  } catch (error) {
      var errorRes = {
        code : 401,
        desc : "Reset Failed"
      }
      return errorRes;
  }
}

// TBD in future phase: Make an additional api call to remove token from server
