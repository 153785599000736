import React, { useEffect, useState } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import moment from "moment";
import CartItemInfoCard from "../layout-new-theme/cards/CartItemInfoCard";
import {
  addToCartReduxStore,
  deleteCartItem,
  removeFromCartReduxStore,
} from "../../actions/admin/booking";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { withAlert, types } from "react-alert";
import Clock from "../front-pages/Clock";
import { getPageContents } from "../../actions/admin/cmspage";
import clock_icon from "../../images/clock-icon.svg";
import back_icon from "../../images/back_icon.svg";
import CheckOut from "./CheckOut";

const Cart = ({ alert }) => {
  const history = useHistory();
  const { cart } = useSelector((state) => state.booking);
  const dispatch = useDispatch();
  const page_name = "cms-cart";
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  const [isAgree, setIsAgree] = useState(false);
  const [clockRef, setClockRef] = useState(null);

  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  useEffect(() => {
    try {
      //push to GTM --- start
      // const items = [];
      // let totalPrice = 0;

      // for (let i = 0; i < cart.length; i++) {
      //   const item = {
      //     item_id: cart[i].bookingAmountInfo.vehicleTypeID,
      //     item_name: cart[i].bookingAmountInfo.vehicleTypeName,
      //     affiliation: "Flywire",
      //     coupon: "",
      //     discount: 0,
      //     index: 0,
      //     item_brand: cart[i].bookingAmountInfo.vehicleTypeName,
      //     item_category: cart[i]?.isFromAirport
      //       ? "airport transfer"
      //       : cart[i].destinations.length > 1
      //       ? "multiple destination"
      //       : "single destination",
      //     location_id:
      //       cart[i].destinations.length == 1
      //         ? cart[i].destinations[0].place_id
      //         : cart[i].destinations[cart[i].destinations.length - 1].place_id,
      //     price: cart[i].bookingAmountInfo.total,
      //     quantity: 1,
      //   };

      //   totalPrice += cart[i].bookingAmountInfo.total;
      //   items.push(item);
      // }

      // const ecommerce = {
      //   currency: "JPY",
      //   value: totalPrice,
      //   items: items,
      // };

      // window.dataLayer.push({
      //   event: "view_cart",
      //   ecommerce: ecommerce,
      // });

      //push to GTM --- end

      // if (cart !== null && cart.length > 0) {
      //   const createdDatetime = moment(cart[0].date);
      //   const currentDatetime = moment();
      //   const duration = moment.duration(currentDatetime.diff(createdDatetime));
      //   const minutes = duration.asMinutes();
      //   if (minutes < 60) {
      //     dispatch(addToCartReduxStore(cart));
      //   } else {
      //     localStorage.removeItem("cart");
      //     dispatch(removeFromCartReduxStore([]));
      //   }
      // }
    } catch (e) {
    }
  }, [cart]);

  // Delete a single item/ booking from the cart when the delete button is clicked
  const clickRemoveItemFromcart = async (item) => {
    if (localStorage.getItem("cart") !== null) {
      const curCart = cart.filter((i) => i.key !== item.key);
      dispatch(addToCartReduxStore(curCart));
      localStorage.setItem("cart", JSON.stringify(curCart));
      dispatch(removeFromCartReduxStore(curCart));
      // Remove the relevant item/ booking from the database
      await deleteCartItem(item.key);
      if (curCart.length === 0) {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  };

  // update cart item
  const updateCartItem = async (item) => {
    if (localStorage.getItem("cart") !== null) {
      dispatch(addToCartReduxStore(cart));
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(removeFromCartReduxStore(cart));
    } else {
      window.location.reload();
    }
  };

  const emptyCart = () => {
    dispatch(addToCartReduxStore([]));
    localStorage.setItem("cart", JSON.stringify([]));
    dispatch(removeFromCartReduxStore([]));
  };

  const getTotalAmount = () => {
    var tot = 0;
    if (cart?.length > 0) {
      tot = cart.map((c) => c.bookingAmountInfo.total).reduce(sum);
    }
    return tot;
  };

  function sum(prev, next) {
    return prev + next;
  }

  const clickCheckout = () => {
    if (cart.length === 0 || !isAgree) return;

    //push to GTM --- start
    // const items = [];
    // let totalPrice = 0;

    // for (let i = 0; i < cart.length; i++) {
    //   const item = {
    //     item_id: cart[i].bookingAmountInfo.vehicleTypeID,
    //     item_name: cart[i].bookingAmountInfo.vehicleTypeName,
    //     affiliation: "Flywire",
    //     coupon: "",
    //     discount: 0,
    //     index: 0,
    //     item_brand: cart[i].bookingAmountInfo.vehicleTypeName,
    //     item_category: cart[i]?.isFromAirport
    //       ? "airport transfer"
    //       : cart[i].destinations.length > 1
    //       ? "multiple destination"
    //       : "single destination",
    //     location_id:
    //       cart[i].destinations.length == 1
    //         ? cart[i].destinations[0].place_id
    //         : cart[i].destinations[cart[i].destinations.length - 1].place_id,
    //     price: cart[i].bookingAmountInfo.total,
    //     quantity: 1,
    //   };
    //   totalPrice += cart[i].bookingAmountInfo.total;
    //   items.push(item);
    // }

    // const ecommerce = {
    //   currency: "JPY",
    //   value: totalPrice,
    //   items: items,
    // };

    // window.dataLayer.push({
    //   event: "begin_checkout",
    //   ecommerce: ecommerce,
    // });

    //push to GTM --- end

    history.push("./CheckOut");
  };

  const SettingClockRef = (ref) => {
    if (ref !== null) setClockRef(ref);
  };

  const onCompleteTimer = () => {
    if (cart.length > 0) {
      emptyCart();
      alert.show(
        t("Your cart has expired. Please add all items to your cart again."),
        { type: types.INFO }
      );
    }
  };

  const start = () => {
    try {
      if (clockRef === null) return;
      clockRef.start();
    } catch (e) {
    }
  };

  const pause = () => {
    try {
      clockRef.pause();
    } catch { }
  };

  const stop = () => {
    try {
      clockRef.stop();
    } catch { }
  };

  return (
    <FrontLayout>
      {/* <!-- section 1 --> */}
      <div className="container-fluid bg-page-cart mt-1 pt-1">
        <div className="custom-mx">
          <div className="container pb-5 custom-mt-8 col-lg-12">
            {/* <!-- column for CART ITEMS--> */}

            <div className="col-12 col-lg-8 me-2 me-lg-0">
              <div className="row mb-4 gx-0 d-flex justify-content-between">
                <div className="col-12 pb-lg-0 mb-4">
                  <div className=" d-flex-auto row align-items-center" onClick={() => history.goBack()}>
                    <div className="col-auto back-to-vehicle-selection-button">
                      <img src={back_icon} className="ps-2" alt="Clock icon" />
                    </div>
                    <div className="col">
                      <p
                        className="fw-100 text-start text-dark noto d-inline back-to-vehicle-selection-button"

                      > {t("Back to Vehicle Selection")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md pb-3 pb-lg-0 pb-lg-0 my-md-0 ">
                  <h1
                    className="fw-bold text-start text-dark "
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content1
                          : cmspage?.page_content_jp.content1,
                    }}
                  >
                    {/* {t("Cart.Heading")} */}
                  </h1>
                </div>

                <div className="d-flex col-md-auto justify-content-between bg-gold-light px-3 py-3 py-md-0 rounded d-flex align-items-center">
                  <p
                    className="d-inline m-0 pe-lg-5"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content2
                          : cmspage?.page_content_jp.content2,
                    }}
                  >
                    {/* {t("Cart.Expire.Text")} */}
                  </p>
                  <div className="col-auto px-0">
                    <p className="d-inline noto">
                      <Clock
                        start={start}
                        stop={stop}
                        // setting={setting}
                        cart={cart}
                        refCallback={SettingClockRef}
                        onCompleteTimer={onCompleteTimer}
                      />
                    </p>
                    <img src={clock_icon} className="ps-2" alt="Clock icon" />
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- item card 1 --> */}
            {cart.length > 0 && cart !== undefined
              ? cart.map((c, i = 0) => (
                <div className="mb-3">
                  <CartItemInfoCard
                    key={i}
                    cart={c}
                    index={i}
                    removeItemFromCart={clickRemoveItemFromcart}
                    updateCartItem={updateCartItem}
                  />
                </div>
              ))
              : ""}
            {/* <!-- item card 1 end --> */}
            <CheckOut 
            updateCartItem={updateCartItem}
            />
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default withAlert()(Cart);
