import {
  ADD_DRIVER,
  DRIVER_ERROR,
  GET_DRIVERS,
  GET_DRIVER,
} from "../../actions/types";

const initialState = {
  drivers: [],
  driver: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_DRIVER:
      return {
        ...state,
        driver: payload,
        loading: false,
      };
    case GET_DRIVER:
      return {
        ...state,
        driver: payload,
        loading: false,
      };
    case GET_DRIVERS:
      return {
        ...state,
        drivers: payload,
        loading: false,
      };
    case DRIVER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
