import {
  ADD_PACKAGE,
  GET_PACKAGES,
  GET_PACKAGE,
  DELETE_PACKAGE,
  DELETE_NEW_PACKAGE,
  PACKAGE_ERROR,
  GET_NEW_PACKAGES,
} from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const getPackage = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/packages/package/${id}`);
    dispatch({
      type: GET_PACKAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const filterNewPackage = async (formData) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(`/api/admin/newpackages/filter`, formData, config);
    return res.data;
  } catch (err) {
    console.log(err.response);
    return null;
  }
};

export const getNewPackage = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(
      `/api/admin/newpackages/newpackage/${id}`
    );
    dispatch({
      type: GET_PACKAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getAllPackages = async () => {
  try {
    const res = await customAxios.get("/api/admin/packages");
    // console.log(res);
    return res.data;
  } catch (err) {
    return [];
  }
};

export const deletePackage = (id) => async (dispatch) => {
  try {
    const res = await customAxios.delete(`/api/admin/packages/${id}`);
    dispatch({
      type: DELETE_PACKAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteNewPackage = (id) => async (dispatch) => {
  try {
    const res = await customAxios.delete(`/api/admin/newpackages/${id}`);
    dispatch({
      type: DELETE_NEW_PACKAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getPackages = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/packages/all");
    dispatch({
      type: GET_PACKAGES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getNewPackages = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/newpackages/all");
    dispatch({
      type: GET_NEW_PACKAGES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const addPackage = (formdata) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post("/api/admin/packages", formdata, config);
    // console.log(res);
    dispatch({
      type: ADD_PACKAGE,
      payload: res.data,
    });
    dispatch(setAlert("Package added", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      dispatch(setAlert(errors, "danger"));
    }
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const addNewPackage = (formdata) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/newpackages",
      formdata,
      config
    );
    // console.log(res);
    dispatch({
      type: ADD_PACKAGE,
      payload: res.data,
    });
    dispatch(setAlert("Package added", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      dispatch(setAlert(errors, "danger"));
    }
    dispatch({
      type: PACKAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
