/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addSingleDestinationFormToState,
  addMultipleDestinationFormToState,
  addAirportFormToState,
  addByTheHourFormToState,
} from "../../../actions/admin/booking";
import PassengerCard from "../cards/PassengerCard";
import { useHistory, useLocation } from "react-router-dom";
import {
  getTotalPaxCount,
  getTotalLuggageCount,
} from "../../../utils/Calculator";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getRouteCarForTotalTime } from "../../../actions/navitimeAPI";
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/ja";
import ja from "date-fns/locale/ja";
import LocationPinIcon from "../../../icons/form-icons/location-pin.svg"
import ClockIcon from "../../../icons/form-icons/clock.svg"
import DateIcon from "../../../icons/form-icons/date.svg"
import PaxIcon from "../../../icons/form-icons/pax.svg"
import LuggageIcon from "../../../icons/form-icons/luggage.svg"
import SearchVehicleIcon from "../../../icons/form-icons/search-vehicle.svg"
import CustomGoogleAutoComplete from "../../../components/layout-new-theme/google-input/CustomGoogleAutoComplete"
import { travelAreaOptions } from "../../../utils/dataLists";
import depature_flight_icon from "../../../icons/form-icons/depature_flight.svg";

const ByTheHourForm = ({
  byTheHourForm = null,
  checkByTheHourFormAvailableVehicle,
  availabilityValueReset,
  formValidationData,
  isLoading = null,
}) => {
  const { t } = useTranslation();
  let history = useHistory();
  const search = useLocation();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [passengerVisible, setPassangeVisible] = useState(false);
  const [lugageVisible, setLugageVisible] = useState(false);
  const [pickuplocation, setpickuplocation] = useState();
  const [dropofflocations, setdropofflocations] = useState([]);
  const [halfHourIntervalsData, setHalfHourIntervalsData] = useState([]);
  const [totalPax, setTotalPax] = useState(0);
  const [totalLugage, setTotalLugage] = useState(0);
  const [formatedTravelDate, setFormatedTravelDate] = useState("");
  const [travelDatePicker, setTravelDatePicker] = useState(new Date());
  const [byTheHourFormData, setByTheHourData] = useState({
    pickuplocation: "",
    dropofflocations: [],
    traveldate: "",
    pickuptime: "",
    dropofftime: "",
    pax: null,
    lugage: {
      checkinSize: 0,
      cabinSize: 0,
    },
    duration: "",
    byTheHourBook: true,
    isFromAirport: false,
    isToAirport: false,
    travelArea: "",
    flightTracking: null,
  });
  const settings = useSelector((state) => state.setting);
  const [changeLuggage, setChangeLuggage] = useState(
    byTheHourForm !== null ? true : false
  );
  const { lang } = settings;
  const [formDataValidation, setformDataValidation] =
    useState(formValidationData);
  const [dropOffTimeToShow, setDropOffTimeToShow] = useState("");
  const [changeTravelArea, setChangeTravelArea] = useState(false);
  const [changeFlightTracking, setChangeFlightTracking] = useState(false);
  const [flightTracking, setFlightTracking] = useState(null);
  const [isDisabledWithoutFlightTracking, setIsDisabledWithoutFlightTracking] =
    useState(false);
  const [isFromAirport, setIsFromAirport] = useState(false);
  const [isToAirport, setIsToAirport] = useState(false);

  if (lang === "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }

  registerLocale("ja", ja);

  useEffect(() => {
    if (byTheHourForm === null) return;
    setpickuplocation(byTheHourForm.pickuplocation);
    setdropofflocations(byTheHourForm.dropofflocations);
    setTotalPaxValues(byTheHourForm.pax);
    setTotalLugageValues(byTheHourForm.lugage);
    const sfdObj = byTheHourFormData;
    sfdObj.pickuplocation = byTheHourForm.pickuplocation;
    sfdObj.dropofflocations = byTheHourForm.dropofflocations;
    sfdObj.traveldate = byTheHourForm.traveldate;
    sfdObj.pickuptime = byTheHourForm.pickuptime;
    sfdObj.dropofftime = byTheHourForm.dropofftime;
    sfdObj.pax = byTheHourForm.pax;
    sfdObj.lugage = byTheHourForm.lugage;
    sfdObj.duration = byTheHourForm.duration;
    sfdObj.flightTracking = byTheHourForm.flightTracking;
    sfdObj.travelArea = byTheHourForm.travelArea;
    sfdObj.isFromAirport = byTheHourForm.isFromAirport;
    sfdObj.isToAirport = byTheHourForm.isToAirport;
    if (byTheHourForm.flightTracking !== null) {
      setChangeFlightTracking(true)
      setFlightTracking(byTheHourForm.flightTracking)
    }
    if (byTheHourForm.travelArea !== null) {
      setChangeTravelArea(true)
    }

    if (
      byTheHourFormData.traveldate !== null ||
      byTheHourFormData.traveldate !== ""
    ) {
      setTravelDatePicker(new Date(byTheHourFormData.traveldate));
      setFormatedTravelDate(
        moment(byTheHourFormData.traveldate).format("MMM D, yyyy (ddd)")
      );
    }

    if (
      byTheHourForm.dropofflocations.length !== 0 &&
      byTheHourForm.pickuplocation !== ""
    ) {
      checkTotalTime(byTheHourForm);
    }
  }, []);

  useEffect(() => {
    setformDataValidation(formValidationData);
  }, [formValidationData]);

  const setPaxAndLuggaePannalVisibility = (status) => {
    setPassangeVisible(status);
    setLugageVisible(status);
  };

  const searchAvailableVehicles = async () => {
    const st = setFormValidationValues();

    if (st) {
      if (search.pathname === "/") {
        setByTheHourForm();
      } else {
        const sfdObj = byTheHourFormData;
        sfdObj.pickuplocation = pickuplocation;
        sfdObj.dropofflocations = dropofflocations;
        sfdObj.isFromAirport = isFromAirport;
        sfdObj.isToAirport = isToAirport;
        sfdObj.flightTracking = changeFlightTracking ? flightTracking : false;

        checkByTheHourFormAvailableVehicle(sfdObj);

        const dataToPush = {
          event: "search",
          search_term: "Search Available Vehicles",
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);
      }
    }
  };

  const setFormValidationValues = () => {
    let status = true;
    if (
      pickuplocation === undefined ||
      dropofflocations.length === 0 ||
      byTheHourFormData.traveldate === "" ||
      byTheHourFormData.pickuptime === "" ||
      getTotalPaxCount(byTheHourFormData.pax) === 0 ||
      byTheHourFormData.duration === "" ||
      !changeTravelArea ||
      // !changeFlightTracking ||
      changeLuggage === false
    )
      status = false;
    else status = true;
    setformDataValidation({
      pickuplocation: pickuplocation !== undefined ? true : false,
      dropofflocations: dropofflocations.length > 0 ? true : false,
      traveldate: byTheHourFormData.traveldate !== "" ? true : false,
      pickuptime: byTheHourFormData.pickuptime !== "" ? true : false,
      pax: getTotalPaxCount(byTheHourFormData.pax) > 0 ? true : false,
      duration: byTheHourFormData.duration !== "" ? true : false,
      travelArea: changeTravelArea ? true : false,
      // flightTracking: changeFlightTracking ? true : false,
      luggage: changeLuggage === true ? true : false,
    });
    return status;
  };

  const setByTheHourForm = async () => {
    const byTheHourDataObj = {
      pickuplocation: pickuplocation,
      dropofflocations: dropofflocations,
      traveldate: byTheHourFormData.traveldate,
      pickuptime: byTheHourFormData.pickuptime,
      dropofftime: byTheHourFormData.dropofftime,
      pax: byTheHourFormData.pax,
      lugage: byTheHourFormData.lugage,
      duration: byTheHourFormData.duration,
      byTheHourBook: true,
      isToAirport: isToAirport,
      isFromAirport: isFromAirport,
      flightTracking: changeFlightTracking ? flightTracking : false,
      travelArea: byTheHourFormData.travelArea,
    };
    dispatch(addByTheHourFormToState(byTheHourDataObj));
    dispatch(addSingleDestinationFormToState(null));
    dispatch(addMultipleDestinationFormToState(null));
    dispatch(addAirportFormToState(null));
    history.push("/book");
  };

  useEffect(() => {
    if (totalPax > 0) {
      handleChangeValidation("pax", true);
    }
  }, [totalPax]);

  const setTotalPaxValues = (formData) => {
    var summed = getTotalPaxCount(formData);
    setTotalPax(summed);
  };

  const setTotalLugageValues = (formData) => {
    var summed = getTotalLuggageCount(formData);
    setTotalLugage(summed);
  };

  const setPaxFormDataToState = (formData) => {
    var summedPax = getTotalPaxCount(formData);
    setTotalPax(summedPax);
    setByTheHourData({ ...byTheHourFormData, pax: formData });
  };

  const setLugageFormDataToState = (formData) => {
    setChangeLuggage(true);
    setformDataValidation({
      ...formDataValidation,
      luggage: true,
    });
    var summedLugage = getTotalLuggageCount(formData);
    setTotalLugage(summedLugage);
    setByTheHourData({ ...byTheHourFormData, lugage: formData });
  };

  const onChange = (e) => {
    if (e.target.name === "pax") {
      let pax = e.target.value;
      if (e.target.value < 0) {
        pax = 0;
      }
      setTotalPax(pax);
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: pax,
      });
      handleChangeValidation(e.target.name, true);
    } else if (e.target.name === "lugage") {
      let lugage = e.target.value;
      if (e.target.value < 0) {
        lugage = 0;
      }
      setTotalLugage(lugage);
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: lugage,
      });
    } else if (e.target.name === "pickuptime") {
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: e.target.value,
      });

      if (
        byTheHourFormData.dropofflocations.length > 0 &&
        byTheHourFormData.pickuplocation !== "" &&
        byTheHourFormData.pickuptime !== "" &&
        byTheHourFormData.duration !== "" &&
        byTheHourFormData.traveldate !== ""
      ) {
        estimateTimeToDestinationWithDuration(
          byTheHourFormData,
          e.target.value
        );
      } else if (
        byTheHourFormData.dropofflocations.length > 0 &&
        byTheHourFormData.pickuplocation !== ""
      ) {
        estimateTimeToDestination(byTheHourFormData, e.target.value);
      }
      handleChangeValidation(e.target.name, true);
    } else {
      setByTheHourData({
        ...byTheHourFormData,
        [e.target.name]: e.target.value,
      });
      handleChangeValidation(e.target.name, true);
    }
  };

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPassangeVisible(false);
          setLugageVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const checkIsAirport = (pickupLocation, dropoffLocations) => {
    var pickUpStatus = false;
    var dropoffStatus = false;

    if (pickupLocation) {
      if (pickupLocation.types.includes("airport")) pickUpStatus = true;

      const trimmedPlace = pickupLocation.formatted_address
        .toLowerCase()
        .split(",");

      for (let index = 0; index < trimmedPlace.length; index++) {
        if ((trimmedPlace[index].includes("haneda") && trimmedPlace[index].includes("airport")) || (trimmedPlace[index].includes("羽田") && trimmedPlace[index].includes("空港"))) {
          pickUpStatus = true;
        }

        if ((trimmedPlace[index].includes("narita") && trimmedPlace[index].includes("airport"))|| (trimmedPlace[index].includes("成田") && trimmedPlace[index].includes("空港"))) {
          pickUpStatus = true;
        }
      }
    }

    if (dropoffLocations.length > 0) {
      for (let i = 0; i < dropoffLocations.length; i++) {
        if (dropoffLocations[i].types.includes("airport")) dropoffStatus = true;

        const trimmedPlaceDropoff = dropoffLocations[i].formatted_address
          .toLowerCase()
          .split(",");

        for (let ind = 0; ind < trimmedPlaceDropoff.length; ind++) {
          if ((trimmedPlaceDropoff[ind].includes("haneda") && trimmedPlaceDropoff[ind].includes("airport")) || (trimmedPlaceDropoff[ind].includes("羽田") && trimmedPlaceDropoff[ind].includes("空港"))) {
            dropoffStatus = true;
          }

          if ((trimmedPlaceDropoff[ind].includes("narita") && trimmedPlaceDropoff[ind].includes("airport")) || (trimmedPlaceDropoff[ind].includes("成田") && trimmedPlaceDropoff[ind].includes("空港"))) {
            dropoffStatus = true;
          }
        }
      }
    }

    return {
      check: pickUpStatus || dropoffStatus,
      pickupStatus: pickUpStatus,
      dropoffStatus: dropoffStatus,
    };
  };

  useEffect(() => {
    if (!pickuplocation && dropofflocations.length === 0) return;

    const { check, pickupStatus, dropoffStatus } = checkIsAirport(
      pickuplocation,
      dropofflocations
    );

    if (check) {
      const e = {
        target: {
          value: "true",
        },
      };

      setIsFromAirport(pickupStatus);
      setIsToAirport(dropoffStatus);

      onChangeFlightTracking(e);

      setIsDisabledWithoutFlightTracking(true);
    } else {
      setIsDisabledWithoutFlightTracking(false);
      setIsFromAirport(false);
      setIsToAirport(false);
    }
  }, [pickuplocation, dropofflocations]);


  const pickupLocationSelected = async (place) => {
    setByTheHourData({
      ...byTheHourFormData,
      duration: "",
    });
    if (place === undefined) return;
    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    place.lat = place.geometry.location.lat;
    place.lon = place.geometry.location.lng;
    place.label = place.name + ", " + place.formatted_address;
    place.formatted_address = place.name + ", " + place.formatted_address;
    place.value = place.place_id;
    place.city = city;
    place.prefecture = city;
    setpickuplocation(place);
    handleChangeValidation("pickuplocation", true);

  };

  const dropOffLocationSelected = async (place) => {
    setByTheHourData({
      ...byTheHourFormData,
      duration: "",
    });
    if (place === undefined) return;
    const city = place.address_components.find((c) =>
      c.types[0].includes("administrative_area_level_1")
    ).long_name;
    place.lat = place.geometry.location.lat;
    place.lon = place.geometry.location.lng;
    place.label = place.name + ", " + place.formatted_address;
    place.formatted_address = place.name + ", " + place.formatted_address;
    place.value = place.place_id;
    place.city = city;
    place.prefecture = city;
    setdropofflocations([place]);

    handleChangeValidation("dropofflocations", true);
  };

  function populateDurationSelect(minimal_duration) {
    setHalfHourIntervalsData([]);
    const start_from = Math.round(minimal_duration / 30);
    const halfHourIntervals = Array.from({ length: 49 }, (_, i) => {
      const hours = Math.floor(i / 2);
      const minutes = (i % 2) * 30;
      const time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      const label =
        lang === "EN"
          ? `${hours.toString().padStart(2, "0")} Hours ${minutes
            .toString()
            .padStart(2, "0")} Minutes`
          : `${hours.toString().padStart(2, "0")}時間 ${minutes
            .toString()
            .padStart(2, "0")}分`;
      return {
        value: time,
        label: label,
      };
    });
    const final_hour_data = halfHourIntervals.slice(start_from + 2);
    setHalfHourIntervalsData(final_hour_data);
  }

  /**
   * * If the user changes the airport selection dropdown list, this funtion will fire.
   * * Set state variables
   */
  const onChangeDuration = (e) => {
    setByTheHourData({ ...byTheHourFormData, duration: e.value });
    handleChangeValidation("duration", true);
  };

  const onChangeTravelArea = (e) => {
    setByTheHourData({
      ...byTheHourFormData,
      travelArea: e.value,
    });
    handleChangeValidation("travelArea", true);
    setChangeTravelArea(true);
  };

  const onChangeFlightTracking = (e) => {
    setByTheHourData({
      ...byTheHourFormData,
      flightTracking: e.target.value === "true",
    });
    handleChangeValidation("flightTracking", true);
    setChangeFlightTracking(true);
    setFlightTracking(e.target.value === "true")
  };
  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation. Fire when both pickup and dropoff location is filled.
   * ? sfd - Selected Form Data
   * @param sfd
   */
  const checkTotalTime = async (sfd) => {
    console.log(sfd);
    availabilityValueReset();
    const start = sfd.pickuplocation.lat + "%2C" + sfd.pickuplocation.lon;
    const end =
      sfd.dropofflocations[0].lat + "%2C" + sfd.dropofflocations[0].lon;

    const car_r = await getRouteCarForTotalTime(start, end);

    const { total_time } = getSelectedRouteData(car_r);

    populateDurationSelect(total_time);
  };

  /**
   * * Get the selected route's data
   * @param total_time - return parameter - total time in minutes
   */
  const getSelectedRouteData = (car_r) => {
    const summary_arr = car_r[car_r.length - 1].summary;

    const total_time = summary_arr.move.time;

    return {
      total_time,
    };
  };

  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation and paste to drop off time as final estimation dropoff time of customer. Fire when both pickup location, dropoff location, and pickup time is filled.
   * ? sfd - Selected Form Data
   * ? timeString - pickup time in string 'HH:MM'
   * @param sfd
   * @param timeString - not required
   */
  const estimateTimeToDestination = async (sfd, timeString) => {
    availabilityValueReset();
    const start = sfd.pickuplocation.lat + "%2C" + sfd.pickuplocation.lon;
    const end =
      sfd.dropofflocations[0].lat + "%2C" + sfd.dropofflocations[0].lon;

    const car_r = await getRouteCarForTotalTime(start, end);

    const { total_time } = getSelectedRouteData(car_r);

    const timeToAdd = timeString === undefined ? sfd.pickuptime : timeString;

    const [hours, minutes] = timeToAdd.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const newTotalMinutes = (totalMinutes + total_time) % (24 * 60);
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;

    setDropOffTimeToShow(
      `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
        2,
        "0"
      )}`
    );
  };

  /**
   * * Function to get minimal duration count by the selected pickuplocation and dropofflocation and paste to drop off time as final estimation dropoff time of customer. Fire when both pickup location, dropoff location, and pickup time is filled.
   * ? sfd - Selected Form Data
   * ? timeString - pickup time in string 'HH:MM'
   * @param sfd
   * @param timeString - not required
   * @param duration - selected duration
   */
  const estimateTimeToDestinationWithDuration = async (sfd, timeString) => {
    availabilityValueReset();
    const timeToAdd = timeString === undefined ? sfd.pickuptime : timeString;
    const duration = sfd.duration;

    const [hours, minutes] = timeToAdd.split(":").map(Number);
    const [hoursDuration, minutesDuration] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const totalDurations = hoursDuration * 60 + minutesDuration;
    const newTotalMinutes = (totalMinutes + totalDurations) % (24 * 60);
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;

    setDropOffTimeToShow(
      `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
        2,
        "0"
      )}`
    );
  };

  useEffect(() => {
    if (
      byTheHourFormData.dropofflocations.length > 0 &&
      byTheHourFormData.pickuplocation &&
      byTheHourFormData.pickuptime !== "" &&
      byTheHourFormData.duration !== "" &&
      byTheHourFormData.traveldate !== ""
    ) {
      estimateTimeToDestinationWithDuration(byTheHourFormData);
    } else if (
      byTheHourFormData.dropofflocations.length > 0 &&
      byTheHourFormData.pickuplocation !== "" &&
      byTheHourFormData.pickuptime !== ""
    ) {
      estimateTimeToDestination(byTheHourFormData);
    }

    if (
      byTheHourFormData.dropofflocations.length > 0 &&
      byTheHourFormData.pickuplocation
    ) {
      checkTotalTime(byTheHourFormData);
    }
  }, [
    byTheHourFormData.dropofflocations,
    byTheHourFormData.pickuplocation,
    byTheHourFormData.pickuptime,
    byTheHourFormData.duration,
    byTheHourFormData.traveldate,
  ]);

  useEffect(() => {
    setByTheHourData({
      ...byTheHourFormData,
      pickuplocation: pickuplocation,
      dropofflocations: dropofflocations,
    });
  }, [pickuplocation, dropofflocations]);

  const handleChangeValidation = (key, condition) => {
    setformDataValidation((formDataValidation) => ({
      ...formDataValidation,
      [key]: condition,
    }));
  };

  const formatDuration = (durationString) => {
    // Split the duration string into hours and minutes
    const [hours, minutes] = durationString.split(":");

    // Convert hours and minutes to integers
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);

    // Format the label
    const label =
      lang === "EN"
        ? `${hoursInt} Hours ${minutesInt < 10 ? "0" : ""}${minutesInt} Minutes`
        : `${hoursInt}時間 ${minutesInt < 10 ? "0" : ""}${minutesInt}分`;

    // Return the formatted object
    return { value: durationString, label };
  };

  const dateTimeContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    );
  };

  const formatTravelArea = (travelArea) => {
    if (!travelArea) return;
    // Format the label
    var label;

    if (travelArea === "undecided") label = t("ByTheHour.TravelArea.Undecided");
    if (travelArea === "withinCity") label = t("ByTheHour.TravelArea.WithinCity");
    if (travelArea === "neighboringPerfecture") label = t("ByTheHour.TravelArea.NeighboringPerfecture");

    // Return the formatted object
    return { value: travelArea, label };
  };

  const handleChangeRaw = (date) => {

      //add collon after second number but make logic if user want to delete text
      if (date.length == 2 && date.includes(":") == false) {
        date = date + ":";
        
      }else if(date.length == 3 && date.includes(":") == true){
        date = date.substring(0, date.length - 1);
      }
      if(date.length == 5 && date.includes(":") && date.split(":")[0] <= 24 && date.split(":")[1] <= 60){
        
        if(date.split(":")[0] == 24 && date.split(":")[1] > 0){
          handleChangeValidation("pickuptime", false);
        }else{
          handleChangeValidation("pickuptime", true);
        }
      }else{
        handleChangeValidation("pickuptime", false);
      }
      console.log(date);
      setByTheHourData({
        ...byTheHourFormData,
        pickuptime: date.isempty ? "" : date,
      });
};

  return (
    <div className="bg-white p-3 p-md-3 mb-5 card-form-custom">
      {/* FIRST LINE INPUT */}
      <div className="row g-3 mb-3">
        {/* PICKUP ADDRESS */}
        <div className="col-12 input-box">
          <div className="form-floating position-relative">
            <CustomGoogleAutoComplete
              id="pickUpAddress"
              onPlaceSelected={pickupLocationSelected}
              defaultValue={
                pickuplocation?.formatted_address
                  ? pickuplocation?.formatted_address
                  : null
              }
              invalidText={"SpecifiedDestination.Pickup.Specific.Validation"}
              isInvalid={!formDataValidation.pickuplocation}
              placeholder="Pick-up address"
              ariaLabel="Pick-up address"
            />
            <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
              <div className="d-flex justify-content-center">
                <img  alt="img" src={LocationPinIcon} />
              </div>
            </span>
            <label for="floatingInput" className="label-icon">
              {t("Pick.Up.Address")}
            </label>
          </div>
          {!formDataValidation.pickuplocation ? (
            <p className="text-danger">
              {t("SpecifiedDestination.Pickup.Specific.Validation")}
            </p>
          ) : (
            ""
          )}
        </div>

        {/* DROPOFF ADDRESS DESKTOP */}
        <div className="col-12 input-box">
          <div className="form-floating position-relative">
            <CustomGoogleAutoComplete
              id="dropoff1"
              onPlaceSelected={dropOffLocationSelected}
              defaultValue={
                byTheHourFormData?.dropofflocations[0]?.formatted_address !==
                  undefined
                  ? byTheHourFormData?.dropofflocations[0]?.formatted_address
                  : byTheHourForm?.dropofflocations[0]?.formatted_address !==
                    undefined
                    ? byTheHourForm?.dropofflocations[0]?.formatted_address
                    : null
              }
              invalidText={"SpecifiedDestination.Dropoff.Specific.Validation"}
              isInvalid={formDataValidation.dropofflocations ? false : true}
              placeholder="Drop-off address"
              ariaLabel="Drop-off address"
            />
            <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
              <div className="d-flex justify-content-center">
                <img  alt="img" src={LocationPinIcon} />
              </div>
            </span>
            <label for="floatingInput" className="label-icon">
              {t("Drop.Off.Address")}
            </label>
          </div>

          {!formDataValidation.pickuplocation ? (
            <p className="text-danger">
              {t("SpecifiedDestination.Dropoff.Specific.Validation")}
            </p>
          ) : (
            ""
          )}
        </div>

        {/* USAGE DURATION DESKTOP*/}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-lg-block ">
          <div className="form-floating">
            {byTheHourFormData?.duration !== "" ? (
              <Select
                // type="select"
                noOptionsMessage={() =>
                  t("booking_by_the_hour.select_duration")
                }
                name="duration"
                classNamePrefix="react-select-time"
                className={formDataValidation.duration ? "" : "invalid"}
                styles={{
                  control: (baseStyles, isActive) => ({
                    ...baseStyles,
                    paddingTop: "",
                    paddingLeft: "8px",
                    paddingBotton: "0.75rem",
                    border: "none",
                    borderBottom: "1px solid #DBBE78",
                    borderColor: "#dbbe78",
                    height: "calc(3.5rem + 2px)",
                    borderRadius: "0.3rem",
                    background: "rgba(167, 167, 167, 0.1)",
                    fontSize: "14px",
                    color: byTheHourFormData.duration ? "#282828" : "",
                    fontWeight: byTheHourFormData.duration ? "bolder" : "",
                  }),
                }}
                autoComplete="off"
                placeholder={t("Usage Duration (e.g., 2 hours)")}
                value={byTheHourFormData.duration != "" ? formatDuration(byTheHourFormData?.duration) : ""}
                onChange={(e) => {
                  onChangeDuration(e);
                  availabilityValueReset();
                }}
                options={halfHourIntervalsData}
              />
            ) : (
              <Select
                noOptionsMessage={() =>
                  t("booking_by_the_hour.select_duration")
                }
                // type="select"
                name="duration"
                classNamePrefix="react-select-time"
                className={formDataValidation.duration ? "" : "invalid"}
                styles={{
                  control: (baseStyles, isActive) => ({
                    ...baseStyles,
                    paddingTop: "",
                    paddingLeft: "8px",
                    paddingBotton: "0.75rem",
                    border: "none",
                    borderBottom: "1px solid #DBBE78",
                    borderColor: "#dbbe78",
                    height: "calc(3.5rem + 2px)",
                    borderRadius: "0.3rem",
                    background: "rgba(167, 167, 167, 0.1)",
                    fontSize: "14px",
                  }),
                }}
                autoComplete="off"
                placeholder={t("Usage Duration (e.g., 2 hours)")}
                onChange={(e) => {
                  onChangeDuration(e);
                  availabilityValueReset();
                }}
                value=""
                options={halfHourIntervalsData}
              />
            )}
            {!formDataValidation.duration && (
              <p className="text-danger">{t("UsageDurationValidation")}</p>
            )}
          </div>
        </div>

        {/* TRAVEL DATE */}

        <div className="col-12 col-sm-12 col-md-6  ">
          <DatePicker
            locale={lang === "EN" ? "en" : "ja"}
            selected={travelDatePicker}
            minDate={new Date()}
            calendarContainer={dateTimeContainer}
            onSelect={(date) => {
              var formatedDate = date.toLocaleDateString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              setTravelDatePicker(date);
              var tge = moment(formatedDate);
              if (lang === "EN") {
                setFormatedTravelDate(tge.format("MMM. Do, yyyy (ddd)"));
              } else {
                setFormatedTravelDate(tge.format("YYYY年M月D日（ddd）"));
              }
              availabilityValueReset();
              handleChangeValidation("traveldate", true);
              setByTheHourData({
                ...byTheHourFormData,
                traveldate: formatedDate,
              });
            }}
            customInput={
              <div className="form-floating position-relative">
                <input
                  readOnly={true}
                  autoComplete="off"
                  type="text"
                  name="traveldate"
                  className={
                    formDataValidation.traveldate
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                  style={{
                    borderColor: !formDataValidation.traveldate ? "red" : "",
                    color: byTheHourFormData.traveldate ? "#282828" : "",
                    fontWeight: byTheHourFormData.traveldate ? "bold" : "",
                  }}
                  placeholder="Travel date"
                  aria-label="Travel date"
                  defaultValue={formatedTravelDate}
                />

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img  alt="img" src={DateIcon} />
                  </div>
                </span>
                <label for="floatingInput" className="label-icon">
                  {t("Travel.Date")}
                </label>
              </div>
            }
          />

          {formDataValidation.traveldate === false && (
            <p className="text-danger">
              {t("SpecifiedDestination.TravelDate.Validation")}
            </p>
          )}
        </div>

        {/* PICKUP TIME */}
        <div className="col-12 col-sm-12 col-md-6 ">
          <DatePicker
            locale={lang === "EN" ? "en" : "ja"}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption={t("Pick.Up.Time")}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            calendarContainer={dateTimeContainer}
            onChange={(date, event) => {
              if(event == undefined){
                  var formatedDate = date.toLocaleDateString("sv-SE", {
                  hour: '2-digit',
                  minute: '2-digit'
                });
                var tge = moment(formatedDate);
                setByTheHourData({
                  ...byTheHourFormData,
                  pickuptime: tge.format("HH:mm"),
                });
                handleChangeValidation("pickuptime", true);
                availabilityValueReset();
              }
            }}
            onChangeRaw={(event) => handleChangeRaw(event.target.value)}
            customInput={
              <div className="form-floating position-relative">
                <input
                  type="text"
                  name="pickuptime"
                  maxLength={5}
                  style={{
                    borderColor: !formDataValidation.pickuptime ? "red" : "",
                    color: byTheHourFormData.pickuptime ? "#282828" : "",
                    fontWeight: byTheHourFormData.pickuptime ? "bold" : "",
                  }}
                  className={
                    formDataValidation.traveldate
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                  placeholder="Pick-up time"
                  aria-label="Pick-up time"
                  value={
                    byTheHourFormData?.pickuptime
                  }
                />

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img  alt="img" src={ClockIcon} />
                  </div>
                </span>
                <label for="floatingInput" className="label-icon">
                  {t("Pick.Up.Time")}
                </label>
              </div>
            }
          />
          {formDataValidation.pickuptime === false && (
            <p className="text-danger">
              {t("AirportTransfer.PickupTime.Required")}
            </p>
          )}

          {dropOffTimeToShow && (
            <p className="mt-1 mb-0 p-0">
              {" "}
              <span style={{ color: "#A7A7A7", fontWeight: "400" }}>
                Est. Drop-off Time:{" "}
              </span>
              <span style={{ color: "#A7A7A7", fontWeight: "700" }}>
                {dropOffTimeToShow}
              </span>{" "}
            </p>
          )}
        </div>

        {/* PAX AND LUGAGE */}
        <div
          ref={wrapperRef}
          className="col-lg-12 col-md-12 col-sm-12 position-relative "
        >
          <div className="row">
            <div className="col pe-1 ">
              <div className="form-floating position-relative">
                <input
                  readOnly={true}
                  onClick={() => {
                    setPassangeVisible(!passengerVisible);
                    setLugageVisible(false);
                  }}
                  style={{
                    borderColor: !formDataValidation.pax ? "red" : "",
                    color: totalPax > 0 ? "#282828" : "",
                    fontWeight: totalPax > 0 ? "bold" : "",
                  }}
                  name="pax"
                  type="number"
                  id="passengers"
                  placeholder="Passengers"
                  aria-label="Passengers"
                  min={0}
                  value={totalPax ?? 0}
                  className={
                    formDataValidation.pax
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                />

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img  alt="img" src={PaxIcon} />
                  </div>
                </span>
                <label for="floatingInput" className="label-icon">
                  {t("Passengers")}
                </label>
              </div>
              {formDataValidation.pax === false && (
                <p className="text-danger">
                  {t("SpecifiedDestination.Passenger.Validation")}
                </p>
              )}
            </div>

            <div className="col ">
              <div className="form-floating position-relative">
                <input
                  readOnly={true}
                  onClick={() => {
                    setLugageVisible(!lugageVisible);
                    setPassangeVisible(false);
                  }}
                  name="lugage"
                  style={{
                    color: totalLugage > 0 ? "#282828" : "",
                    fontWeight: totalLugage > 0 ? "bold" : "",
                  }}
                  type={
                    (formDataValidation.luggage == null &&
                      changeLuggage === false) ||
                      (formDataValidation.luggage === false &&
                        changeLuggage === false)
                      ? "text"
                      : "number"
                  }
                  placeholder="Lugages"
                  aria-label="Lugages"
                  min={0}
                  value={
                    (formDataValidation.luggage == null &&
                      changeLuggage === false) ||
                      (formDataValidation.luggage === false &&
                        changeLuggage === false)
                      ? "-"
                      : totalLugage ?? 0
                  }
                  className={
                    formDataValidation.luggage ||
                      formDataValidation.luggage == null ||
                      changeLuggage === true
                      ? "form-control form-control-icon"
                      : "form-control form-control-icon invalid"
                  }
                />

                <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                  <div className="d-flex justify-content-center">
                    <img  alt="img" src={LuggageIcon} />
                  </div>
                </span>
                {lugageVisible === true ? (
                  <PassengerCard
                    setPaxPanel={setPaxAndLuggaePannalVisibility}
                    setFormDataToState={setLugageFormDataToState}
                    passengerFormData={byTheHourFormData.lugage}
                    passangerForm={false}
                    luggageForm={true}
                  />
                ) : (
                  <></>
                )}
                {passengerVisible === true ? (
                  <PassengerCard
                    setPaxPanel={setPaxAndLuggaePannalVisibility}
                    setFormDataToState={setPaxFormDataToState}
                    passengerFormData={byTheHourFormData.pax}
                    passangerForm={true}
                    luggageForm={false}
                  />
                ) : (
                  <></>
                )}
                <label className="label-icon">{t("Luggage")}</label>
              </div>
            </div>
          </div>
        </div>

        {/* TRAVEL AREA DESKTOP */}
        <div className="col-12 d-lg-block d-none ">
          <div>
            <Select
              placeholder={t("ByTheHour.TravelArea.Title")}
              classNamePrefix="react-select-travel-area"
              className={
                formDataValidation.travelArea == null ||
                  formDataValidation.travelArea
                  ? ""
                  : "invalid"
              }
              styles={{
                control: (baseStyles, isActive) => ({
                  ...baseStyles,
                  paddingTop: "",
                  paddingLeft: "8px",
                  paddingBotton: "0.75rem",
                  border: "none",
                  borderBottom: "1px solid #DBBE78",
                  borderColor: "#dbbe78",
                  height: "calc(3.5rem + 2px)",
                  borderRadius: "0.3rem",
                  background: "rgba(167, 167, 167, 0.1)",
                  fontSize: "14px",
                }),
              }}
              autoComplete="off"
              onChange={(e) => {
                // onChangeAirport(e);
                onChangeTravelArea(e);
                availabilityValueReset();
              }}
              value={formatTravelArea(byTheHourFormData?.travelArea)}
              getOptionLabel={option => t(option.label)}
              options={travelAreaOptions}
            // defaultValue={airportTypeOptions[0]}
            />
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        {/* TRAVEL AREA & AIRPORT TRANSFER DESKTOP */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none">
          <div className="d-flex gap-3">
            <div className="d-flex align-items-center px-2 d-inline">
              <i className="pe-2 pb-1">
                <img  alt="img" src={depature_flight_icon} />
              </i>
              <div className="pe-4">
                <label className="noto ">
                  {t("ByTheHour.FlightTrackingRequest.Title")}
                </label>
              </div>

              <div className="form-check pe-3">
                <input
                  disabled={dropofflocations.length > 1 ? true : false}
                  className={
                    formDataValidation.flightTracking == null ||
                      formDataValidation.flightTracking
                      ? "form-check-input"
                      : "form-check-input invalid"
                  }
                  type="radio"
                  name="flightTracking"
                  id="flexRadioDefault1"
                  onChange={onChangeFlightTracking}
                  value={true}
                  checked={changeFlightTracking && flightTracking === true}
                />
                <label
                  className="form-check-label noto"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Yes")}
                </label>
              </div>

              <div className="form-check pe-3">
                <input
                  className={
                    formDataValidation.flightTracking == null ||
                      formDataValidation.flightTracking
                      ? "form-check-input"
                      : "form-check-input invalid"
                  }
                  type="radio"
                  name="flightTracking"
                  id="flexRadioDefault2"
                  onChange={onChangeFlightTracking}
                  value={false}
                  checked={changeFlightTracking && flightTracking === false}
                  disabled={isDisabledWithoutFlightTracking}
                />
                <label
                  className="form-check-label noto"
                  htmlFor="flexRadioDefault2"
                >
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
          {formDataValidation.flightTracking == null ||
            formDataValidation.flightTracking ? (
            ""
          ) : (
            <p className="text-danger pb-2">
              {t("ByTheHour.TravelArea.Validation")}
            </p>
          )}
        </div>

        {/* TRAVEL AREA MOBILE */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-lg-none d-sm-block">
          <div>
            <Select
              placeholder={t("ByTheHour.TravelArea.Title")}
              classNamePrefix="react-select-travel-area"
              className={
                formDataValidation.travelArea == null ||
                  formDataValidation.travelArea
                  ? ""
                  : "invalid"
              }
              styles={{
                control: (baseStyles, isActive) => ({
                  ...baseStyles,
                  paddingTop: "",
                  paddingLeft: "8px",
                  paddingBotton: "0.75rem",
                  border: "none",
                  borderBottom: "1px solid #DBBE78",
                  borderColor: "#dbbe78",
                  height: "calc(3.5rem + 2px)",
                  borderRadius: "0.3rem",
                  background: "rgba(167, 167, 167, 0.1)",
                  fontSize: "14px",
                }),
              }}
              autoComplete="off"
              onChange={(e) => {
                // onChangeAirport(e);
                onChangeTravelArea(e);
                availabilityValueReset();
              }}
              getOptionLabel={option => t(option.label)}
              value={formatTravelArea(byTheHourFormData?.travelArea)}
              options={travelAreaOptions}
            // defaultValue={airportTypeOptions[0]}
            />
          </div>
        </div>

        {/* IS AIRPORT MOBILE */}
        <div
          className={`col-12 col-sm-12 col-md-12 col-lg-6 d-none ${formDataValidation.flightTracking == null ||
              formDataValidation.flightTracking
              ? ""
              : "outline-red"
            }`}
        >
          <div className="d-flex gap-3 mb-2">
            <i className="ps-2">
             <img  alt="img" src={depature_flight_icon}/>
            </i>
            <div>
              <h5 className="form-floating-radio-text">
                {t("ByTheHour.FlightTrackingRequest.Title")}
              </h5>
            </div>
          </div>
          <div className="row d-flex justify-content-between mb-2">
            <div className="col-6 d-grid">
              {/* <button className={`form-check btn ${!changeFlightTracking ? 'btn-outline-default' : changeFlightTracking && flightTracking == true ? 'btn-outline-checked' : 'btn-outline-unchecked'} btn-lg rounded-3 w-full`} value={"true"} onClick={onChangeFlightTracking}>
                {t("Yes")}
              </button> */}
              <button
                type="button"
                className={`form-check btn btn-lg rounded-3 w-full text-start ${!changeFlightTracking ? 'btn-outline-default' : changeFlightTracking && flightTracking === true ? 'btn-outline-checked' : 'btn-outline-unchecked'} btn-lg rounded-3 w-full`}
                onClick={onChangeFlightTracking}
                value={"true"}
              >
                <input
                  disabled={dropofflocations.length > 1 ? true : false}
                  className={
                    formDataValidation.flightTracking == null ||
                      formDataValidation.flightTracking
                      ? "form-check-input ms-0"
                      : "form-check-input ms-0 invalid"
                  }
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  onChange={onChangeFlightTracking}
                  value={true}
                  checked={
                    changeFlightTracking && flightTracking === true
                      ? true
                      : false
                  }
                />
                <label
                  className="form-check-label noto ps-2"
                  htmlFor="flexRadioDefault1"
                >
                  {t("Yes")}
                </label>
              </button>
            </div>
            <div className="col-6 d-grid">
              {/* <button className={`form-check btn ${!changeFlightTracking ? 'btn-outline-default' : changeFlightTracking && flightTracking == false ? 'btn-outline-checked' : 'btn-outline-unchecked'} btn-lg rounded-3 w-full`} value={"false"} onClick={onChangeFlightTracking} disabled={isDisabledWithoutFlightTracking}>
                {t("No")}
              </button> */}
              <button
                type="button"
                className={`form-check btn btn-lg rounded-3 w-full text-start ${!changeFlightTracking ? 'btn-outline-default' : changeFlightTracking && flightTracking === false ? 'btn-outline-checked' : 'btn-outline-unchecked'} btn-lg rounded-3 w-full`}
                value={"false"}
                onClick={onChangeFlightTracking}
                disabled={isDisabledWithoutFlightTracking}
              >
                <input
                  disabled={dropofflocations.length > 1 ? true : false}
                  className={
                    formDataValidation.flightTracking == null ||
                      formDataValidation.flightTracking
                      ? "form-check-input ms-0"
                      : "form-check-input ms-0 invalid"
                  }
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onChange={onChangeFlightTracking}
                  value={false}
                  checked={
                    changeFlightTracking && flightTracking === false
                      ? true
                      : false
                  }
                />
                <label
                  className="form-check-label noto ps-2"
                  htmlFor="flexRadioDefault2"
                >
                  {t("No")}
                </label>
              </button>
            </div>
          </div>
          {formDataValidation.flightTracking == null ||
            formDataValidation.flightTracking ? (
            ""
          ) : (
            <p className="text-danger pb-2">
              {t("ByTheHour.TravelArea.Validation")}
            </p>
          )}
        </div>
      </div>

      {/* FOURTH LINE INPUT */}
      <div className="d-grid ">
        <button
          disabled={isLoading}
          type="button"
          className="btn btn-primary btn-sm rounded-3  d-flex justify-content-center px-md-5 py-2 fs-14"
          onClick={searchAvailableVehicles}
        >
          <div className="d-flex align-items-center px-2 d-inline text-white">
            {isLoading ? (
              <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                {t("Search.Available.Vehicles")}
                <i className="ps-2">
                  <img src={SearchVehicleIcon} alt="" />
                </i>
              </>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default ByTheHourForm;
