import {
  ADD_VEHICLE_TYPE,
  VEHICLE_TYPE_ERROR,
  GET_VEHICLE_TYPE,
  GET_VEHICLE_TYPES,
} from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const getAllVehicleTypes = async () => {
  try {
    const res = await customAxios.get("/api/admin/vehicletypes");
    return res.data;
  } catch (err) {
    return [];
  }
};

// get vehicle by id
export const getVehicleType = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/admin/vehicletypes/${id}`);
    dispatch({
      type: GET_VEHICLE_TYPE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VEHICLE_TYPE_ERROR,
      payload: { msg: err.response.statusText, status: err.response },
    });
  }
};

// get vehicle types
export const getVehicleTypes = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/vehicletypes");
    dispatch({
      type: GET_VEHICLE_TYPES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VEHICLE_TYPE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// add driver
export const addVehicleType =
  (formdata, history, edit = false) =>
  async (dispatch) => {
    console.log(formdata);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await customAxios.post(
        "/api/admin/vehicletypes",
        formdata,
        config
      );
      dispatch({
        type: ADD_VEHICLE_TYPE,
        payload: res.data,
      });
      dispatch(
        setAlert(
          edit ? "Vehicle type Updated" : "Vehicle type created",
          "success"
        )
      );
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        dispatch(
          setAlert(
            "Error.. " +
              errors.codeName +
              " " +
              JSON.stringify(errors.keyValue),
            "danger"
          )
        );
      }
      dispatch({
        type: VEHICLE_TYPE_ERROR,
        payload: { msg: err.response, status: err.response },
      });
    }
  };
