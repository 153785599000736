import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import Layout from "../../layout/Layout";
import Alert from "../../layout/Alert";
import TitleBar from "../../layout/TitleBar";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCMSPageContent,
  getPageContents,
} from "../../../actions/admin/cmspage";

const CMSAlphard = () => {
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage } = cmspage_state;
  const page_name = "cms-alphard";
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    pagename: page_name,
    page_content_en: {
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
      content11: "",
      content12: "",
      content13: "",
      content14: "",
      content15: "",
      content16: "",
      content17: "",
      content18: "",
      content19: "",
      content20: "",
      content21: "",
      content22: "",
      content23: "",
      content24: "",
      content25: "",
      content26: "",
      content27: "",
      content28: "",
      content29: "",
      content30: "",
      content31: "",
      content32: "",
      content33: "",
      content34: "",
      content35: "",
      content36: "",
      content37: "",
      content38: "",
      content39: "",
      content40: "",
      content41: "",
      content42: "",
      content43: "",
      content44: "",
      content45: "",
      content46: "",
      content47: "",
    },
    page_content_jp: {
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      content10: "",
      content11: "",
      content12: "",
      content13: "",
      content14: "",
      content15: "",
      content16: "",
      content17: "",
      content18: "",
      content19: "",
      content20: "",
      content21: "",
      content22: "",
      content23: "",
      content24: "",
      content25: "",
      content26: "",
      content27: "",
      content28: "",
      content29: "",
      content30: "",
      content31: "",
      content32: "",
      content33: "",
      content34: "",
      content35: "",
      content36: "",
      content37: "",
      content38: "",
      content39: "",
      content40: "",
      content41: "",
      content42: "",
      content43: "",
      content44: "",
      content45: "",
      content46: "",
      content47: "",
    },
  });

  useEffect(() => {
    if (cmspage?.pagename === page_name) setFormData(cmspage);
  }, [cmspage]);

  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  const onChangeEn = (e) =>
    setFormData({
      ...formData,
      page_content_en: {
        ...formData.page_content_en,
        [e.target.name]: e.target.value,
      },
    });

  const onChangeJp = (e) =>
    setFormData({
      ...formData,
      page_content_jp: {
        ...formData.page_content_jp,
        [e.target.name]: e.target.value,
      },
    });

  const onUpdateData = (e) => {
    e.preventDefault();
    dispatch(updateCMSPageContent(formData));
  };

  return (
    <LoadingOverlay active={false} spinner={<ScaleLoader color="#36D7B7" />}>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="CMS | alphard" />
          <div className="container-fluid">
            <form onSubmit={(e) => {}}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Main Title (small) (en):
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content1"
                        value={formData?.page_content_en.content1}
                        onChange={(e) => onChangeEn(e)}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Main Title (large){" "}
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content2"
                        value={formData?.page_content_en.content2}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Page Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content3"
                        value={formData?.page_content_en.content3}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 1 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content4"
                        value={formData?.page_content_en.content4}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 2 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content5"
                        value={formData?.page_content_en.content5}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 3 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content6"
                        value={formData?.page_content_en.content6}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 4 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content7"
                        value={formData?.page_content_en.content7}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content8"
                        value={formData?.page_content_en.content8}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Heading (large){" "}
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content9"
                        value={formData?.page_content_en.content9}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      {" "}
                      Section 1 - Subsection 1 - Heading{" "}
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content10"
                        value={formData?.page_content_en.content10}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 1 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content11"
                        value={formData?.page_content_en.content11}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      hSection 1 - Subsection 2 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content12"
                        value={formData?.page_content_en.content12}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 1 (PAX)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content13"
                        value={formData?.page_content_en.content13}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 2 (Charger)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content14"
                        value={formData?.page_content_en.content14}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 3 (TV)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content15"
                        value={formData?.page_content_en.content15}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 4 (Suitcases)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content16"
                        value={formData?.page_content_en.content16}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 5 (WiFi)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content17"
                        value={formData?.page_content_en.content17}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 6 (DVD Player)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content18"
                        value={formData?.page_content_en.content18}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 3 - Title (Video)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content19"
                        value={formData?.page_content_en.content19}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 3 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content20"
                        value={formData?.page_content_en.content20}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 3 - Youtube Embed Link
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content21"
                        value={formData?.page_content_en.content21}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Section 2 - Heading</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content22"
                        value={formData?.page_content_en.content22}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content23"
                        value={formData?.page_content_en.content23}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Heading(large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content24"
                        value={formData?.page_content_en.content24}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content25"
                        value={formData?.page_content_en.content25}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content26"
                        value={formData?.page_content_en.content26}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 1 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content27"
                        value={formData?.page_content_en.content27}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 1 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content28"
                        value={formData?.page_content_en.content28}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 2 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content29"
                        value={formData?.page_content_en.content29}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 2 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content30"
                        value={formData?.page_content_en.content30}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 3 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content31"
                        value={formData?.page_content_en.content31}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 3 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content32"
                        value={formData?.page_content_en.content32}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content33"
                        value={formData?.page_content_en.content33}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content34"
                        value={formData?.page_content_en.content34}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 1 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content35"
                        value={formData?.page_content_en.content35}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 1 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content36"
                        value={formData?.page_content_en.content36}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 2 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content37"
                        value={formData?.page_content_en.content37}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 2 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content38"
                        value={formData?.page_content_en.content38}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 3 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content39"
                        value={formData?.page_content_en.content39}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 3 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content40"
                        value={formData?.page_content_en.content40}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content41"
                        value={formData?.page_content_en.content41}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content42"
                        value={formData?.page_content_en.content42}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Point 1 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content43"
                        value={formData?.page_content_en.content43}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Point 2 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content44"
                        value={formData?.page_content_en.content44}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">CTA - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content45"
                        value={formData?.page_content_en.content45}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">CTA - Content</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content46"
                        value={formData?.page_content_en.content46}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">CTA - Button Text</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content47"
                        value={formData?.page_content_en.content47}
                        onChange={(e) => onChangeEn(e)}
                        required
                      />
                    </div>
                  </div>

                  {/* second cards */}
                </div>

                {/*  */}
                <div className="col-md-6 bg-light">
                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Main Title (small) (jp):
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content1"
                        value={formData?.page_content_jp.content1}
                        onChange={(e) => onChangeJp(e)}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Main Title (large){" "}
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content2"
                        value={formData?.page_content_jp.content2}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Hero - Page Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content3"
                        value={formData?.page_content_jp.content3}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 1 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content4"
                        value={formData?.page_content_jp.content4}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 2 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content5"
                        value={formData?.page_content_jp.content5}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 3 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content6"
                        value={formData?.page_content_jp.content6}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">
                      Secondary Nav - Tab 4 Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content7"
                        value={formData?.page_content_jp.content7}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content8"
                        value={formData?.page_content_jp.content8}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Heading (large){" "}
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content9"
                        value={formData?.page_content_jp.content9}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      {" "}
                      Section 1 - Subsection 1 - Heading{" "}
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content10"
                        value={formData?.page_content_jp.content10}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 1 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content11"
                        value={formData?.page_content_jp.content11}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      hSection 1 - Subsection 2 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content12"
                        value={formData?.page_content_jp.content12}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 1 (PAX)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content13"
                        value={formData?.page_content_jp.content13}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 2 (Charger)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content14"
                        value={formData?.page_content_jp.content14}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 3 (TV)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content15"
                        value={formData?.page_content_jp.content15}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 4 (Suitcases)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content16"
                        value={formData?.page_content_jp.content16}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 5 (WiFi)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content17"
                        value={formData?.page_content_jp.content17}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 2 - Feature 6 (DVD Player)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content18"
                        value={formData?.page_content_jp.content18}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 3 - Title (Video)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content19"
                        value={formData?.page_content_jp.content19}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 3 - Content
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content20"
                        value={formData?.page_content_jp.content20}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 1 - Subsection 3 - Youtube Embed Link
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content21"
                        value={formData?.page_content_jp.content21}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Section 2 - Heading</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content22"
                        value={formData?.page_content_jp.content22}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Heading (small)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content23"
                        value={formData?.page_content_jp.content23}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Heading(large)
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content24"
                        value={formData?.page_content_jp.content24}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content25"
                        value={formData?.page_content_jp.content25}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content26"
                        value={formData?.page_content_jp.content26}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 1 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content27"
                        value={formData?.page_content_jp.content27}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 1 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content28"
                        value={formData?.page_content_jp.content28}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 2 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content29"
                        value={formData?.page_content_jp.content29}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 2 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content30"
                        value={formData?.page_content_jp.content30}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 3 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content31"
                        value={formData?.page_content_jp.content31}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 1 - Point 3 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content32"
                        value={formData?.page_content_jp.content32}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content33"
                        value={formData?.page_content_jp.content33}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content34"
                        value={formData?.page_content_jp.content34}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 1 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content35"
                        value={formData?.page_content_jp.content35}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 1 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content36"
                        value={formData?.page_content_jp.content36}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 2 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content37"
                        value={formData?.page_content_jp.content37}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 2 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content38"
                        value={formData?.page_content_jp.content38}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 3 - Title
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content39"
                        value={formData?.page_content_jp.content39}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 2 - Point 3 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content40"
                        value={formData?.page_content_jp.content40}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Heading
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content41"
                        value={formData?.page_content_jp.content41}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Description
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content42"
                        value={formData?.page_content_jp.content42}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Point 1 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content43"
                        value={formData?.page_content_jp.content43}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">
                      Section 2 - Subsection 1 - Tab 3 - Point 2 - Value
                    </label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content44"
                        value={formData?.page_content_jp.content44}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">CTA - Title</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content45"
                        value={formData?.page_content_jp.content45}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">CTA - Content</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content46"
                        value={formData?.page_content_jp.content46}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">CTA - Button Text</label>
                    <div className="col">
                      <textarea
                        type="text"
                        className="form-control form-control-admin"
                        name="content47"
                        value={formData?.page_content_jp.content47}
                        onChange={(e) => onChangeJp(e)}
                        required
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={onUpdateData}
                      >
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update content
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </form>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default CMSAlphard;
