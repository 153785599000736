import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const GarageItem = ({
    garage: {
        _id,
        name,
        prefecture,
        lon,
        lat,
        isActive,
        defaultGarage,
        availableVehicleTypes,
        date,
    },
    removeGarage,
    count
}) => {
    return (
        <tr>
            <td>{count + 1}</td>
            <td>{name}</td>
            <td>
                {prefecture.map((l) => (
                    <div key={l.value}>{l.value}</div>
                ))}
            </td>
            <td>{`${lat}, ${lon}`}</td>
            <td>
                {
                    availableVehicleTypes.map((v) => (
                        <div key={v._id}>{v.typename}</div>
                    ))
                }
            </td>
            <td>
                {isActive ? (
                    <span className="badge bg-success">Active</span>
                ) : (
                    <span className="badge bg-secondary">Inactive</span>
                )}
            </td>
            <td>
                {defaultGarage ? (
                    <span className="badge bg-primary">Default</span>
                ) : ""}
            </td>
            <td className="px-0">
                <Link
                    type="button"
                    className="btn btn-secondary py-1 px-3"
                    to={`/admin/garagelist/edit/${_id}`}
                >
                    Edit
                </Link>
            </td>
            <td className="ps-1">
                <button
                    type="button"
                    className="btn btn-outline-danger py-1 px-3"
                    data-bs-toggle="modal"
                    data-bs-target={`#deleteModal${count}`}
                >
                    Delete
                </button>
                <div
                    className="modal fade"
                    id={`deleteModal${count}`}
                    tabIndex="-1"
                    aria-labelledby="deleteModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModalLabel">
                                    Warning!
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this garage?
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        removeGarage(_id);
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default GarageItem;
