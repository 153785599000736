import {
  REGISTER_SUCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  ACCOUNT_DELETED,
  ALL_USERS,
  EDIT_USER,
  LOGOUT_ADMIN
} from "../actions/types";
import jwtDecode from "jwt-decode";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  users: null,
  edituser: null,
};

function isAdminToken(token) {
  const decodedJwt = jwtDecode(token);
  if (decodedJwt.user.id.role === "admin" || decodedJwt.user.id.role === undefined) {
    return true;
  } else if (decodedJwt.user.id.role === "individual" || decodedJwt.user.id.role === "business") {
    return false;
  }
}

export default function foo(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EDIT_USER:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedAdmin: true,
        loading: false,
        edituser: payload,
      };
    case ALL_USERS:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedAdmin: true,
        loading: false,
        users: payload,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: (payload.role == "individual" || payload.role == "business") ? true : false,
        isAuthenticatedAdmin: (payload.role == "individual" || payload.role == "business") ? false : true,
        loading: false,
        user: payload,
      };
    case REGISTER_SUCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload?.token);
      return {
        ...state,
        token: payload?.token,
        isAuthenticated: !isAdminToken(payload?.token),
        isAuthenticatedAdmin: isAdminToken(payload?.token),
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
      return {...state, loading : false};
    case LOGIN_FAIL:
    case LOGOUT:
    case LOGOUT_ADMIN:
      localStorage.removeItem("token");
      return {
        ...state,
        token : null,
        isAuthenticated: false,
        isAuthenticatedAdmin: false,
        loading: false,
        user: null,
      };
    case ACCOUNT_DELETED:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}
