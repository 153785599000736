import React, { useState } from "react";
// import { Link } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Select from "react-select";
import Layout from "../../layout/Layout";
import { addVehicleType } from "../../../actions/admin/vehicletype";
import Alert from "../../layout/Alert";
import { vehicleTypeImageOptions } from "../../../utils/dataLists";
import { useDispatch } from "react-redux";

const AddVehicleType = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    typename: "",
    vehicletypeimage: vehicleTypeImageOptions[0].label,
    hourlyprice: 0,
    description: "",
    maxpassenger: 0,
    maxluggages: 0,
    baseprice: 0,
    subtitle: "",
    option1Icon: "",
    option2Icon: "",
    option3Icon: "",
    option4Icon: "",
    option5Icon: "",
    option6Icon: "",
    option7Icon: "",
    option8Icon: "",
    optionText1: "",
    optionText2: "",
    optionText3: "",
    optionText4: "",
    optionText5: "",
    optionText6: "",
    optionText7: "",
    optionText8: "",
  });

  const {
    typename,
    hourlyprice,
    description,
    maxluggages,
    maxpassenger,
    baseprice,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFormSubmit = (e) => {
    e.preventDefault();

    dispatch(addVehicleType(formData, null));
    setFormData({
      ...formData,
      typename: "",
      hourlyprice: 0,
      description: "",
      maxpassenger: 0,
      maxluggages: 0,
      baseprice: 0,
    });
    // console.log(formData);
  };

  const setOption = (e) => {
    setFormData({ ...formData, vehicletypeimage: e.label });
  };
  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Add Vehicle Type" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              {/* <form onSubmit={(e) => onFormSubmit(e)}> */}

              <div className="card mb-3">
                <div className="card-header">Vehicle details</div>
                <div className="card-body">

                  <div className="mb-3">
                    <label className="label mb-2">Type Name:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Vehicle Type"
                        name="typename"
                        value={typename}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Hourly Price:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hourly Price"
                        name="hourlyprice"
                        value={hourlyprice}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label mb-2">Base Price:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Hourly Price"
                        name="baseprice"
                        value={baseprice}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Image Name:</label>
                    <div className="col-sm-12">
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select pick-up location"
                        autoComplete="off"
                        defaultValue={vehicleTypeImageOptions[0]}
                        onChange={(e) => {
                          setOption(e);
                        }}
                        options={vehicleTypeImageOptions}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Description:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control form-control-admin"
                        id="reg"
                        placeholder="Enter Vehicle Type Description"
                        name="description"
                        value={description}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Maximum Passengers:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Passangers"
                        name="maxpassenger"
                        value={maxpassenger}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="label mb-2">Maximum Luggages:</label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control form-control-admin"
                        id="name"
                        placeholder="Enter Luggages"
                        name="maxluggages"
                        value={maxluggages}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                </div>
              </div>

              <div className="mb-3">
                <div className="col-sm-offset-2 col-sm-10">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => onFormSubmit(e)}
                  >
                    <i
                      className="fa fa-plus-circle fa-fw"
                      aria-hidden="true"
                    ></i>{" "}
                    Add Vehicle Type
                  </button>
                </div>
              </div>
              
              {/* </form> */}
            </div>
          </div>
        </div>
        <Alert />
      </main>
    </Layout>
  );
};

export default AddVehicleType;
