import React from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";

const VehicleInfo = () => {
  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Vehicle Infomation" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3 mb-3">
              <div className="card">
                <img
                  src="https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Honda Accord Hybrid LT</h5>
                  <p className="card-text">
                    Vehicle type : GC | Vehicle registration : ABC-123456-C33 |
                    Active
                  </p>
                  <a href="#!" className="btn btn-secondary">
                    Edit Vehicle
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="card">
                <img
                  src="https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Honda Accord Hybrid LT</h5>
                  <p className="card-text">
                    Vehicle type : GC | Vehicle registration : ABC-123456-C33 |
                    Active
                  </p>
                  <a href="#!" className="btn btn-secondary">
                    Edit Vehicle
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="card">
                <img
                  src="https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Honda Accord Hybrid LT</h5>
                  <p className="card-text">
                    Vehicle type : GC | Vehicle registration : ABC-123456-C33 |
                    Active
                  </p>
                  <a href="#!" className="btn btn-secondary">
                    Edit Vehicle
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="card">
                <img
                  src="https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Honda Accord Hybrid LT</h5>
                  <p className="card-text">
                    Vehicle type : GC | Vehicle registration : ABC-123456-C33 |
                    Active
                  </p>
                  <a href="#!" className="btn btn-secondary">
                    Edit Vehicle
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="card">
                <img
                  src="https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Honda Accord Hybrid LT</h5>
                  <p className="card-text">
                    Vehicle type : GC | Vehicle registration : ABC-123456-C33 |
                    Active
                  </p>
                  <a href="#!" className="btn btn-secondary">
                    Edit Vehicle
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="card">
                <img
                  src="https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Honda Accord Hybrid LT</h5>
                  <p className="card-text">
                    Vehicle type : GC | Vehicle registration : ABC-123456-C33 |
                    Active
                  </p>
                  <a href="#!" className="btn btn-secondary">
                    Edit Vehicle
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

VehicleInfo.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(VehicleInfo);
