import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const PackageItem = ({
  package: {
    _id,
    airport,
    vehicletype,
    locations,
    packagename,
    rate,
    isactive,
  },
  count,
  deletePackage,
}) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>{packagename}</td>
      <td>{airport?.label}</td>
      <td>
        {locations.map((l) => (
          <div key={l?.lat}>{l?.label}</div>
        ))}
      </td>
      <td>{vehicletype}</td>
      <td>{rate}</td>
      <td>
        {isactive ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-secondary">Inactive</span>
        )}
      </td>
      <td className="px-0">
        <Link
          type="button"
          className="btn btn-secondary py-1 px-3"
          to={`/admin/packagelist/edit/${_id}`}
        >
          Edit
        </Link>
      </td>
      <td className="ps-1">
        <button
          type="button"
          className="btn btn-outline-danger py-1 px-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            deletePackage(_id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default PackageItem;
