import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const NewPackageItem = ({
  package: {
    _id,
    packagename,
    description,
    type,
    isactive,
  },
  count,
  deletePackage,
}) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>{packagename}</td>
      <td>{description}</td>
      <td>
        {type == "fromAirport" ? (
          <span className="badge bg-info py-2">From Airport</span>
        ) : type == "toAirport" ? (
          <span className="badge bg-dark py-2">To Airport</span>
        ) : type == "basic" ? (
          <span className="badge bg-dark py-2">Basic</span>
        ) : ""}
      </td>
      <td>
        {isactive ? (
          <span className="badge bg-success py-2">Active</span>
        ) : (
          <span className="badge bg-secondary py-2">Inactive</span>
        )}
      </td>
      <td className="px-0">
        <Link
          type="button"
          className="btn btn-secondary py-1 px-3"
          to={`/admin/newpackagelist/edit/${_id}`}
        >
          Edit
        </Link>
      </td>
      <td className="ps-1">
        <button
          type="button"
          className="btn btn-outline-danger py-1 px-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            deletePackage(_id);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default NewPackageItem;
