import React, { useState } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { addUserToDB } from "../../../actions/auth";
import Alert from "../../layout/Alert";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import { setAlert } from "../../../actions/alert";
import { useDispatch } from "react-redux";

const AddUser = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });
  const clearFormData = () => {
    setFormData({
      name: "",
      email: "",
      password: "",
      password2: "",
    });
  };
  const [isloading, setIsloading] = useState(false);
  const { name, email, password, password2 } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    if (password !== password2) {
      //   setAlert("Password do not match", "danger", 3000);
      dispatch(setAlert("Password do not match.", "danger"));
    } else {
      var res = await addUserToDB(name, email, password);
      const token = res.token;
      if (!token) {
        if (res[0].response.status === 400) {
          res[0].response.data.errors.map((e) => {
            dispatch(setAlert(e.msg, "danger"));
          });
        }
      } else {
        dispatch(setAlert("User has been created.", "success"));
        clearFormData();
      }
    }
    setIsloading(false);
  };

  return (
    <LoadingOverlay
      active={isloading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Add New Admin User" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <form onSubmit={(e) => onSubmit(e)}>

                  <div className="card mb-3">
                    <div className="card-header">User details</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Name:</label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            id="name"
                            placeholder="Enter name"
                            name="name"
                            value={name}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Email:</label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            id="name"
                            placeholder="Enter Email"
                            name="email"
                            value={email}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Password:</label>
                        <div className="col-sm-12">
                          <input
                            type="password"
                            className="form-control form-control-admin"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Confirm Password:</label>
                        <div className="col-sm-12">
                          <input
                            type="password"
                            className="form-control form-control-admin"
                            placeholder="Confirm Password"
                            name="password2"
                            value={password2}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Add User
                      </button>
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default AddUser;
