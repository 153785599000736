import {
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_ERROR,
  GET_SETTING,
  SET_REF,
  SET_LANG,
} from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const setLanguage = (lang) => async (dispatch) => {
  try {
    // const res = await customAxios.get("/api/admin/sitesetting/getsettings");
    dispatch({
      type: SET_LANG,
      payload: lang,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SETTINGS_ERROR,
      payload: { msg: err },
    });
  }
};

export const getSettingForMail = async () => {
  try {
    const res = await customAxios.get("/api/admin/sitesetting/getsettings");
    return res.data;
  } catch (err) {
    return [];
  }
};

export const setRef = (val) => async (dispatch) => {
  try {
    // const res = await customAxios.get("/api/admin/sitesetting/getsettings");
    dispatch({
      type: SET_REF,
      payload: val,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SETTINGS_ERROR,
      payload: { msg: err },
    });
  }
};

export const getSetting = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/admin/sitesetting/getsettings");
    localStorage.setItem("jsonstring", JSON.stringify(res.data));
    dispatch({
      type: GET_SETTING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SETTINGS_ERROR,
      payload: { msg: err },
    });
  }
};

export const getSettingEditBooking = async () => {
  try {
    const res = await customAxios.get("/api/admin/sitesetting/getsettings");
    
    return res.data.editBooking;
  } catch (err) {
    console.log(err)
    return false;
  }
};

// add sitesetting
export const updateSettings = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post(
      "/api/admin/sitesetting",
      formData,
      config
    );
    dispatch({
      type: UPDATE_SETTINGS,
      payload: res.data,
    });
    dispatch(setAlert("Settings Updated", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: UPDATE_SETTINGS_ERROR,
      payload: { msg: err },
    });
  }
};
