import React, { useState, useEffect, useRef } from "react";
import Alert from "../../layout/Alert";
import Layout from "../../layout/Layout";
import TitleBar from "../../layout/TitleBar";
import EmailEditor from "react-email-editor";
import { useDispatch, useSelector } from "react-redux";
import { saveEmailTemplate } from "../../../actions/admin/mail";
import { useHistory } from "react-router-dom";

// import cusjs from "./custom";

const UpdateEmailTemplate = ({ match }) => {
  // const React = window.unlayer.React;
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const template_state = useSelector((state) => state.template);
  const { templates } = template_state;
  const { user } = auth;
  const [isCutomerEmail, setIsCustomerEmail] = useState(false);
  const [subject, setSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const emailEditorRef = useRef(null);

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  useEffect(() => {
    const tempDesign = templates.filter((t) => t._id === match.params.id);
    console.log(tempDesign);
    setTemplateName(tempDesign[0].templatename);
    setIsCustomerEmail(tempDesign[0].iscustomeremail);
    setSubject(tempDesign[0].subject ? tempDesign[0].subject : "");
  }, [templates]);

  const onLoad = () => {
    console.log("onLoad");

    emailEditorRef.current?.editor?.addEventListener(
      "design:loaded",
      onDesignLoad
    );
    console.log("match.params.id");
    console.log(match.params.id);
    const templateDesign = templates.filter((t) => t._id === match.params.id);
    console.log(templateDesign);
    if (templateDesign.length > 0) {
      emailEditorRef.current?.editor?.loadDesign(templateDesign[0].design);
    } else history.push("/admin/templatelist");
  };

  const exportHtml = () => {
    emailEditorRef.current?.editor?.exportHtml((data) => {
      const { design, html } = data;
      const currentUser = { id: user._id, name: user.name, email: user.email };
      dispatch(
        saveEmailTemplate(
          match.params.id,
          html,
          design,
          templateName,
          isCutomerEmail,
          currentUser,
          subject
        )
      );
    });
  };

  const onReady = () => {
    // editor is ready
    // console.log("onReady");
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Update Email Template" />
        <div className="container-fluid">
          <div className="row mb-2">
            <Alert />
            <div className="col-3">
              <label className="label mb-2">Template Name:</label>
              <input
                type="text"
                className="form-control form-control-admin"
                placeholder="Enter template name"
                name="templatename"
                value={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
                required
              />
            </div>
            <div className="col-3">
              <label className="label mb-2">Subject:</label>
              <input
                type="text"
                className="form-control form-control-admin"
                placeholder="Enter subject"
                name="subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                required
              />
            </div>
            <div className="col-3">
              <label className="mb-2">
                Mark as default customer email
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isCutomerEmail}
                  name="isactive"
                  onChange={(e) => {
                    setIsCustomerEmail(!isCutomerEmail);
                  }}
                />
              </div>
            </div>

          </div>

          <div className="mb-2">
            <div className="mt-3 mb-0">Available dynamic tags:</div>
            <div><p className="card-title">Copy and add these tags to a paragraph, div or heading in the template to automatically insert dynamic data (eg:customer name, cart table) when the email is sent.</p></div>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{pickuplocation}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{dropofflocation}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{drivername}}"}</label> 
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{vehiclename}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{vehicletype}}"}</label> 
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{pickuptime}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{dropofftime}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{customername}}"}</label> 
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{cart}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{packagename}}"}</label> 
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{totalcost}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2 me-3">{"{{flightno}}"}</label>
            <label className="badge bg-light border border-dark p-2 my-2">{"{{vehicleregistration}}"}</label>
          </div>
          <div className="my-4 col-3 d-flex align-items-center">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={exportHtml}
            >
              <i className="fa fa-plus-circle fa-fw" aria-hidden="true"></i>{" "}
              Update Template
            </button>
          </div>
          <div className="row">
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              onReady={onReady}
              minHeight={"80vh"}
            // tools={null}
            // options={{
            //   customJS: ["http://localhost:3000/custom.js"],
            // }}
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default UpdateEmailTemplate;
