/* eslint-disable array-callback-return */
import FrontLayout from "../../layout-new-theme/FrontLayout";
import back_icon from "../../../images/back_icon.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useState,  } from "react";
import BookingInformation from "./BookingInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartReduxStore,
  addBooking,
  updateBooking,
  setTimeoutCart,
  addByTheHourFormToState,
  addSpecifiedDestinationFormToState,
} from "../../../actions/admin/booking";
import moment from "../../../utils/customMoment";
import jwtDecode from "jwt-decode";
import arrow_right_icon from "../../../icons/arrow_right.svg";

const BookingConfirmationPage = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  if (lang === "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }

  const cart_item_raw = props.location.state?.cart_item || "";
  const isEditBooking = props.location.state?.isEditBooking || false;
  const keyBook = props.location.state?.keyBook || null;
  const [cartItem, setCartItem] = useState(JSON.parse(cart_item_raw));
  const { t } = useTranslation();
  const history = useHistory();
  const pickupDateTime = new Date(cartItem.pickupDateTime);


  const formattedPickupTime = pickupDateTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const handleOnChangeCartItem = (item) => {
    setCartItem({
      ...item,
      totalPrice: getTotalPriceFromCart(item),
    });
  };

  const getTotalPriceFromCart = (item) => {
    var tot = 0;
    item.bookingInfo.map((itm) => {
      tot += itm.total + (itm.childSeatPrice ?? 0);
    });

    item.meetAssist.map((m) => {
      if (m.meetAssistName !== "none") {
        tot += m.meetAssistPrice;
      }
    });

    return tot;
  };


  const addToCart = async (redirect = "/Cart") => {
    if (cartItem) {
      const items = [];
      let totalPrice = 0;

      cartItem.bookingInfo.map((bi, ind) => {
        const item = {
          index: ind,
          item_id: bi.vehicleTypeID,
          item_name: bi.vehicleTypeName,
          item_brand: "Toyota",
          affiliation: "Flywire",
          coupon: "",
          discount: 0,
          item_category: cartItem.isAirportTransfer ? 'Airport Transfer' : cartItem.bookingType === "byTheHour" ? 'By The Hour' : 'Specified Destination',
          item_category2:
            cartItem.destinations.length === 1
              ? cartItem.destinations[0].place_id
              : cartItem.destinations[cartItem.destinations.length - 1]
                  .place_id,
          item_category3:
            bi.childSeat[0].childSeatCount +
              bi.childSeat[1].childSeatCount +
              bi.childSeat[2].childSeatCount >
            0
              ? "Child Seats"
              : "",
          location_id: "",
          price: bi.total,
          quantity: 1,
        };

        items.push(item);
      })

      totalPrice = cartItem.totalPrice

      const ecommerceData = {
        currency: "JPY",
        value: totalPrice,
        items: items
      }

      const dataToPush = {
        event: 'add_to_cart',
        ecommerce: ecommerceData
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataToPush);
    }

    addItemCartToStore(cartItem)
      .then(async () => await resetform())
      .then(() => history.push(redirect))
      .catch((err) => {
        console.error(err);
      });
  };

  const addToCartDuplicate = async (arrCart) => {
    await Promise.all(
      arrCart.map((i) => {
        addItemCartToStore(i)
          .then(() => resetform())
          .catch((err) => {
            console.error(err);
          });
      })
    );

    history.push("/Cart");
  };

  const submitCheckout = () => {
    var randomchar =
      Math.floor(Math.random() * 16777215).toString(16) +
      Math.floor(Math.random() * 1000 + 1);

    let newCart = cartItem;
    newCart.randomId = randomchar;

    addItemCartToStore(cartItem, false)
      .then(() => resetform())
      .then(() => {
        let arrCart = [];
        arrCart.push(cartItem);

        history.push("/checkout", {
          items_to_checkout: JSON.stringify(arrCart),
          fromCart: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Function to compare two trip objects based on tripStartTime
  const compareTrips = (a, b) => {
    const startTimeA = moment(a.tripStartTime);
    const startTimeB = moment(b.tripStartTime);

    if (startTimeA.isBefore(startTimeB)) {
      return -1;
    } else if (startTimeA.isAfter(startTimeB)) {
      return 1;
    } else {
      return 0;
    }
  };

  /**
   * * manage cart storage
   * * local store,redux state,DB
   */
  const addItemCartToStore = async (item, storeLocalStorage = true) => {
    item.totalPrice = getTotalPriceFromCart(item);
    // item.passengerName = "(your name)";
    var store_cart;
    if (storeLocalStorage) {
      const localStoteItem = JSON.parse(localStorage.getItem("cart"));
      if (localStoteItem !== null && localStoteItem.length > 0) {
        var old_cart = JSON.parse(localStorage.getItem("cart"));

        if (old_cart[0].randomId !== item.randomId) {
          item.randomId = old_cart[0].randomId;
        }

        if (isEditBooking) {
           store_cart = old_cart.filter(obj => obj.key !== keyBook);
        } else {
           store_cart = old_cart;
        }

        store_cart.push(item);

        // Sorting the array using the compare function
        const sortedCart = store_cart.sort(compareTrips);

        localStorage.setItem("cart", JSON.stringify(sortedCart));
        dispatch(addToCartReduxStore(sortedCart));

        if (!isEditBooking) {
          const nowDateTime = moment();
          localStorage.setItem("lastAddedBookToCart", nowDateTime);

          let keys = [];

          sortedCart.map((i) => {
            keys.push(i.key);
          });

           setTimeoutCart(keys)
        }
      } else {
        var cart_array_store = [];
        cart_array_store.push(item);
        localStorage.setItem("cart", JSON.stringify(cart_array_store));
        dispatch(addToCartReduxStore(cart_array_store));

        const nowDateTime = moment();
        localStorage.setItem("lastAddedBookToCart", nowDateTime);

        setTimeoutCart([item.key])
      }
    }
    addCartToDB(item, storeLocalStorage);
  };

  const checkTokenUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = jwtDecode(token);
      if (
        (decodedJwt.user.id.role === "individual" ||
          decodedJwt.user.id.role === "business") &&
        decodedJwt.user.id.verifiedStatus === true
      ) {
        return decodedJwt;
      }
      return null;
    }
    return null;
  };

  /**
   * * Insert data to the database
   */
  const addCartToDB = async (item, withCart) => {
    const decodedJwtToken = await checkTokenUser();
    const cartUser = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.name : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.contactName : "cart user";
    const cartEmail = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.email : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.email : "cart@email.com";
    const cartPhone = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.phoneNumber : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.phoneNumber : "0000000000";
    const cartAddress = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.address : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.businessInformation.businessAddress : "cart address";
    const cartCountry = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.country : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.country : "Japan";
    const cartPrefixNumber = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.prefix_number : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.prefix_number : "+81";
    const cartCountryCode = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.country_code : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.country_code : "JP";
    const cartPostCode = (decodedJwtToken !== null && decodedJwtToken.user.id.role === "individual") ? decodedJwtToken.user.id.individualDetail.billingInformation.postCode : (decodedJwtToken !== null && decodedJwtToken.user.id.role === "business") ? decodedJwtToken.user.id.businessDetail.billingInformation.postCode : "0000";
    const userId = decodedJwtToken !== null ? decodedJwtToken.user.id._id : "";
    
    if (isEditBooking) {
      dispatch(
        updateBooking(
          keyBook,
          item.travelDateWithPickupTime,
          item.pickuptime,
          item.pickuplocation,
          item.destinations,
          item.paxList,
          item.luggagelist,
          item.pickupFlightNo,
          item.dropoffFlightNo,
          item.isFromAirport,
          item.cutomerActualDropOffTime,
          item.dropoffDatetime,
          item.garageArivalTime,
          item.cutomerChangedDropOffTimeOnly,
          cartUser,
          cartEmail,
          cartPhone,
          cartAddress,
          cartCountry,
          cartCountryCode,
          cartPrefixNumber,
          cartPostCode,
          0,
          item.availableVehicleId,
          null,
          item.bookingInfo,
          withCart ? "ADDED_TO_CART" : "READY_TO_CHECKOUT",
          item.totalDistance,
          item.tripStartTime,
          item.garageArivalTimeWithAdditionalTime,
          item.note_arr,
          item.randomId,
          item.activePackage,
          item.passengerName === undefined ? "(your name)" : item.passengerName,
          item.specialRequest,
          item.meetAssist,
          item.byTheHourBook,
          item.duration,
          item.isManyPeople,
          item.totalPrice,
          item.childSeat,
          item.isAirportTransfer,
          userId,
          withCart,
          "Mr.",
          item.pronounced, // pronounced
          "+81", // country_code
          false, //duplicateFromBookingKey "??"
          item?.flightTracking
        )
      );
    } else {
      dispatch(
        addBooking({
          key: item.key,
          randomId: item.randomId,
          travelDate: item.travelDate,
          pickupTime: item.pickupTime,
          pickupDateTime: item.pickupDateTime,
          customerDropoffDateTime: item.customerDropoffDateTime,
          startFromGarage: item.startFromGarage,
          garageArivalTime: item.garageArrivalTime,
          pickupLocation: item.pickupLocation,
          destinations: item.destinations,
          totalDistance: item.totalDistance,
          garage: item.garage._id,
          pax: item.paxList,
          luggage: item.luggagelist,
          bookingType: item.bookingType,
          additionalTime: item.additionalTime,
          breakTime: item.breakTime,
          flightTrackingTime: item.flightTrackingTime,
          usageDuration: item.usageDuration,
          billedDuration: item.billedDuration,
          duration: item.bookingType === "byTheHour" ? item.duration : null,
          isAirportTransfer: item.isAirportTransfer,
          isFromAirport: item.isFromAirport,
          isToAirport: item.isToAirport,
          meetAssist: item.meetAssist,
          isManyPeople: item.isManyPeople,
          package: item.package,
          bookingInfo: item.bookingInfo,
          date: item.date,
          passengerName: item.passengerName === undefined ? "(your name)" : item.passengerName,
          passengerPronounced: item.passengerPronounced,
          noteList: item.note_arr,
          totalPrice: item.totalPrice,
          pickupFlightNo: item.pickupFlightNo,
          dropoffFlightNo: item.dropoffFlightNo,
          pickupSign: item.pickupSign,
          bookingStatus: withCart ? "ADDED_TO_CART" : "READY_TO_CHECKOUT",
          userId: userId,
          name: cartUser,
          email: cartEmail,
          phone: cartPhone,
          address: cartAddress,
          country: cartCountry,
          countryCodeCust: cartCountryCode,
          prefixNumber: cartPrefixNumber,
          postCode: cartPostCode,
        })
      );
    }
  };

  const resetform = async () => {
    dispatch(addSpecifiedDestinationFormToState(null));
    dispatch(addByTheHourFormToState(null));
  };

  return (
    <FrontLayout>
      <div className="bg-page">
        
        <div className="container-fluid mt-1 pt-1">
          <div className="custom-mx">
            <div className=" pb-5 custom-mt-9 col-lg-12">
              <div className=" d-flex-auto row align-items-center mb-3">
                <div className="col-auto back-to-vehicle-selection-button">
                  <img src={back_icon} className="" alt="Clock icon" />
                </div>
                <div className="col">
                  <p
                    className="fw-100 text-start text-dark noto d-inline back-to-vehicle-selection-button"
                    onClick={() => history.goBack()}
                  >
                    {" "}
                    {t("BookingConfirmation.BackToVehicleSelection")}
                  </p>
                </div>
              </div>
              <h3 className="mb-3">{t("BookingConfirmation.Title")}</h3>
              <BookingInformation
                cart={cartItem}
                removeItemFromCart={() => {}}
                updateCartItem={handleOnChangeCartItem}
                addToCartDuplicate={addToCartDuplicate}
                checkTokenUser={checkTokenUser}
              />
              <div className="d-grid mt-3">
                {isEditBooking ? <button
                  type="button"
                  className="btn btn-primary rounded py-2"
                  onClick={() => addToCart("/Cart")}
                  // disabled ={(cartItem.passengerName  == undefined || cartItem.passengerName  == "" ? true : false) && (cartItem.isAirportTransfer == true|| cartItem.flightTracking== true ? cartItem.pickupFlightNo !== undefined || cartItem.pickupFlightNo !== "" : false)}
                  disabled={(() => {
                    if (
                      cartItem.passengerName == undefined ||
                      cartItem.passengerName == ""
                    ) {
                      return true;
                    } else {
                      if (
                        cartItem.isAirportTransfer == true ||
                        cartItem.flightTracking == true
                      ) {
                        if (
                          cartItem.isFromAirport &&
                          (cartItem.pickupFlightNo == undefined ||
                            cartItem.pickupFlightNo == "")
                        ) {
                          return true;
                        }
                        return false;
                      }
                      return false;
                    }
                  })()}
                >
                  {t("BookingConfirmation.ConfirmBooking")}
                  <i className="ps-2">
                    <img  alt="img" src={arrow_right_icon} />
                  </i>
                </button> : <button
                  type="button"
                  className="btn btn-primary rounded py-2"
                  data-bs-toggle="modal"
                  data-bs-target="#bookingConfirmation"
                  // disabled ={(cartItem.passengerName  == undefined || cartItem.passengerName  == "" ? true : false) && (cartItem.isAirportTransfer == true|| cartItem.flightTracking== true ? cartItem.pickupFlightNo !== undefined || cartItem.pickupFlightNo !== "" : false)}
                  disabled={(() => {
                    if (
                      cartItem.passengerName == undefined ||
                      cartItem.passengerName == ""
                    ) {
                      return true;
                    } else {
                      if (
                        cartItem.isAirportTransfer == true ||
                        cartItem.flightTracking == true
                      ) {
                        if (
                          cartItem.isFromAirport &&
                          (cartItem.pickupFlightNo == undefined ||
                            cartItem.pickupFlightNo == "")
                        ) {
                          return true;
                        } else if (
                          cartItem.isToAirport &&
                          (cartItem.dropoffFlightNo == undefined ||
                            cartItem.dropoffFlightNo == "")){
                          return true;
                        }
                        return false;
                      }
                      return false;
                    }
                  })()}
                >
                  {t("BookingConfirmation.ConfirmBooking")}
                  <i className="ps-2">
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16406 4.04199L13.8307 9.00033L9.16406 13.9587M3.16406 9.00033H13.8307H3.16406Z"
                        stroke="#4A4A4A"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </i>
                </button> }
                
              </div>

              <div
                className="modal fade"
                id="bookingConfirmation"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalTitleId"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered modal-sm"
                  role="document"
                >
                  <div className="modal-content border-top-gold">
                    <div className="modal-body">
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <h5 className="text-center">
                        {t("booking_confirmation.title")}
                      </h5>
                      <p className="text-center mt-4">
                        {lang == "EN" ? <>
                          {cartItem.passengerName != "" ? ` Your booking for ${cartItem.passengerPronounced + ' '}${cartItem.passengerName}, Picking up at ` : `at `}
                          <b>{`${moment(cartItem.pickupDateTime).format("HH:mm")}, `}</b>
                          <b>{`${moment(pickupDateTime).format("MMM. Do, YYYY (ddd)")} `}</b>
                          {`in ${cartItem.pickupLocation.label.split(',')[0]} has been added to Cart.`}
                        </> : <>
                          {cartItem.passengerName} 様の{moment(pickupDateTime).format("YYYY年M月D日")} <b>{`${moment(cartItem.pickupDateTime).format("HH:mm")}`}</b>にご乗車場所: {cartItem.pickupLocation.label.split(',')[0]}の予約が予約かごに追加されました。
                        </>


                        }

                      </p>
                      <div className="row gap-2 justify-content-center">
                        <div className="col-12 col-lg-3 d-grid p-lg-0">
                          <button
                            type="button"
                            className="rounded btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              addToCart("/book");
                            }}
                          >
                            {t("booking_confirmation.add_another_booking")}
                          </button>
                        </div>
                        <div className="col-12 col-lg-3 d-grid p-lg-0">
                          <button
                            type="button"
                            className="rounded btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              addToCart("/Cart");
                            }}
                          >
                            {t("booking_confirmation.proceed_to_cart")}
                          </button>
                        </div>
                      </div>
                      <div className="d-flex gap-3 justify-content-center align-items-center mt-3">
                        <div className="col col-lg-2">
                          <hr />
                        </div>
                        <div className="col-auto">
                          <p className="m-0">
                            {t("booking_confirmation.or_book_now")}</p>
                        </div>
                        <div className="col col-lg-2">
                          <hr />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-3">
                        <div className="col-12 col-lg-3 d-grid">
                          <button
                            type="button"
                            className="rounded btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              submitCheckout();
                            }}
                          >
                            {t("booking_confirmation.book_this_transfer_only")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default BookingConfirmationPage;
