import {
    ADD_GARAGE,
    UPDATE_GARAGE,
    DELETE_GARAGE,
    GET_GARAGES,
    GET_GARAGE,
    GARAGE_ERROR,
} from "../../actions/types";

const initialState = {
    garages: [],
    garage: null,
    loading: true,
    error: {},
};

export default function foo(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_GARAGE:
            return {
                ...state,
                garages: payload,
                loading: false,
            };
        case UPDATE_GARAGE:
            return {
                ...state,
                garage: payload,
                loading: false,
            };
        case DELETE_GARAGE:
            return {
                ...state,
                garages: payload,
                loading: false,
            };
        case GET_GARAGE:
            return {
                ...state,
                garage: payload,
                loading: false,
            };
        case GET_GARAGES:
            return {
                ...state,
                garages: payload,
                loading: false,
            };
        case GARAGE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}
