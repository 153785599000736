import {
  GET_AIRPORTS,
  AIRPORT_ERROR,
  GET_AIRPORT,
  DELETE_AIRPORT,
} from "../../actions/types";

const initialState = {
  airports: [],
  airportState: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DELETE_AIRPORT:
      return {
        ...state,
        airports: payload,
        loading: false,
      };
    case GET_AIRPORT:
      return {
        ...state,
        airportState: payload,
        loading: false,
      };
    case GET_AIRPORTS:
      return {
        ...state,
        airports: payload,
        loading: false,
      };
    case AIRPORT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
