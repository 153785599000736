import React, { Fragment, useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import Spinner from "../../layout/Spinner";
import TitleBar from "../../layout/TitleBar";
import { useSelector, useDispatch } from "react-redux";
import { getTemplates, deleteTemplate } from "../../../actions/admin/mail";
import TemplateItem from "./TemplateItem";

const TemplateList = () => {
  const template_state = useSelector((state) => state.template);
  const { loading, templates } = template_state;
  // const [isloading, setIsloading] = useState(false);
  const [delId, setDelId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  const delTempfromPopup = () => {
    dispatch(deleteTemplate(delId));
    dispatch(getTemplates());
    setDelId(null);
  };

  const removeTemplate = (delId) => {
    setDelId(delId);
  };
  return (
    <Fragment>
      <Layout>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Warning!
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete this template?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={delTempfromPopup}
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Email templates list" />
          <h4 className="my-3">All available templates</h4>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <table className="table table-hover table-sm booking-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Template Name</th>
                      <th scope="col">Customer Template</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {templates.map((p, i = 0) => (
                      <TemplateItem
                        key={p._id}
                        template={p}
                        count={i++}
                        deleteTemplate={removeTemplate}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default TemplateList;
