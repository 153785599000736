/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSetting, setLanguage } from "../../actions/admin/settings";
import logo_brand_name from "../../images/tlf-logo-brand-name.svg";
import logo_brand from "../../images/tlf-logo-brand.svg";
import LanguageCard from "../layout-new-theme/LanguageCard";
import check_green from "../../images/check_green.svg";
import arrow_right_icon from "../../icons/arrow_right.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SuccessChangePaymentMethod = () => {
  const {  i18n } = useTranslation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    try {
      if (localStorage.getItem("lang") === undefined || localStorage.getItem("lang") === null) {
        var userLang = navigator.language || navigator.userLanguage;
        console.log("userLang", userLang) 
        if (userLang === "ja") {
          langChange("JP");
        } else {
          langChange("EN");
        }
      }else{
        langChange(localStorage.getItem("lang"));
      }
    } catch { 
      console.log("error")
    }
  }, []);
  
   const langChange = (lng) => {
    console.log(lng)
    i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("lang", lng);
    dispatch(setLanguage(lng));
  };
  return (
    <>
      <div style={{ backgroundColor: "#F6F6F6" }} className="vh-md-100" >
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top">
          <div className="container-fluid">
            <div className="d-block d-md-none"></div>
            <img
              alt=""
              src={logo_brand}
              style={{ width: "70px", marginLeft: "100px" }}
              className="navbar-brand d-block d-md-none"
            />
            <img
              alt=""
              src={logo_brand_name}
              style={{ width: "70px" }}
              className="navbar-brand d-none d-md-block"
            />
            <LanguageCard />
          </div>
        </nav>
        <div className="custom-mx mt-3 pt-3 mt-sm-5 pt-sm-5">
          <div className="container pb-5 pb-md-0 pt-5 pt-md-0 custom-mt-6 col-lg-12">
            <div className="row pb-5 d-flex justify-content-lg-center">
              <div className="col-12 col-md-8 px-5 py-5 ">
                <div className="row mb-5">
                  {
                    <div className="text-center mb-3">
                      <img alt="img" src={check_green} />
                    </div>
                  }
                  <h3 className="fw-bold text-center text-dark my-3">
                    {t("SuccessChangePaymentMethod.Title")}
                  </h3>
                  <p className="text-center mb-3 ">
                    {t("SuccessChangePaymentMethod.Desc")}
                    <br />
                    <br />
                    <span dangerouslySetInnerHTML={{__html : t("SuccessChangePaymentMethod.Contact")}}></span>
                  </p>
                  <div className="mt-3 d-flex justify-content-center">
                  <div className="block d-md-grid">
                  <button
                      type="button"
                      className="btn btn-primary btn-sm text-dark px-5 rounded"
                      onClick={() => history.push("/")}
                    >
                      <div className="d-flex justify-content-center py-2 px-5 align-items-center d-inline">
                        <div>{t("SuccessChangePaymentMethod.BackToHome")}</div>
                        <i className="ps-2">
                          <img alt="img" src={arrow_right_icon} />
                        </i>
                      </div>
                    </button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessChangePaymentMethod;
