import {
  ADD_VEHICLE,
  VEHICLE_ERROR,
  GET_VEHICLES,
  GET_ACTIVE_VEHICLES,
  GET_VEHICLE,
} from "../../actions/types";

const initialState = {
  activevehicles: [],
  vehicles: [],
  vehicle: null,
  loading: true,
  error: {},
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_VEHICLE:
      return {
        ...state,
        vehicle: payload,
        loading: false,
      };
    case GET_VEHICLE:
      return {
        ...state,
        vehicle: payload,
        loading: false,
      };
    case GET_VEHICLES:
      return {
        ...state,
        vehicles: payload,
        loading: false,
      };
    case GET_ACTIVE_VEHICLES:
      return {
        ...state,
        activevehicles: payload,
        loading: false,
      };
    case VEHICLE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
