import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import { getUser, updateUser } from "../../../actions/auth";
import Alert from "../../layout/Alert";
// import { vehicleTypeOptions } from "../../../utils/dataLists";
import { useDispatch, useSelector } from "react-redux";
// import LoadingSpinner from "../../common/LoadingSpinner";
import Spinner from "../../layout/Spinner";
import { getAllVehicleTypes } from "../../../actions/admin/vehicletype";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";
import { setAlert } from "../../../actions/alert";

const UpdateUserAdmin = ({ match, history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { loading, edituser } = auth;

  const [formData, setFormData] = useState({
    _id: "",
    name: "",
    email: "",
    password: "",
    password2: "",
  });
  const [isloading, setIsloading] = useState(false);
  const { name, email, password, password2 } = formData;

  useEffect(() => {
    dispatch(getUser(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    // console.log(opt);
    setFormData({
      _id: loading || !edituser?._id ? "" : edituser?._id,
      name: loading || !edituser?.name ? "" : edituser?.name,
      email: loading || !edituser?.email ? "" : edituser?.email,
      password: "",
      password2: "",
    });
  }, [loading, edituser]);

  //console.log(selectedOption);
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    if (password !== password2) {
      //   setAlert("Password do not match", "danger", 3000);
      dispatch(setAlert("Password do not match.", "danger"));
    } else {
      var res = await updateUser(match.params.id, name, email, password);
      //   console.log(res);

      if (res !== "User updated.") {
        if (res[0].response.status === 400) {
          res[0].response.data.errors.map((e) => {
            dispatch(setAlert(e.msg, "danger"));
          });
        }
      } else {
        dispatch(setAlert("User has been updated.", "success"));
      }
    }
    setIsloading(false);
  };

  return (
    <LoadingOverlay
      active={isloading || loading}
      spinner={<ScaleLoader color="#36D7B7" />}
    >
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Update User" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <form onSubmit={(e) => onSubmit(e)}>

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <Link className="btn btn-secondary" to="/admin/userlist-admin">
                        <i
                          className="fa fa-arrow-left fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Back To List
                      </Link>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header">User status</div>
                    <div className="card-body">

                      <div className="mb-3">
                        <label className="label mb-2">Name:</label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-admin"
                            placeholder="Enter name"
                            name="name"
                            value={name}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Email:</label>
                        <div className="col-sm-12">
                          <input
                            readOnly
                            type="email"
                            className="form-control form-control-admin"
                            placeholder="Enter Email"
                            name="email"
                            value={email}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">New Password:</label>
                        <div className="col-sm-12">
                          <input
                            type="password"
                            className="form-control form-control-admin"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="label mb-2">Confirm New Password:</label>
                        <div className="col-sm-12">
                          <input
                            type="password"
                            className="form-control form-control-admin"
                            placeholder="Confirm Password"
                            name="password2"
                            value={password2}
                            onChange={(e) => onChange(e)}
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle fa-fw"
                          aria-hidden="true"
                        ></i>{" "}
                        Update User
                      </button>{" "}

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Alert />
        </main>
      </Layout>
    </LoadingOverlay>
  );
};

export default withRouter(UpdateUserAdmin);
