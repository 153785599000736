// LoadingContext.js
import React, { createContext, useState, useContext } from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoading = () => {
    setLoading(true);
  };

  const closeLoading = () => {
    // Delay for 0.5 seconds (500 milliseconds)
    setTimeout(() => {
      // Set loading to false after 0.5 seconds
      setLoading(false);
    }, 1500);
  };

  return (
    <LoadingContext.Provider value={{ loading, showLoading, closeLoading }}>
      {children}
      {loading && <LoadingSpinner />} {/* Menambahkan LoadingSpinner jika loading true */}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
