import React, { useState, Fragment, useEffect } from "react";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import {
  getGarages,
  deleteGarage
} from "../../../actions/admin/garage";
import GarageItem from "./GarageItem";
import Spinner from "../../layout/Spinner";
import { useSelector, useDispatch } from "react-redux";

const GarageList = () => {
  const garage = useSelector((state) => state.garage);
  const { loading, garages } = garage;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGarages());
  }, [loading]);

  const removeGarage = (id) => {
    dispatch(deleteGarage(id));
  };

  return (
    <Fragment>
      <Layout>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <TitleBar title="Garage List" />
          <h4 className="my-4">All available garages</h4>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <table className="table table-hover table-sm booking-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Garage Name</th>
                      <th scope="col">Prefecture</th>
                      <th scope="col">Lat, Lon</th>
                      <th scope="col">Available</th>
                      <th scope="col">Status</th>
                      <th scope="col">Default</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {garages?.map((p, i = 0) => (
                      <GarageItem
                        key={p._id}
                        garage={p}
                        count={i++}
                        removeGarage={removeGarage}
                      />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </main>
      </Layout>
    </Fragment>
  );
};

export default GarageList;
