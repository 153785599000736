import {
    GET_NOTIFICATIONS_CUSTOMER,
    GET_NOTIFICATION_CUSTOMER,
    GET_NOTIFICATIONS_CUSTOMER_ERROR
} from "./types";
import customAxios from "../utils/customAxios";
import setAuthToken from "../utils/setAuthToken";

/**
 * * Get individual bookings based on the provided to the parameters
 * @param status  - type status
 * @param paymentStatus - payment status
 * @param sortBy - pick date
 */
export const getCustomerNotification = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await customAxios.get(
            `/api/notification`
        );
        dispatch({
            type: GET_NOTIFICATIONS_CUSTOMER,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GET_NOTIFICATIONS_CUSTOMER_ERROR,
            payload: { msg: err.response, status: err.response },
        });
    }
};

/**
 * * Get individual bookings based on the provided to the parameters
 * @param status  - type status
 * @param paymentStatus - payment status
 * @param sortBy - pick date
 */
export const getCustomerNotificationDetail = (id) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await customAxios.get(
            `/api/notification/` + id
        );
        dispatch({
            type: GET_NOTIFICATION_CUSTOMER,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GET_NOTIFICATIONS_CUSTOMER_ERROR,
            payload: { msg: err.response, status: err.response },
        });
    }
};