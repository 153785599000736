import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const AdminUserItem = ({ user: { _id, name, email, date }, count, currentUser }) => {
  return (
    <tr>
      <td>{count + 1}</td>
      <td>
        {email === currentUser.email ? (
          <span>
            {name}{" "}
            <span className="badge" style={{ backgroundColor: "#d3ac50" }}>
              {" [Current User]"}
            </span>
          </span>
        ) : (
          <span>{name}</span>
        )}
      </td>
      <td>{email}</td>
      <td>
        <Moment format="YYYY/MM/DD">{date}</Moment>
      </td>
      <td>
        <Link
          type="button"
          className="btn btn-secondary btn-sm py-2 px-4"
          to={`/admin/useredit/edit/${_id}`}
        >
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default AdminUserItem;
